import React from "react";

// Packages
import { Routes, Route } from "react-router-dom";

// Components
import ComponentNotFound from "components/Errors/ComponentNotFound";
import {
  ViewDataRoutes,
  StandardRecipeDataContainer,
  CodeRecipeDataContainer,
  AutoMLRecipeDataContainer,
  Charts,
  ArtifactDetails,
  ModelDetails
} from "src/pages";
import ApiConnectorRecipeDataContainer from "src/pages/Projects/AddApiConnectorRecipe/ApiConnectorRecipeDataContainer";

import Jobs from "./Jobs";
import { JobWrapper, JobRunCanvas, JobCanvasCompare, JobRunLogsPage, JobRuns } from "./components";
import Segment from "../ViewDataset/Segment/Segment";

// Contexts
import JobsContextProvider from "./context/JobsContextProvider";
import { WebPaths } from "src/routing/routes";

const JobRoutes = () => {
  return (
    <JobsContextProvider>
      <Routes>
        <Route index element={<Jobs />} />
        <Route path={WebPaths.CreateJob} element={<JobWrapper />} />
        <Route path={WebPaths.JobId} element={<JobWrapper />} />
        <Route path={WebPaths.JobCompare} element={<JobCanvasCompare />} />
        <Route path={WebPaths.JobRuns} element={<JobRuns />} />
        <Route path={WebPaths.JobCanvas} element={<JobRunCanvas />} />
        <Route path={WebPaths.RunIdLogs} element={<JobRunLogsPage />} />
        <Route
          path={WebPaths.JobDataRoutes}
          element={
            <ViewDataRoutes
              jobProps={{
                path: "projects/:projectId/jobs/:jobId/scenario/:scenarioId/job-runs/:jobRunId/entity/:entityId"
              }}
            />
          }
        />
        <Route path={WebPaths.JobSegment} element={<Segment />} />

        <Route path={WebPaths.JobArtifacts} element={<ArtifactDetails />} />
        <Route path={WebPaths.JobModels} element={<ModelDetails />} />
        <Route path={WebPaths.JobChart} element={<Charts />} />
        <Route path={WebPaths.JobStandardRecipe} element={<StandardRecipeDataContainer />} />
        <Route path={WebPaths.JobCodeRecipe} element={<CodeRecipeDataContainer />} />
        <Route path={WebPaths.JobAutoMLRecipe} element={<AutoMLRecipeDataContainer />} />
        <Route
          path={WebPaths.JobAPIConnectorRecipe}
          element={<ApiConnectorRecipeDataContainer />}
        />

        <Route path="*" element={<ComponentNotFound />} />
      </Routes>
    </JobsContextProvider>
  );
};

export default JobRoutes;
