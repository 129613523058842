import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import AskAICollapsed from "./AskAICollapsed";
import AskAIInputDatasetExpanded from "./AskAIInputDatasetExpanded";
import { Entity, EntityFeaturesResponse } from "src/types";
import { EntityIconV3 } from "src/icons/NewUX/EntityIconV3";

const useStyles = makeStyles({
  inputDatasetsRow: {
    padding: "0px 0px 0px 16px",
    width: "inherit",
    gap: "24px",
    flexWrap: "nowrap"
  },
  outputIconWrap: {
    height: "32px",
    width: "24px !important"
  }
});

interface IProps {
  inputDataset: Entity;
  isExpanded: boolean;
  entityFeatures: EntityFeaturesResponse | undefined;
  onExpandToggle: () => void;
}
const AskAIInputDataset: React.FC<IProps> = ({
  inputDataset,
  entityFeatures,
  isExpanded,
  onExpandToggle
}) => {
  const classes = useStyles({ isExpanded });

  return (
    <Grid container direction="row" className={classes.inputDatasetsRow}>
      <Grid
        test-id="ask-ai-modal-entity-icon"
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        className={classes.outputIconWrap}>
        <EntityIconV3 />
      </Grid>
      {isExpanded ? (
        <AskAIInputDatasetExpanded
          inputDatasetId={inputDataset.id}
          inputDatasetName={inputDataset.displayName || inputDataset.name}
          entityFeatures={entityFeatures}
          onExpandToggle={onExpandToggle}
        />
      ) : (
        <AskAICollapsed name={inputDataset.name} onExpandToggle={onExpandToggle} />
      )}
    </Grid>
  );
};

export default AskAIInputDataset;
