import React, { useMemo } from "react";
import _ from "lodash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";

import CreateDataAppInputs from "./CreateDataAppInputs";
import DataAppModelControls from "./DataAppModelControls";
import EnableResponseCache from "./EnableResponseCache";
import SelectAskAIInputType from "./SelectAskAIInputType";
import SelectAskAILLMModel from "./SelectAskAILLMModel";
import SelectDataAppSecurity from "./SelectDataAppSecurity";
import { AppTemplate } from "src/pages/DataApps/DataApps.type";
import {
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum,
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigDataSharingStrategyEnum
} from "openapi/Models";
import { IRecipes } from "./CreateDataApp";
import { handleResponse } from "src/utils/apiService";
import { useCreateDataAppMutation } from "src/hooks/api/dataapps/useCreateDataAppMutation";
import { useForm } from "src/utils/useForm";
import { useParams } from "react-router-dom";
import { validateNameField } from "src/utils/formFieldUtils";

interface IProps {
  type: CreateDataAppRequestDtoDataAppTypeEnum;
  recipes: IRecipes[];
  appTemplates?: AppTemplate[];
  askAIDataAppTemplateId?: string;
  onBack: () => void;
  onSuccess: () => void;
}

export const dataAppConfigFields = {
  llmModel: "llmModel",
  inputType: "inputType",
  dataAppName: "dataAppName",
  description: "description",
  imageBase64: "imageBase64",
  recipeId: "recipeId",
  systemMessage: "systemMessage",
  security: "security",
  enableCache: "enableCache"
};

const initialValues = {
  [dataAppConfigFields.dataAppName]: "",
  [dataAppConfigFields.description]: ""
};

const CreateDataAppForm: React.FC<IProps> = (props) => {
  const { recipes, type, appTemplates, askAIDataAppTemplateId, onBack, onSuccess } = props;

  const { projectId } = useParams();
  const createDataAppMutation = useCreateDataAppMutation();
  const allowedRecipes = useMemo(() => recipes?.filter((recipe) => recipe.allowed), [recipes]);

  const isAskAI = type === CreateDataAppRequestDtoDataAppTypeEnum.Askai;
  const { values, handleInputChange, setValues } = useForm({
    ...initialValues,
    [dataAppConfigFields.recipeId]:
      type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && allowedRecipes?.length === 1
        ? allowedRecipes?.[0]?.id
        : "",
    [dataAppConfigFields.llmModel]: isAskAI ? DataappAskAIConfigLlmTypeEnum.Turbo : undefined,
    [dataAppConfigFields.inputType]: isAskAI
      ? DataappAskAIConfigInputTypeEnum.ProjectCanvas
      : undefined,
    [dataAppConfigFields.security]: isAskAI
      ? DataappAskAIConfigDataSharingStrategyEnum.SampleData
      : undefined,
    [dataAppConfigFields.enableCache]: isAskAI ? false : undefined
  });

  const errorMsgs = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));

    const { error } = validateNameField({
      fieldName: name,
      fieldNameLabel: "dataApp name"
    });

    return {
      [dataAppConfigFields.dataAppName]: name ? error : ""
    };
  }, [values]);

  const disabledTooltipMsg = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));
    if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
      if (!name) {
        return "Please input DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      if (!_.get(values, dataAppConfigFields.inputType)) {
        return "Please select an input to enable";
      }

      return "";
    } else {
      const recipeId = _.get(values, dataAppConfigFields.recipeId);
      if (!name || !recipeId) {
        return "Please input DataApp Name and select Recipe to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      return "";
    }
  }, [values, type, errorMsgs]);

  const handleSubmit = () => {
    if (projectId) {
      if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
        if (askAIDataAppTemplateId) {
          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            appTemplateId: askAIDataAppTemplateId,
            projectId,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            dataAppType: type,
            askAIConfig: {
              llmType: _.get(values, dataAppConfigFields.llmModel),
              inputType: _.get(values, dataAppConfigFields.inputType),
              systemMessage:
                _.get(values, dataAppConfigFields.inputType) !==
                DataappAskAIConfigInputTypeEnum.RagFiles
                  ? _.get(values, dataAppConfigFields.systemMessage)
                  : undefined,
              enableCache: _.get(values, dataAppConfigFields.enableCache),
              dataSharingStrategy:
                _.get(values, dataAppConfigFields.inputType) !==
                DataappAskAIConfigInputTypeEnum.RagFiles
                  ? _.get(values, dataAppConfigFields.security)
                  : undefined
            },
            onSuccess
          });
        } else {
          handleResponse({
            errorMessage: "Please make sure to publish ask AI template before creating the app"
          });
        }
        return;
      }

      if (type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel) {
        const currRecipe = recipes.find(
          (recipe) => recipe.id === _.get(values, dataAppConfigFields.recipeId)
        );
        const currentAppTemplate = appTemplates?.find(
          (appTemplate: any) =>
            appTemplate.autoMlProblemType?.toLowerCase() === currRecipe?.metadata?.problemType &&
            appTemplate.buildStatus
        );
        if (!currentAppTemplate) {
          handleResponse({ errorMessage: `App Template not found for the specified recipe;` });
        }

        projectId &&
          currentAppTemplate &&
          currRecipe &&
          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            projectId,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            appTemplateId: currentAppTemplate.id,
            recipeId: currRecipe.id,
            metadata: currRecipe?.runConfigs?.[0]?.variables,
            dataAppType: type,
            askAIConfig: {
              systemMessage: _.get(values, dataAppConfigFields.systemMessage)
            },
            onSuccess
          });
        return;
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px"
        }}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <IconButton style={{ padding: 0 }} onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography component="h3" style={{ color: "#515151" }}>
            {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai ? "Custom" : "Model"}{" "}
            Configuration
          </Typography>
        </div>
        <Tooltip title={disabledTooltipMsg}>
          <span>
            <Button
              size="small"
              style={{ marginRight: "16px" }}
              disabled={!!disabledTooltipMsg || createDataAppMutation.isLoading}
              variant="contained"
              startIcon={
                createDataAppMutation.isLoading ? <CircularProgress size={16} /> : undefined
              }
              color="primary"
              onClick={handleSubmit}>
              Create
            </Button>
          </span>
        </Tooltip>
      </div>
      <Grid container direction="row" spacing={2}>
        <Grid
          item
          xs={type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel ? 12 : 6}
          container
          spacing={2}
          direction="column">
          <Grid item>
            <CreateDataAppInputs
              type={type}
              values={values}
              errorMsgs={errorMsgs}
              recipes={recipes}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <Card
              style={{ padding: "16px", display: "flex", gap: "20px", flexDirection: "column" }}>
              {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
                <SelectAskAILLMModel
                  value={_.get(values, dataAppConfigFields.llmModel)}
                  onChange={handleInputChange}
                />
              )}
              {_.get(values, dataAppConfigFields.inputType) !==
                DataappAskAIConfigInputTypeEnum.RagFiles && (
                <DataAppModelControls values={values} onChange={handleInputChange} />
              )}
            </Card>
          </Grid>
        </Grid>
        {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
          <Grid item xs={6}>
            <Card
              style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "30px" }}>
              <SelectAskAIInputType
                value={_.get(values, dataAppConfigFields.inputType)}
                onChange={handleInputChange}
              />
              <EnableResponseCache
                value={_.get(values, dataAppConfigFields.enableCache)}
                onChange={handleChange}
              />
              {_.get(values, dataAppConfigFields.inputType) !==
                DataappAskAIConfigInputTypeEnum.RagFiles && (
                <SelectDataAppSecurity
                  value={_.get(values, dataAppConfigFields.security)}
                  onChange={handleInputChange}
                />
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CreateDataAppForm;
