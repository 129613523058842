import React, { useEffect, useState, useMemo } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { now } from "lodash";

// MUI
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import PauseIcon from "@material-ui/icons/Pause";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EjectIcon from "@material-ui/icons/Eject";
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { WebPaths } from "src/routing/routes";

// Hooks
import { useUpdateJob } from "src/hooks/api";
import { QUERY_KEY_PROJECT_DETAILS } from "src/hooks/api/projects/useProjectDetails";

// Open API
import { UpdateProjectRunDto } from "openapi/Models";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { TimeoutTextField } from "src/pages/Projects/common/TimeoutTextField/TimeoutTextField";

import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import JobGlobalVariables from "../JobGlobalVariables/JobGlobalVariables";
import ManualJobRun from "../ManualJobRun/ManualJobRun";

// Constants
import { JobDeletePromptDetails, JobsStatuses, JobsHelperText } from "../../utils/Jobs.constants";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { useJobContext } from "./context/useJobContext";

const JobHeader = (props: $TSFixMe) => {
  const {
    projectId,
    saveJob,
    isValidatingNodes,
    isSaving,
    isUpdatingJob,
    updateJobMutation,
    resetUpdateJobMutation,
    isUpdatingJobStatus,
    updateJobStatusMutation,
    resetUpdateJobStatusMutation,
    updateJobTimeoutMutation,
    resetUpdateJobTimeoutMutation,
    isJobDeleting,
    deleteJobMutation,
    resetDeleteJobMutation
  } = props || {};

  const navigate = useNavigate();
  const { jobId } = useParams() || {};

  const queryClient = useQueryClient();

  // Project context
  const { project } = useProjectContext() || {};

  // Job context
  const {
    jobContextState,
    setJobContextState,
    jobRunConfigContextState,
    jobParametersContextState,

    isFetchingDefaultJobNames,
    refetchJobNames,
    defaultJobNames,

    isFetchingJob,
    jobData,
    refetchJob,

    currentJobId,

    isSaved
  } = useJobContext() || {};

  // States - STARTS >>
  const [jobName, setJobName] = useState<$TSFixMe>("");
  const [showConfirmDeleteJobScreen, setShowConfirmDeleteJobScreen] = useState<$TSFixMe>(false);
  const [shouldResetJobName, setShouldResetJobName] = useState<number | boolean>(false);
  const [isJobParametersOpen, setIsJobParametersOpen] = useState<boolean>(false);

  const [isManualJobRunOpen, setIsManualJobRunOpen] = useState<boolean>(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isUpdatingJobName,
    mutateAsync: updateJobNameMutation,
    reset: resetUpdateJobNameMutation
  } = useUpdateJob();
  // << ENDS - Query hooks

  // Sync jobContextState - STARTS >>
  useEffect(() => {
    const thisJobContextState = jobContextState || {};
    thisJobContextState.jobName = jobName;

    setJobContextState(() => ({ ...thisJobContextState }));
  }, [jobName]);
  // << ENDS - Sync jobContextState

  const navigateBack = () => {
    if (projectId) {
      navigate(-1);
    }
  };

  // Job Name - STARTS >>
  useEffect(() => {
    refetchJobNames();
  }, []);

  useEffect(() => {
    const _ = async () => {
      let name: $TSFixMe = JobsHelperText?.UntitledJob;
      if (defaultJobNames?.length > 0) {
        let i = 2;

        while (defaultJobNames?.includes(name)) {
          name = `${JobsHelperText?.UntitledJob} ${i}`;
          i++;
        }
      }

      setJobName(() => name);
    };

    !jobData?.id && _();
  }, [jobData?.id, defaultJobNames]);

  useEffect(() => {
    jobData?.id && jobData?.name && setJobName(() => jobData?.name);
  }, [jobData?.id]);

  const updateJobName = async (name?: string) => {
    if (!jobId || !name) {
      return;
    }

    const payload: UpdateProjectRunDto = {
      id: jobId,
      name
    };

    await resetUpdateJobNameMutation();
    await updateJobNameMutation(payload, {
      onSuccess: async () => {
        await refetchJob();
        toastWrapper({ type: ToastTypes.Success, content: JobsHelperText.JobNameUpdated });
      },
      onError: () => setShouldResetJobName(now())
    });
  };
  // << ENDS - Job Name

  const pauseJob = async () => {
    resetUpdateJobStatusMutation();

    const payload = { id: currentJobId, status: JobsStatuses.Inactive };
    await updateJobStatusMutation(payload, {
      onSuccess: () => {
        refetchJob();
        toastWrapper({ type: "success", content: "Scheduler paused successfully!" });
      }
    });
  };

  const resumeJob = async () => {
    resetUpdateJobStatusMutation();

    const payload = { id: currentJobId, status: JobsStatuses.Active };
    await updateJobStatusMutation(payload, {
      onSuccess: () => {
        refetchJob();
        toastWrapper({ type: "success", content: "Scheduler resumed successfully!" });
      }
    });
  };

  const updateJobTimeout = async (timeout: number) => {
    if (timeout === jobData?.timeoutInHours) {
      return;
    }

    resetUpdateJobTimeoutMutation();

    const payload = { ...jobData, timeoutInHours: timeout };
    await updateJobTimeoutMutation(payload, {
      onSuccess: () => {
        refetchJob();
        toastWrapper({ type: "success", content: "Job Timeout updated successfully!" });
      }
    });
  };

  const navigateToJobRunsPage = () => {
    navigate(
      generatePath(`${WebPaths.JobRoutes}${WebPaths.JobRuns}`, {
        projectId,
        jobId: currentJobId
      })
    );
  };

  // Delete job - STARTS >>
  const deleteJob = async () => {
    resetDeleteJobMutation();

    await deleteJobMutation(
      { jobId: currentJobId },
      {
        onSuccess: () => {
          toastWrapper({ type: "success", content: "Job deleted successfully!" });
          queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
          navigateBack();
        },
        onSettled: () => {
          setShowConfirmDeleteJobScreen(() => false);
        }
      }
    );
  };

  const promptConfirmDeleteJob = () => {
    setShowConfirmDeleteJobScreen(() => true);
  };

  const cancelDeleteJob = () => {
    setShowConfirmDeleteJobScreen(() => false);
  };
  // << ENDS - Delete job

  const disabledPauseActionMessage = useMemo(() => {
    if (isSaving) {
      return "There is another action in progress. Please wait.";
    } else if (isUpdatingJobStatus) {
      return "Scheduler pause action is in progress. Please wait.";
    } else if (!isSaved) {
      return "Save any unsaved changes in order to use this option.";
    } else {
      return "";
    }
  }, [isSaved, isSaving, isUpdatingJobStatus]);

  const disabledResumeActionMessage = useMemo(() => {
    if (isSaving) {
      return "There is another action in progress. Please wait.";
    } else if (isUpdatingJobStatus) {
      return "Scheduler resume action is in progress. Please wait.";
    } else if (!isSaved) {
      return "Save any unsaved changes in order to use this option.";
    } else {
      return "";
    }
  }, [isSaved, isSaving, isUpdatingJobStatus]);

  const disabledRunHistoryActionMessage = useMemo(() => {
    if (isSaving) {
      return "There is another action in progress. Please wait.";
    } else {
      return "";
    }
  }, [isSaving]);

  const disabledRunActionMessage = useMemo(() => {
    if (isSaving) {
      return "There is another action in progress. Please wait.";
    } else if (!isSaved) {
      return "Save any unsaved changes in order to use this option.";
    } else {
      return "";
    }
  }, [isSaving, isSaved]);

  const disabledSaveActionMessage = useMemo(() => {
    if (isFetchingDefaultJobNames || isValidatingNodes) {
      return "There is another action in progress. Please wait.";
    } else if (isSaving) {
      return "The save action is in progress. Please wait.";
    } else if (isSaved) {
      return "No changes to be saved.";
    } else if (!isSaved && !jobRunConfigContextState?.isValid) {
      return "Invalid run configuration.";
    } else {
      return "";
    }
  }, [
    isFetchingDefaultJobNames,
    isValidatingNodes,
    isSaving,
    isSaved,
    jobRunConfigContextState?.isValid
  ]);

  return (
    <>
      {showConfirmDeleteJobScreen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Job"
          content={[JobDeletePromptDetails.messageLine1, JobDeletePromptDetails.messageLine2]}
          onClose={cancelDeleteJob}
          onSubmit={deleteJob}
          isCancelDisabled={isJobDeleting}
          isSubmitDisabled={isJobDeleting}
          isSubmitting={isJobDeleting}
        />
      )}

      {isJobParametersOpen && (
        <JobGlobalVariables
          close={() => setIsJobParametersOpen(() => false)}
          isReadOnly={!currentJobId}
          jobId={currentJobId}
          isUpdatingJob={isUpdatingJob}
          updateJobMutation={updateJobMutation}
          resetUpdateJobMutation={resetUpdateJobMutation}
          refetchJob={refetchJob}
        />
      )}

      {isManualJobRunOpen && (
        <ManualJobRun
          close={() => setIsManualJobRunOpen(() => false)}
          projectId={projectId}
          jobId={currentJobId}
          jobParametersData={jobData?.computedVariables || {}}
          navigateToJobRunsPage={navigateToJobRunsPage}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: WebPaths.Canvas,
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              jobName={jobName}
              setJobName={setJobName}
              updateJobName={updateJobName}
              isReadonlyJobName={
                !!isUpdatingJobName || !!isFetchingDefaultJobNames || !!isFetchingJob
              }
              shouldResetJobName={shouldResetJobName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(currentJobId
            ? {
                moreOptions: [
                  {
                    label: "Run",
                    icon: <RunIcon viewBox="0 0 20 20" />,
                    action: () => setIsManualJobRunOpen(() => true),
                    disabled: !!disabledRunActionMessage,
                    tooltip: disabledRunActionMessage
                  },
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => promptConfirmDeleteJob()
                  },
                  {
                    label: jobData?.timeoutInHours
                      ? `Timeout ${jobData?.timeoutInHours}hr`
                      : "Timeout",
                    icon: <TimeoutIcon viewBox="0 0 20 22" />,
                    nestedComponent: (
                      <TimeoutTextField
                        value={jobData?.timeoutInHours}
                        // onChange here is called on onBlur in the component.
                        minValue={1}
                        onChange={(timeout) => updateJobTimeout(timeout)}
                      />
                    )
                  }
                ]
              }
            : {}),
          component: (
            <>
              {currentJobId && jobData?.status === JobsStatuses.Active && (
                <Tooltip title={disabledPauseActionMessage || "Pause Scheduler"}>
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      disabled={!!disabledPauseActionMessage}
                      onClick={pauseJob}>
                      {isUpdatingJobStatus ? <CircularProgress size={20} /> : <PauseIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {currentJobId && jobData?.status === JobsStatuses.Inactive && (
                <Tooltip title={disabledResumeActionMessage || "Resume Scheduler"}>
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      disabled={!!disabledResumeActionMessage}
                      onClick={resumeJob}>
                      {isUpdatingJobStatus ? (
                        <CircularProgress size={20} />
                      ) : (
                        <EjectIcon style={{ transform: "rotate(90deg)" }} />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {currentJobId && (
                <Tooltip title={disabledRunHistoryActionMessage || "Run History"}>
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      disabled={!!disabledRunHistoryActionMessage}
                      onClick={navigateToJobRunsPage}>
                      <AccessTimeIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {(jobParametersContextState || [])?.length > 0 && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setIsJobParametersOpen(() => true)}>
                  Global Variables ({(jobParametersContextState || [])?.length})
                </Button>
              )}

              <Tooltip title={disabledSaveActionMessage}>
                <span>
                  <Button
                    color="primary"
                    size="small"
                    disabled={!!disabledSaveActionMessage}
                    onClick={saveJob}>
                    {isSaving ? <CircularProgress size={20} /> : "Save"}
                  </Button>
                </span>
              </Tooltip>
            </>
          )
        }}
      />
    </>
  );
};

export default JobHeader;
