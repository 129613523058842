import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import Papa from "papaparse";
import { SignedUrlObjectTypeEnum } from "src/hooks/api/common/useGetSignedUrlMutation";

import { getAPIWithRethrow, postAPIWithRethrow } from "src/utils/apiService";

export const QUERY_KEY_FETCH_CHARTS = "query-key-fetch-charts";

interface IVariables {
  name: string;
  dataFilePath: string | undefined;
  chartType: string;
  shouldFetchData?: boolean;
}

const useFetchChartData = (
  { name, dataFilePath, chartType }: IVariables,
  options?: UseQueryOptions<any, any>
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_FETCH_CHARTS, name, dataFilePath, chartType],
    queryFn: async () => {
      if (!dataFilePath) {
        return null;
      }

      const payload = {
        fileName: name,
        filePath: dataFilePath,
        signedUrlObjectType: SignedUrlObjectTypeEnum.GENERAL
      };
      const response = await postAPIWithRethrow(`/signed-url/generate-file-download-url`, payload);
      const fileData = await getAPIWithRethrow(response.signedUrl, {
        headers: response.headers,
        signedUrl: true
      });
      if (chartType === "IMAGE") {
        const res = await fetch(response.signedUrl);
        const blob = await res.blob();

        return URL.createObjectURL(blob);
      }
      if (chartType === "TABLE") {
        return new Promise((resolve, reject) => {
          Papa.parse(fileData, {
            header: true,
            skipEmptyLines: true,
            complete(results: any) {
              resolve(results.data);
            },
            error(err) {
              reject(err);
            }
          });
        });
      }
      if (chartType === "JSON") {
        //Remove title
        return { ...fileData, layout: { ...fileData.layout, title: null } };
      } else {
        return fileData;
      }
    },
    ...options
  });
};

export default useFetchChartData;
