import React, { useCallback, useMemo } from "react";

import { Tooltip } from "@material-ui/core";

import { DATA_TYPES_ICONS } from "src/icons";
import { Table } from "src/components";

import styles from "./ViewDataDataTable.module.scss";
import uniq from "lodash/uniq";

const getElementWithTooltip = ({ value = "", customStyles }: $TSFixMe) => (
  <Tooltip title={value.length >= 15 ? value : ""}>
    <span className={`${styles.cell} ${customStyles}`}>{value}</span>
  </Tooltip>
);

const ViewDataDataTable = ({
  responseData,
  isLoadingData,
  entityFeatures,
  showPagination,
  columnOptionsCustomRender,
  onColumnChange,
  hideCount = true,
  maxHeight = "calc(100vh - 179px)",
  globalFilter,
  showSample
}: $TSFixMe) => {
  const iconSelection = useCallback(
    (column: $TSFixMe) => {
      const fieldSchema = entityFeatures?.find(
        (entityFeatureInternal: $TSFixMe) => entityFeatureInternal?.name === column
      )?.fieldSchema;
      return (
        fieldSchema && (
          <span className={styles.iconContainer}>
            {
              (DATA_TYPES_ICONS as $TSFixMe)[
                fieldSchema?.fieldProperties?.idLikeColumn ? "ID" : fieldSchema?.rcDataType
              ]
            }
          </span>
        )
      );
    },
    [entityFeatures]
  );

  const fullListOfColumns = useMemo(() => {
    return uniq([
      ...(responseData?.columns || []),
      ...(entityFeatures?.map((item: $TSFixMe) => item?.name) || [])
    ]);
  }, [entityFeatures, responseData?.columns]);

  const tableColumns = useMemo(() => {
    return (fullListOfColumns || []).map((column: $TSFixMe, index: number) => {
      const dataType = entityFeatures?.find(
        (entityFeatureInternal: $TSFixMe) => entityFeatureInternal?.name === column
      )?.fieldSchema?.rcDataType;
      return {
        id: column || `column_${index}`,
        name: column,
        Header: column || "Unknown",
        accessor: column,
        icon: iconSelection(column),
        data: {
          dataType
        },
        Cell: ({ cell, column }: $TSFixMe) => {
          if (column.data.dataType === "DOUBLE" || column.data.dataType === "FLOAT") {
            const value = cell.row.original[column.name];
            const formattedValue = value ? Number(value).toFixed(2) : value;
            return getElementWithTooltip({ value: formattedValue });
          }
          return getElementWithTooltip({ value: cell.row.original[column.name] });
        },
        ...(columnOptionsCustomRender && { width: 250 })
      };
    });
  }, [fullListOfColumns, entityFeatures, iconSelection, columnOptionsCustomRender]);

  const tableData =
    responseData?.tableData ||
    (!responseData?.rows || !responseData?.columns
      ? []
      : responseData?.rows?.reduce((responseDataAcc: $TSFixMe, responseDataCurr: $TSFixMe) => {
          responseDataAcc.push(
            responseDataCurr.cells.reduce(
              (cellAcc: $TSFixMe, cellCurr: $TSFixMe, cellIndex: $TSFixMe) => {
                cellAcc[responseData?.columns[cellIndex]] =
                  !!cellCurr &&
                  typeof cellCurr === "string" &&
                  ["nan"].includes(cellCurr?.trim()?.toLowerCase())
                    ? ""
                    : cellCurr;

                return cellAcc;
              },
              {}
            )
          );

          return responseDataAcc;
        }, []));
  return (
    <Table
      size="small"
      columns={tableColumns ?? []}
      hideCount={hideCount}
      data={tableData ?? []}
      isLoading={isLoadingData}
      showSample={showSample}
      fixedLayout={true}
      showPagination={showPagination}
      {...(showPagination && { rowsPerPage: 15 })}
      columnOptionsCustomRender={columnOptionsCustomRender}
      maxHeight={maxHeight}
      onSelectedColumnChange={onColumnChange}
      isTheadSticky
      unsorted
      globalFilter={globalFilter}
      hiddenColumns={fullListOfColumns.slice(50)}
    />
  );
};

export default ViewDataDataTable;
