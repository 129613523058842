import _ from "lodash";
import React, { useState } from "react";
import { Button, Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

import { Spinner } from "src/components";
import {
  QUERY_KEY_RECIPE,
  useAddMessageToOutputMutation,
  useRemoveMessageFromOutputMutation
} from "src/hooks/api";
import PinnedDatasets from "../AskAIInputDatasets/PinnedDatasets";
import AutoSelectDataset from "../AskAIInputDatasets/AutoSelectDataset";
import { ViewOutputIcon } from "src/icons/NewUX/ViewOutputIcon";
import { ViewCodeIcon } from "src/icons/NewUX/ViewCodeIcon";

import { AddOutputRequestDtoFlowTypeEnum } from "openapi/Models/add-output-request-dto";
import { OutputEntity } from "openapi/Models/output-entity";
import { OutputEntityDto } from "openapi/Models/output-entity-dto";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { handleResponse } from "src/utils/apiService";
import { useQueryClient } from "@tanstack/react-query";
import { AIChatRequestDtoOutputTypeEnum } from "openapi/Models";
import { ShowUpdateNameDialog } from "../AskAIResponseContainer/ShowUpdateNameDialog";

const useStyles = makeStyles({
  recipeBtn: {
    backgroundColor: "#fff",
    height: "26px"
  },
  actions: {
    gap: "16px"
  },
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "4px"
  }
});

export const AskAIHeaderActions = ({
  message,
  isOutputDataset,
  setShouldShowCode,
  shouldShowCode,
  existingOutputNames,
  explainIsloading,
  onExplainCode,
  explainCodeSuccess
}: {
  message: AIChatResponseDto;
  isOutputDataset: boolean;
  setShouldShowCode: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowCode: boolean;
  targetId: string | undefined;
  existingOutputNames: string[];
  onExplainCode: () => void;
  explainIsloading: boolean;
  explainCodeSuccess: boolean;
}) => {
  const classes = useStyles();
  const {
    transformId,
    outputEntityList,
    outputEntityResponseList,
    threadId,
    askAIMessageId,
    error
  } = message;
  const isAddedToRecipe = !!transformId;
  const [showUpdateNameDialog, setShowUpdateNameDialog] = useState(false);
  const addMessageToOutputMutation = useAddMessageToOutputMutation(threadId!);
  const removeMessageFromOutputMutation = useRemoveMessageFromOutputMutation(threadId!);

  const onRemoveFromRecipe = () => {
    removeMessageFromOutputMutation.mutate(
      {
        messageId: askAIMessageId!
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY_RECIPE] });
        }
      }
    );
  };

  const queryClient = useQueryClient();
  const onAddToRecipe = (data: { string: string }) => {
    const outputNames = Object.values(data);
    const duplicateNames = _.intersection(existingOutputNames, outputNames);
    if (duplicateNames.length !== 0) {
      handleResponse({
        errorMessage: `The output name(s) - ${duplicateNames.join(", ")} are already in use. Please ensure each output name is unique before saving.`
      });
      return;
    }

    const nameChanges = _.map(_.entries(data), ([oldOutputName, newOutputName]) => ({
      oldName: oldOutputName,
      newName: newOutputName
    }));
    addMessageToOutputMutation.mutate(
      {
        messageId: askAIMessageId!,
        request: {
          flowType: AddOutputRequestDtoFlowTypeEnum.Recipe,
          nameChanges
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY_RECIPE] });
          setShowUpdateNameDialog(false);
        }
      }
    );
  };

  const datasetName = _.first(_.map(outputEntityResponseList || outputEntityList, "name"));
  const isRecipeUpdating = removeMessageFromOutputMutation.isLoading;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="flex-end"
      className={classes.actions}
      onClick={(e) => e.stopPropagation()}>
      {isOutputDataset && datasetName && (
        <>
          <PinnedDatasets name={datasetName} />
          <AutoSelectDataset name={datasetName} />
        </>
      )}

      <Tooltip title={shouldShowCode ? "View Output" : "View Code"}>
        <div>
          <IconButton
            className={classes.queryBtn}
            data-testid={`ask-ai-view-${shouldShowCode ? "output" : "code"}-btn`}
            onClick={(event: any) => {
              event.stopPropagation();
              setShouldShowCode((show) => !show);
            }}>
            {shouldShowCode ? <ViewOutputIcon /> : <ViewCodeIcon />}
          </IconButton>
        </div>
      </Tooltip>
      {shouldShowCode && (
        <Tooltip title={explainCodeSuccess ? "Code explanation is already done" : ""}>
          <span>
            <Button
              color="primary"
              size="small"
              className={classes.recipeBtn}
              variant="outlined"
              data-testid={`ask-ai-view-explain-code-btn`}
              disabled={explainIsloading || explainCodeSuccess}
              onClick={(event: any) => {
                event.stopPropagation();
                onExplainCode();
              }}>
              {explainIsloading ? <Spinner size={18} noPadding /> : "Explain"}
            </Button>
          </span>
        </Tooltip>
      )}

      {_.isEmpty(error) && (
        <Button
          color="primary"
          size="small"
          className={classes.recipeBtn}
          startIcon={
            !isRecipeUpdating &&
            (isAddedToRecipe ? <Remove color="primary" /> : <Add color="primary" />)
          }
          disabled={isRecipeUpdating || addMessageToOutputMutation.isLoading}
          onClick={(event: $TSFixMe): void => {
            event.stopPropagation();
            isAddedToRecipe ? onRemoveFromRecipe() : setShowUpdateNameDialog(true);
          }}
          data-testid={`ask-ai-${isAddedToRecipe ? "remove-from" : "add-to"}-recipe-btn`}>
          {isRecipeUpdating ? <Spinner size={18} noPadding /> : "Recipe"}
        </Button>
      )}
      {showUpdateNameDialog && (
        <ShowUpdateNameDialog
          isSubmitLoading={addMessageToOutputMutation.isLoading}
          isSubmitDisabled={addMessageToOutputMutation.isLoading}
          onSubmit={onAddToRecipe}
          title={"Update Name"}
          onClose={() => {
            setShowUpdateNameDialog(false);
          }}
          label={isOutputDataset ? "Dataset Name" : "Chart Name"}
          queryOutputs={_.map(
            outputEntityResponseList || outputEntityList,
            (entity: OutputEntityDto | OutputEntity) => {
              return {
                name: entity.name as string,
                isModel: entity.outputType === AIChatRequestDtoOutputTypeEnum.Model
              };
            }
          )}
        />
      )}
    </Grid>
  );
};

export default React.memo(AskAIHeaderActions);
