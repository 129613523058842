/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AdditionalChart } from './additional-chart';
// May contain unused imports in some cases
// @ts-ignore
import { DataappAskAIConfig } from './dataapp-ask-aiconfig';

/**
 * 
 * @export
 * @interface UpdateDataAppRequestDto
 */
export interface UpdateDataAppRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDataAppRequestDto
     */
    'ttlInHrs'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDataAppRequestDto
     */
    'params'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDataAppRequestDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof UpdateDataAppRequestDto
     */
    'cores'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDataAppRequestDto
     */
    'memInMbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDataAppRequestDto
     */
    'diskInGbs'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'envType'?: UpdateDataAppRequestDtoEnvTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataAppRequestDto
     */
    'groupId'?: string;
    /**
     * 
     * @type {Array<AdditionalChart>}
     * @memberof UpdateDataAppRequestDto
     */
    'additionalCharts'?: Array<AdditionalChart>;
    /**
     * 
     * @type {DataappAskAIConfig}
     * @memberof UpdateDataAppRequestDto
     */
    'askAIConfig'?: DataappAskAIConfig;
}

export const UpdateDataAppRequestDtoEnvTypeEnum = {
    ExtraSmall: 'EXTRA_SMALL',
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE',
    CpuLarge: 'CPU_LARGE',
    MaxLarge: 'MAX_LARGE',
    ExtraMaxLarge: 'EXTRA_MAX_LARGE',
    Custom: 'CUSTOM'
} as const;

export type UpdateDataAppRequestDtoEnvTypeEnum = typeof UpdateDataAppRequestDtoEnvTypeEnum[keyof typeof UpdateDataAppRequestDtoEnvTypeEnum];


