import React from "react";

// Packages
import clsx from "clsx";

// MUI
import ButtonGroup, { ButtonGroupProps } from "@material-ui/core/ButtonGroup";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import { CardsViewIcon } from "src/icons/NewUX/CardsViewIcon";
import { TableViewIcon } from "src/icons/NewUX/TableViewIcon";

type ActionProps = {
  icon: React.ReactNode;
  info: "";
};

type Props = {
  buttonGroupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  primaryActionProps?: ActionProps;
  secondaryActionProps?: ActionProps;
  isPrimaryView: boolean;
  setIsPrimaryView: (isPrimaryView: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "auto",
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    pointerEvents: "auto",
    cursor: "pointer",
    opacity: 0.5,
    "&.inactive": { cursor: "help", opacity: 1 }
  }
}));

const ToggleView = (props: Props) => {
  const {
    buttonGroupProps,
    buttonProps,
    primaryActionProps = {
      icon: <CardsViewIcon viewBox="0 0 18 18" />,
      info: "Card view"
    },
    secondaryActionProps = {
      icon: <TableViewIcon viewBox="0 0 20 20" />,
      info: "List view"
    },
    isPrimaryView,
    setIsPrimaryView
  } = props || {};

  const classes = useStyles();

  const toggleView = () => setIsPrimaryView(!isPrimaryView);

  return (
    <ButtonGroup color="primary" {...buttonGroupProps}>
      <Tooltip title={primaryActionProps?.info || ""}>
        <Button
          data-testid="cardView"
          className={clsx(classes.button, { ["inactive"]: isPrimaryView })}
          {...(!isPrimaryView ? { onClick: toggleView } : {})}
          {...buttonProps}>
          {primaryActionProps?.icon}
        </Button>
      </Tooltip>
      <Tooltip title={secondaryActionProps?.info || ""}>
        <Button
          data-testid="tableView"
          className={clsx(classes.button, { ["inactive"]: !isPrimaryView })}
          {...(!!isPrimaryView ? { onClick: toggleView } : {})}
          {...buttonProps}>
          {secondaryActionProps?.icon}
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ToggleView;
