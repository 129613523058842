import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import _, { delay } from "lodash";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

import useAuthStore from "src/stores/auth.store";
import GenerateCodeBar, { STOPPED_QUERY_ID } from "./GenerateCodeBar";
import AIGuideResponse from "./AIGuideResponse";
import { SuggestionPrompts } from "./SuggestionPrompts";
import { AIGuideTextRow } from "./AIGuideTextRow";
import GenerateLoader from "src/components/Loader/GenerateLoader";
import { AskAiA11yBackdrop } from "src/components/custom";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { EntityFeaturesResponse } from "src/types";
import data from "./ai-guide-loader.json";
import { InputIdDto } from "openapi/Models/input-id-dto";

const useStyles = makeStyles({
  container: {
    height: "-webkit-fill-available"
  },
  aiGuide: {
    justifyContent: "space-between",
    height: "100%",
    position: "relative"
  },
  askAiA11yContainer: {
    position: "relative",
    flexGrow: 1
  },
  aiGuideConvoGrid: {
    overflow: "auto",
    position: "relative"
  },
  rightBar: {
    padding: "24px 16px 12px 16px",
    display: "flex",
    width: "calc(100% - 44px)"
  },
  helpText: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    lineHeight: 1.54,
    padding: "4px 16px"
  },
  floatBtns: {
    position: "absolute",
    bottom: "124px",
    right: "32px",
    zIndex: 99,
    width: "auto",
    gap: "8px",
    justifyContent: "flex-end"
  },
  floatBtn: {
    color: "#303077",
    border: "2px solid #303077"
  }
});

const AiGuideConvoGrid = ({
  children,
  isGenerating,
  query,
  setQuery,
  generatingQuery,
  messages,
  handleGenerateCode,
  handleGenerateStop,
  suggestions = [],
  columns,
  inputIds,
  isDisabled,
  projectId
}: {
  children: React.ReactNode;
  isGenerating: boolean;
  query: string;
  setQuery: (query: string) => void;
  generatingQuery: string;
  messages: AIChatResponseDto[];
  handleGenerateCode: (query?: string) => void;
  handleGenerateStop: () => void;
  suggestions?: string[];
  entityData?: EntityFeaturesResponse | undefined;
  columns?: string[];
  inputIds: InputIdDto[];
  isDisabled: boolean;
  projectId: string;
}) => {
  const classes = useStyles();
  const askAiEnabled = useAuthStore((state) => state.askAiEnabled);
  const askAIContainerRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messageStartRef = useRef<HTMLDivElement | null>(null);
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);
  const [isScrollToBottomVisible, setIsScrollToBottomVisible] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [isGenerating]);

  useEffect(() => {
    delay(() => {
      scrollToBottom();
    }, 1000);
  }, []);

  const handleScroll = (event: any) => {
    const TOLERANCE = screen.height * 0.5;
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + TOLERANCE;
    if (scrollTop > TOLERANCE) {
      setIsScrollToTopVisible(true);
    } else {
      setIsScrollToTopVisible(false);
    }
    setIsScrollToTopVisible(scrollTop > TOLERANCE);
    setIsScrollToBottomVisible(!isAtBottom);
  };

  const scrollToTop = () => {
    messageStartRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.aiGuide}
      onScroll={handleScroll}>
      {!askAiEnabled ? (
        <Grid item className={classes.askAiA11yContainer}>
          <AskAiA11yBackdrop styleProps={{ backgroundColor: "transparent" }} />
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.aiGuideConvoGrid}
          ref={askAIContainerRef}>
          <div ref={messageStartRef}></div>
          <Grid container direction="column" wrap="nowrap" className={classes.rightBar}>
            {children}
          </Grid>
          {messages?.map((message, index) => {
            const isLastMessage = index === messages?.length - 1;
            const isStoppedQuery = message.askAIMessageId === STOPPED_QUERY_ID;
            return (
              <AIGuideResponse
                message={message}
                key={message.askAIMessageId}
                isGenerating={isGenerating}
                canRetry={isLastMessage && !isGenerating && !isStoppedQuery}
                hideInputs
                inputIds={inputIds}
                hideFeedback={isStoppedQuery}
                projectId={projectId}
              />
            );
          })}
          <div ref={messagesEndRef}></div>
          {isGenerating && data && (
            <Grid container direction="column">
              <AIGuideTextRow
                maxHeight="132px"
                width="750px"
                hoverBgColor="#fff"
                userInput={generatingQuery}
                color="#fff"
              />
              <Grid
                container
                direction="column"
                wrap="nowrap"
                style={{ paddingBottom: "calc(30vh)" }}>
                <GenerateLoader
                  testId="generate-output-loader"
                  title={"Generating Output..."}
                  data={data}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container direction="column" wrap="nowrap">
        <SuggestionPrompts
          suggestions={suggestions}
          isDisabled={!askAiEnabled || isGenerating || isDisabled}
          disabledTitle={
            askAiEnabled && (isGenerating || isDisabled)
              ? "Wait until existing query run is complete"
              : ""
          }
          onClick={(suggestion) => {
            handleGenerateCode(suggestion);
          }}
        />
        <GenerateCodeBar
          query={query}
          setQuery={setQuery}
          isGenerating={isGenerating}
          onGenerateCode={handleGenerateCode}
          onGenerateStop={handleGenerateStop}
          disabled={!askAiEnabled || isDisabled}
          columns={columns}
        />
        <Grid container item direction="row-reverse">
          {columns && columns?.length !== 0 && (
            <Typography className={classes.helpText} data-test-id="ai-column-help-text">
              <b>Use @</b> in query box for column names.
            </Typography>
          )}
          <Typography className={classes.helpText} data-test-id="ai-help-text">
            <b>Shift/Ctrl + Return</b> to add a new line
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" wrap="nowrap" className={classes.floatBtns}>
        {isScrollToTopVisible && (
          <IconButton
            size="small"
            data-test-id="ai-scroll-to-top-btn"
            onClick={scrollToTop}
            className={classes.floatBtn}>
            <ArrowUpward fontSize="medium" />
          </IconButton>
        )}
        {isScrollToBottomVisible && (
          <IconButton
            size="small"
            data-test-id="ai-scroll-to-bottom-btn"
            onClick={scrollToBottom}
            className={classes.floatBtn}>
            <ArrowDownward fontSize="medium" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(AiGuideConvoGrid);
