import React, { useMemo, useState } from "react";
import { Box, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import { BarButton } from "src/components/SubtopBar/BarButton";
import { Delete } from "src/icons/Delete";
import { useProjectsStore } from "src/store/store";
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";
import { includes } from "lodash";

type Props = {
  id: string;
  onDelete: () => void;
};

export const ProjectSettingsDrawerTitle = ({ id, onDelete }: Props) => {
  const { moreOptionsButton, barMenu } = useSubtopBarStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatingProjectIds] = useProjectsStore((state) => [state.updatingProjectIds]);

  const isUpdating = useMemo(() => includes(updatingProjectIds, id), [id, updatingProjectIds]);

  const handleMenuClick = (e?: $TSFixMe) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(e?.currentTarget);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h6">Project Settings</Typography>
      <div>
        <button
          id="project-settings-options-button"
          onClick={handleMenuClick}
          className={moreOptionsButton}>
          <MoreVert color="primary" />
        </button>
        <Menu
          id="project-settings-options-menu"
          className={barMenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          getContentAnchorEl={undefined}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClick}>
          <Tooltip title={isUpdating ? "Please wait until the project is getting updated" : ""}>
            <span>
              <MenuItem id="delete-project-button" onClick={onDelete} disabled={isUpdating}>
                <BarButton icon={<Delete />} label="Delete Project" />
              </MenuItem>
            </span>
          </Tooltip>
        </Menu>
      </div>
    </Box>
  );
};
