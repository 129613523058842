import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { filter, includes, toLower } from "lodash";

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormHelperText,
  Tooltip,
  MenuItemProps,
  ListSubheader
} from "@material-ui/core";
import SelectSearch from "src/components/Inputs/SelectSearch";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    margin: 0,
    background: "#fff"
  },
  formControlSmall: {
    // TODO: below code makes label overlap if label is not hidden
    "& .MuiSelect-root": {
      padding: 0,
      paddingBottom: 7,
      fontSize: 14
    }
  },
  select: {
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center"
    },
    "& .MuiSelect-outlined": {
      backgroundColor: "#ffffff"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D8D8D8",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D8D8D8"
    }
  },
  selectMenu: {
    height: "350px",
    maxWidth: "400px",
    "& .Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: "#F2F9FF"
    }
  },
  fixedMenuHeight: {
    maxHeight: 250
  },
  icon: {
    fill: theme.palette.primary.main
  },
  menuItem: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
      backgroundColor: "none"
    }
  },
  subHeader: {
    borderTop: "1px solid #0003"
  }
}));

interface SelectValue {
  label: React.ReactNode;
  value: string;
  disabled?: boolean;
  helpText?: string;
  rightComponent?: React.ReactNode;
  category?: string;
}
interface SelectProps extends MuiSelectProps {
  values: SelectValue[];
  hideInputLabel?: boolean;
  size?: string;
  fixedMenuHeight?: boolean;
  helperText?: string;
  menuProps?: any;
}

export const NO_TARGET_COL_TEXT = "I don’t have a Target Column";

const TargetColumnSelect = ({
  label,
  value,
  variant = "filled",
  size = "medium",
  disabled,
  values,
  onChange,
  fullWidth,
  margin = "dense",
  inputProps,
  placeholder,
  hideInputLabel = false,
  fixedMenuHeight = false,
  required,
  helperText,
  menuProps = {},
  ...restProps
}: SelectProps) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>("");

  const filtered = useMemo(() => {
    return filter(values, ({ value }) => includes(toLower(value.toString()), toLower(search)));
  }, [values, search]);

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      className={clsx(classes.formControl, {
        [classes.formControlSmall]: size === "small"
      })}
      required={required}
      margin={margin}
      disabled={disabled}>
      {!hideInputLabel && (
        <InputLabel htmlFor="mutiple-select" id="select-label">
          {label}
        </InputLabel>
      )}
      <MuiSelect
        labelId="select-label"
        variant={variant}
        value={value}
        required={required}
        label={!hideInputLabel ? label : ""}
        placeholder={placeholder}
        onChange={(event: any, child: any) => {
          if (child.props.disabled) {
            return;
          }
          onChange?.(event, child);
        }}
        className={classes.select}
        inputProps={{
          name: "mutiple-select",
          id: "mutiple-select",
          ...inputProps
        }}
        MenuProps={{
          classes: {
            paper: clsx(classes.selectMenu, {
              [classes.fixedMenuHeight]: fixedMenuHeight
            })
          },
          MenuListProps: { disablePadding: true },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          autoFocus: false,
          getContentAnchorEl: null,
          ...menuProps
        }}
        onClose={() => setSearch("")}
        {...restProps}>
        <MenuItemWithTooltip value={NO_TARGET_COL_TEXT}>{NO_TARGET_COL_TEXT}</MenuItemWithTooltip>
        <ListSubheader className={classes.subHeader}>{"Target Column"}</ListSubheader>
        <SelectSearch value={search} onChange={setSearch} />
        {filtered.map(({ label, helpText, value: listVal, disabled, rightComponent }) => (
          <MenuItemWithTooltip
            key={listVal}
            id={listVal}
            helpText={helpText || ""}
            value={listVal}
            disabled={disabled}
            className={classes.menuItem}>
            {label} {rightComponent}
          </MenuItemWithTooltip>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

interface MenuItemWithTooltipProps extends MenuItemProps {
  helpText?: string;
}

const MenuItemWithTooltip = React.forwardRef((props: MenuItemWithTooltipProps, ref) => {
  const { children, helpText, ...restProps } = props;
  return (
    <Tooltip title={helpText || ""} placement="bottom-start">
      <MenuItem {...restProps} innerRef={ref} button>
        {children}
      </MenuItem>
    </Tooltip>
  );
});
MenuItemWithTooltip.displayName = "MenuItemWithTooltip";

export default TargetColumnSelect;
