import { every, has, isEmpty, isNil, size, toLower } from "lodash";

import {
  getLocalStorageItem,
  removeLocalStorageItem
} from "src/services/LocalStorage/LocalStorage.service";
import {
  getSessionStorageItem,
  removeSessionStorageItem
} from "src/services/SessionStorage/SessionStorage.service";

import { AuthSessionEnum } from "src/constants";
import { TableSessionConfig } from "src/constants/table.constants";

export const getTokenFromStorage = () => {
  const tokenFromLocalStorage = getLocalStorageItem({
    key: AuthSessionEnum.TokenSessionKey,
    shouldJsonParse: false
  });

  const tokenFromSessionStorage = getSessionStorageItem({
    key: AuthSessionEnum.TokenSessionKey,
    shouldJsonParse: false
  });

  return tokenFromLocalStorage || tokenFromSessionStorage;
};

export const onLogout = () => {
  removeLocalStorageItem(TableSessionConfig.TablePreferencesSessionKey);
  removeSessionStorageItem(TableSessionConfig.TablePreferencesSessionKey);
};

export const areAllKeysPresentAndNotNil = (dataMap?: { [key: string]: any }, keys?: string[]) => {
  if (isNil(dataMap) || isNil(keys)) {
    return false;
  }

  if (isEmpty(dataMap) || isEmpty(keys)) {
    return false;
  }

  return every(keys, (key) => has(dataMap, key) && !isNil(dataMap[key]));
};

interface GetReactHookFormTextFieldRulesProps {
  fieldName?: string;
  minLength?: number;
  maxLength?: number;
}

export const getReactHookFormTextFieldRules = (props?: GetReactHookFormTextFieldRulesProps) => {
  const { fieldName, minLength = 3, maxLength = 64 } = props || {};

  return {
    validate: {
      noInvalidPatterns: (value: string) => {
        if (!value) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} cannot be blank.`;
        }

        const thisValue = value.trim();
        if (size(thisValue) < minLength || size(thisValue) > maxLength) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} needs to be between ${minLength} and ${maxLength} characters.`;
        }

        if (
          // Spaces, underscores and dashes are allowed. No other special characters
          !/^(?:[a-zA-Z0-9 _-]*)?$/.test(thisValue) ||
          // Should not have only special characters
          /^(?:[_ -]*)?$/.test(thisValue) ||
          // Should not start with special characters
          /^(?:[_-]*)?$/.test(thisValue.substring(0, 1))
        ) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} contains invalid characters. It needs to start with alphanumerics and can only contain alphanumerics, spaces, underscores and dashes.`;
        }

        return true;
      }
    }
  };
};
