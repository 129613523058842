import React, { useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";

import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import { AskAIOutputWrapContainer } from "./AskAIOutputWrapContainer";
import { RecordSelector } from "src/pages/Projects/common/RecordSelector";
import PinnedDatasets from "../AskAIInputDatasets/PinnedDatasets";
import AutoSelectDataset from "../AskAIInputDatasets/AutoSelectDataset";
import { SampleRows } from "./SampleRows";

const useStyles = makeStyles({
  viewDataContentContainer: {
    maxHeight: "246px",
    overflowY: "auto",
    margin: "5px 12px 0px 12px",
    borderRadius: "2px",
    border: "1px solid #D1D1D1"
  },
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "6px"
  },
  span: {
    fontSize: "14px",
    color: "#515151"
  }
});
interface IProps {
  dataMap: $TSFixMe;
  buttons: $TSFixMe[];
  toggleExpanded: () => void;
  queryOutputs: { name: string }[];
  isAddedToRecipe: boolean;
  sampleRows: number | undefined;
}

const AskAIOutputDatasetContainer: React.FC<IProps> = (props) => {
  const { dataMap, buttons, toggleExpanded, queryOutputs, isAddedToRecipe, sampleRows } = props;
  const classes = useStyles();
  const [value, setValue] = useState(100);
  const handleCountChange = React.useCallback((event: $TSFixMe) => {
    event.stopPropagation();
    setValue(event.target.value);
  }, []);

  const responses = React.useMemo(() => {
    const dataKeys = dataMap ? Object.keys(dataMap) : [];
    return dataKeys.map((outputName) => {
      const output = dataMap[outputName];
      const slicedRows = output?.rows?.slice(0, value) || [];
      return output
        ? { ...output, rows: slicedRows }
        : {
            columns: [],
            rows: []
          };
    });
  }, [dataMap, value]);

  return (
    <>
      {responses?.map((response, index) => {
        return (
          <AskAIOutputWrapContainer
            key={index}
            name={queryOutputs?.[0]?.name}
            buttons={[
              {
                component: <PinnedDatasets name={queryOutputs?.[0]?.name} />
              },
              {
                component: <AutoSelectDataset name={queryOutputs?.[0]?.name} />
              },
              ...buttons
            ]}
            background={isAddedToRecipe ? "#E9FFDF" : "#E7E7FF"}
            onClick={() => toggleExpanded()}>
            <Grid id="viewDataContainer" className={classes.viewDataContentContainer}>
              <ViewDataData
                isLoadingData={false}
                responseData={response}
                entityFeatures={[]}
                maxHeight="236px"
              />
            </Grid>
            <Box
              p="4px 12px 12px 12px"
              style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <RecordSelector value={value} handleCountChange={handleCountChange} />
              <span className={classes.span}>{`Size: ${response.numRows ?? 0} Rows * ${
                response.numCols ?? 0
              } Columns`}</span>
            </Box>
            <SampleRows sampleRows={sampleRows} />
          </AskAIOutputWrapContainer>
        );
      })}
    </>
  );
};

export default React.memo(AskAIOutputDatasetContainer);
