import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { makeStyles, Checkbox } from "@material-ui/core";

import AccordianCard from "./AccordianCard";
import { projectMetadata } from "../utils/CopyDownloadProject.constants";
import { AssociateDto } from "openapi/Models";

interface IProps {
  data: AssociateDto[];
  onChange: (key: string, value: string[]) => void;
}

const useStyles = makeStyles({
  flexBetween: { display: "flex", gap: "6px", alignItems: "center" }
});

interface IJobs {
  id: string;
  name: string;
  checked: boolean;
}

const CopyJobs: React.FC<IProps> = (props) => {
  const { data, onChange } = props;
  const [jobs, setJobs] = useState<IJobs[]>([]);
  const { flexBetween } = useStyles();

  useEffect(() => {
    if (data) {
      setJobs(
        _.map(data, (job) => ({
          id: job.id ?? "",
          name: (job.displayName || job.name) ?? "",
          checked: false
        }))
      );
    }
  }, [data]);

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newJobs: IJobs[] = [];
    const selectedJobs: string[] = [];

    _.forEach(jobs, (job) => {
      if (job.id === id) {
        newJobs.push({ ...job, checked: e.target.checked });
        if (e.target.checked) {
          selectedJobs.push(id);
        }
      } else {
        newJobs.push(job);
        if (job.checked) {
          selectedJobs.push(job.id);
        }
      }
    });

    setJobs(newJobs);
    onChange(projectMetadata.jobs.id, selectedJobs);
  };

  const checkedAll = useMemo(() => !_.isEmpty(jobs) && !_.some(jobs, { checked: false }), [jobs]);

  const handleCheckAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newJobs: IJobs[] = [];
    const selectedJobs: string[] = [];

    _.forEach(jobs, (job) => {
      newJobs.push({ ...job, checked: e.target.checked });
      if (e.target.checked) {
        selectedJobs.push(job.id);
      }
    });

    setJobs(newJobs);
    onChange(projectMetadata.jobs.id, selectedJobs);
  };

  if (_.isEmpty(jobs)) {
    return null;
  }

  return (
    <AccordianCard
      label={projectMetadata.jobs.label}
      title={
        <div className={flexBetween}>
          <Checkbox
            checked={checkedAll}
            disabled={_.isEmpty(jobs)}
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={handleCheckAllChange}
            onClick={(e) => e.stopPropagation()}
          />
          <span>{projectMetadata.jobs.label}</span>
        </div>
      }>
      <div>
        {_.map(jobs, (job) => (
          <div className={flexBetween} key={job.id}>
            <Checkbox
              checked={job.checked}
              onChange={(e) => handleCheckChange(e, job.id)}
              inputProps={{ "aria-label": "jobs checkbox" }}
            />
            <span>{job.name}</span>
          </div>
        ))}
      </div>
    </AccordianCard>
  );
};

export default CopyJobs;
