import React, { useEffect, useMemo, useState } from "react";

// Packages
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { chain, find } from "lodash";

// Hooks
import { useGetDataSources, useGetProjectScenarios } from "src/hooks/api";
import { getEnsuredDataConnectorsData, useGetJobs } from "src/hooks/api";

// Stores
import { useDataSourcesStore, useProjectsStore, useScenariosStore } from "src/store/store";
import { dataSourcesSetter, connectorsSetter, projectsGetter } from "src/store/store.selectors";

// Contexts
import { DagContext } from "./DagContext";
import { WebPaths } from "src/routing/routes";

type Props = {
  children: React.ReactNode;
};

const DagContextProvider = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { projectId } = useParams<$TSFixMe>();

  const isFromDatasetPage = useMemo(
    () => location?.state?.pathname?.includes("/dataset"),
    [location?.state?.pathname]
  );

  //TODO: This is workaround. To fix project routes
  const scenarioId = useMemo(
    () => chain(location.pathname).split("scenario/").last().split("/").head().value(),
    [location.pathname]
  );

  const queryClient = useQueryClient();

  // Stores - STARTS >>
  const setDataSourcesStore = useDataSourcesStore(dataSourcesSetter);
  const setConnectorsStore = useDataSourcesStore(connectorsSetter);
  const projectsStore = useProjectsStore(projectsGetter);

  const [setScenarios] = useScenariosStore((state: $TSFixMe) => [state.setScenarios]);
  // << ENDS - Stores

  // States - STARTS >>
  const [isFromArtifactsModelModal, setIsFromArtifactsModelModal] = useState(false);
  const [showNavigateToAskAI, setShouldNavigateToAskAI] = useState(false);
  // << ENDS - States

  useEffect(() => {
    const _ = async () => {
      const dataConnectorsData: $TSFixMe = await getEnsuredDataConnectorsData({ queryClient });

      if ((dataConnectorsData || [])?.length > 0) {
        setConnectorsStore([...dataConnectorsData]);
      }
    };

    _();
  }, []);

  useEffect(() => {
    setShouldNavigateToAskAI(Boolean(searchParams.get("openAskAI")));
  }, []);

  const project = useMemo(
    () => find(projectsStore, (eachProject) => eachProject?.id === projectId),
    [projectsStore, projectId]
  );

  // Query hooks - STARTS >>
  useGetDataSources({
    cacheTime: Infinity,
    refetchOnMount: true,
    onSuccess: (dataSourcesData) => setDataSourcesStore(dataSourcesData)
  });

  const {
    isLoading: isFetchingProjectScenarios,
    isSuccess: isProjectScenariosFetched,
    data: projectScenariosData
  } = useGetProjectScenarios({
    projectId,
    isApiWithRethrow: true,
    cacheTime: Infinity,
    refetchOnMount: true,
    onError: () => {}
  });

  const { data: jobsData } = useGetJobs({ projectId });
  // << ENDS - Query hooks

  useEffect(() => {
    if (!!isProjectScenariosFetched) {
      let defaultScenario: $TSFixMe = {};
      if ((projectScenariosData || [])?.length > 0) {
        setScenarios(projectScenariosData);

        defaultScenario = find(
          projectScenariosData,
          (eachScenario: $TSFixMe) => eachScenario?.default
        );
      }
      if (
        !scenarioId &&
        defaultScenario?.id &&
        (showNavigateToAskAI || Boolean(searchParams.get("openAskAI")))
      ) {
        const selectedEntity = searchParams.get("entity");

        navigate(
          `/projects/${projectId}/scenario/${defaultScenario.id}/add-code-recipe${
            selectedEntity ? `?entity=${selectedEntity}` : ""
          }`,
          {
            replace: true
          }
        );
      } else if (!scenarioId && defaultScenario?.id && projectId) {
        navigate(
          generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
            projectId,
            scenarioId: defaultScenario.id
          }),
          {
            replace: true,
            state: location.state
          }
        );
      }
    }
  }, [isProjectScenariosFetched, projectScenariosData, scenarioId, projectId]);

  // Resetting store-data
  useEffect(() => () => setConnectorsStore([]), []);

  const value = useMemo(
    () => ({
      isFromDatasetPage,
      isFromArtifactsModelModal,
      setIsFromArtifactsModelModal,
      scenarioId,
      project,
      isFetchingProjectScenarios,
      jobsData
    }),
    [
      isFromDatasetPage,
      isFromArtifactsModelModal,
      setIsFromArtifactsModelModal,
      scenarioId,
      project,
      isFetchingProjectScenarios,
      jobsData
    ]
  );

  return <DagContext.Provider value={value}>{children}</DagContext.Provider>;
};

export default DagContextProvider;
