import Dashboard from "./Dashboard/Dashboard";
import Projects from "./Projects/Projects";
import ArtifactsAndModels from "./Library/ArtifactsAndModels/ArtifactsAndModels";
import UserDetails from "./User/UserDetails";
import TenantManagement from "./TenantManagement/TenantManagement";
import StandardRecipeDataContainer from "./Projects/AddStandardRecipe/StandardRecipeDataContainer";
import CodeRecipeDataContainer from "./Projects/AddCodeRecipe/CodeRecipeDataContainer";
import AutoMLRecipeDataContainer from "./Projects/AddAutoMLRecipe/AutoMLRecipeDataContainer";
import ViewDataRoutes from "./ViewData/ViewDataRoutes";
import Charts from "./Projects/Charts/Charts";
import UsageAndPolicy from "./UsageAndPolicy/UsageAndPolicy";
import VariablesList from "./Variables/VariablesList";
import Environments from "./private/Environments/Environments";
import RecipeRunLogs from "./Projects/RecipeRunLogs/RecipeRunLogs";
import Environment from "./private/Environments/components/Environment";
import DataSources from "./DataSources/DataSources";
import DataSource from "./DataSources/DataSource/DataSource";
import ProjectSettings from "./Projects/ProjectSettings/ProjectSettings";
import ArtifactDetails from "./Library/ArtifactsAndModels/Artifacts/ArtifactDetails";
import ModelDetails from "./Library/ArtifactsAndModels/Models/ModelDetails";
import { DataAppDeepLink } from "./DataApps";

// ProjectsModule imports
import { JobRoutes, jobsHelpers } from "./private/ProjectsModule/pages";

export {
  Dashboard,
  Projects,
  UserDetails,
  TenantManagement,
  StandardRecipeDataContainer,
  CodeRecipeDataContainer,
  AutoMLRecipeDataContainer,
  ViewDataRoutes,
  Charts,
  UsageAndPolicy,
  VariablesList,
  Environments,
  RecipeRunLogs,
  Environment,
  DataSources,
  DataSource,
  ProjectSettings,
  ArtifactsAndModels,
  ArtifactDetails,
  ModelDetails,
  DataAppDeepLink,

  // ProjectsModule exports
  JobRoutes,
  jobsHelpers
};
