import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { getProjectCanvas } from "src/api";
import {
  useGetDataSources,
  useGetScenarios,
  useGetVariables,
  useGetJobs,
  useGetJobDestinations,
  useGetJob,
  useGetProjectCanvas
} from "src/hooks/api";
import { useProjectsStore } from "src/store/store";
import { projectsGetter } from "src/store/store.selectors";
import { JobContext } from "./JobContext";
import { dataSourcesTypes } from "src/pages/DataSources/utils/DataSources.constants";
import { JobsHelperText } from "../../../utils/Jobs.constants";

const JobContextProvider = (props: $TSFixMe) => {
  const { children } = props || {};

  const { projectId, jobId }: $TSFixMe = useParams() || {};

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  const reloadTrigger = useProjectsStore((state) => state.reloadTrigger);
  // << ENDS - Stores

  // States - STARTS >>
  const [dataSourcesData, setDataSourcesData] = useState<$TSFixMe>([]);

  const [jobContextState, setJobContextState] = useState<$TSFixMe>({
    jobName: ""
  });

  const [jobRunConfigContextState, setJobRunConfigContextState] = useState<$TSFixMe>({
    isValid: false,
    values: {}
  });

  const [jobParametersContextState, setJobParametersContextState] = useState<$TSFixMe>([]);
  const [jobDestinationsContextState, setJobDestinationsContextState] = useState<$TSFixMe>([]);

  const [defaultJobNames, setDefaultJobNames] = useState<$TSFixMe>([]);

  const [datasetsData, setDatasetsData] = useState<$TSFixMe>([]);
  const [projectCanvasData, setProjectCanvasData] = useState<$TSFixMe>({});

  const [defaultScenario, setDefaultScenario] = useState<$TSFixMe>({});

  const [isSaved, setIsSaved] = useState<$TSFixMe>(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Queries
  const { isFetched: isDataSourcesFetched, data: dataSourcesQueryData } = useGetDataSources();
  const { data: variablesData, refetch: refetchVariables } = useGetVariables({ projectId });

  useEffect(() => {
    !!reloadTrigger && refetchVariables();
  }, [reloadTrigger]);

  const onGetJobsSucceed = (data: $TSFixMe) => {
    let defaultNames: $TSFixMe = [];
    if ((Array.isArray(data) ? data : [])?.length > 0) {
      defaultNames = data?.reduce((acc: $TSFixMe, item: $TSFixMe) => {
        if (item?.dto?.name?.includes(JobsHelperText?.UntitledJob)) {
          acc.push(item?.dto?.name);
        }

        return acc;
      }, []);
    }

    setDefaultJobNames(defaultNames);
  };

  const { isFetching: isFetchingDefaultJobNames, refetch: refetchJobNames } = useGetJobs({
    projectId,
    onSuccess: onGetJobsSucceed
  });

  const {
    isLoading: isFetchingJob,
    isSuccess: isJobFetched,
    data: jobData,
    refetch: refetchJob
  } = useGetJob({ projectId, jobId, isInitialData: true });

  const currentJobId = useMemo(() => {
    return jobId || jobData?.id;
  }, [jobId, jobData?.id]);

  const currentScenarioId = useMemo(
    () => jobData?.scenarioId || defaultScenario?.id,
    [jobData?.scenarioId, defaultScenario?.id]
  );

  const { data: scenariosData, refetch: refetchScenarios } = useGetScenarios({
    projectId,
    ...(!!currentJobId ? { jobId: currentJobId } : {})
  });

  useEffect(() => {
    if ((scenariosData || [])?.length > 0) {
      const thisDefaultScenario = scenariosData?.find((scenario: $TSFixMe) => scenario?.default);

      Object.keys(thisDefaultScenario || {})?.length > 0 &&
        setDefaultScenario(() => thisDefaultScenario);
    }
  }, [scenariosData]);

  const { data: jobCanvasData, refetch: refetchDatasets } = useGetProjectCanvas({
    projectId,
    scenarioId: jobRunConfigContextState?.values?.scenario,
    jobProps: { jobId: currentJobId }
  });

  useEffect(() => {
    if ((jobCanvasData?.nodes || [])?.length > 0) {
      setDatasetsData(() => [
        ...(jobCanvasData?.nodes
          ?.filter((node: $TSFixMe) => node?.type === "ENTITY")
          ?.map((eachDataset: $TSFixMe) => ({
            id: eachDataset?.id,
            name: eachDataset?.name
          })) || [])
      ]);
    }
  }, [jobCanvasData]);

  const { data: destinationsData, refetch: refetchDestinations } = useGetJobDestinations({
    jobId: currentJobId
  });
  // << ENDS - Query hooks

  const project = useMemo(
    () =>
      (projectsStore || [])?.find((eachProject: $TSFixMe) => eachProject?.id === projectId) || {},
    [projectId, projectsStore]
  );

  useEffect(() => {
    if (isDataSourcesFetched) {
      if (dataSourcesQueryData?.length > 0) {
        const dataSourcesTypesImages: $TSFixMe = {};
        dataSourcesTypes?.forEach((eachDataSourceType: $TSFixMe) => {
          dataSourcesTypesImages[eachDataSourceType?.name] = eachDataSourceType?.image;
        });
        const supportedConnectors = _.map(dataSourcesTypes, "name");
        const inHouseConnectors = _.filter(dataSourcesQueryData, ({ dataSourceType }) =>
          _.includes(supportedConnectors, dataSourceType)
        );

        setDataSourcesData(() => [
          ...(inHouseConnectors?.map((eachDataSource: $TSFixMe) => {
            eachDataSource.image = dataSourcesTypesImages[eachDataSource?.dataSourceType];
            return eachDataSource;
          }) || [])
        ]);
      }
    }
  }, [isDataSourcesFetched]);

  useEffect(() => {
    let jobParameters: $TSFixMe = [];
    if (isJobFetched && jobData?.id) {
      jobParameters =
        Object.keys(jobData?.computedVariables || {})?.map((key: $TSFixMe) => ({
          key: key,
          value: jobData?.computedVariables[key]
        })) || [];
    } else {
      jobParameters =
        (variablesData || [])?.map((eachVariable: $TSFixMe) => ({
          key: eachVariable?.name,
          value: eachVariable?.value
        })) || [];
    }

    setJobParametersContextState(() => [...jobParameters]);
  }, [isJobFetched, jobData, variablesData]);

  const getProjectCanvasData = async ({ scenarioId: thisScenarioId }: $TSFixMe = {}) =>
    await getProjectCanvas({
      projectId,
      scenarioId: thisScenarioId || jobRunConfigContextState?.values?.scenario
    });

  useEffect(() => {
    const _ = async () => {
      const projectCanvasDataResponse = await getProjectCanvasData({
        scenarioId: currentScenarioId
      });
      setProjectCanvasData(() => projectCanvasDataResponse || {});
    };

    !!currentJobId && !!currentScenarioId && _();
  }, [currentJobId, currentScenarioId]);

  const validateNodes = async () => {
    let isValid = false;

    const thisProjectCanvasData = await getProjectCanvasData();
    if (Object.keys(thisProjectCanvasData || {})?.length > 0) {
      if ((thisProjectCanvasData?.nodes || [])?.length > 0) {
        const thisNodes =
          thisProjectCanvasData.nodes?.filter(
            (eachNode: $TSFixMe) => eachNode?.type === "DFSGROUP"
          ) || [];

        isValid = thisNodes?.length > 0;
      }
    }

    return isValid;
  };

  // Job context value - STARTS >>
  const value = useMemo(
    () => ({
      project,

      dataSourcesData,

      variablesData,
      refetchVariables,

      jobContextState,
      setJobContextState,
      jobRunConfigContextState,
      setJobRunConfigContextState,
      jobParametersContextState,
      setJobParametersContextState,
      jobDestinationsContextState,
      setJobDestinationsContextState,

      isFetchingDefaultJobNames,
      refetchJobNames,
      defaultJobNames,

      isFetchingJob,
      isJobFetched,
      jobData,
      refetchJob,

      scenariosData,
      refetchScenarios,
      defaultScenario,

      datasetsData,
      getProjectCanvasData,
      projectCanvasData,
      validateNodes,

      currentJobId,
      currentScenarioId,

      destinationsData,
      refetchDestinations,

      refetchDatasets,

      isSaved,
      setIsSaved
    }),
    [
      project,

      dataSourcesData,

      variablesData,
      refetchVariables,

      jobContextState,
      setJobContextState,
      jobRunConfigContextState,
      setJobRunConfigContextState,
      jobParametersContextState,
      setJobParametersContextState,
      jobDestinationsContextState,
      setJobDestinationsContextState,

      isFetchingDefaultJobNames,
      refetchJobNames,
      defaultJobNames,

      isFetchingJob,
      isJobFetched,
      jobData,
      refetchJob,

      scenariosData,
      refetchScenarios,
      defaultScenario,

      datasetsData,
      getProjectCanvasData,
      projectCanvasData,
      validateNodes,

      currentJobId,
      currentScenarioId,

      destinationsData,
      refetchDestinations,

      refetchDatasets,

      isSaved,
      setIsSaved
    ]
  );
  // << ENDS - Job context value

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export default JobContextProvider;
