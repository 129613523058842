import React from "react";

type ContextTypes = {
  project: $TSFixMe;

  dataSourcesData: $TSFixMe[];

  variablesData: $TSFixMe[];
  refetchVariables: $TSFixMeFunction;

  jobContextState: $TSFixMe;
  setJobContextState: $TSFixMeFunction;

  jobRunConfigContextState: $TSFixMe;
  setJobRunConfigContextState: $TSFixMeFunction;

  jobParametersContextState: $TSFixMe[];
  setJobParametersContextState: $TSFixMeFunction;

  jobDestinationsContextState: $TSFixMe[];
  setJobDestinationsContextState: $TSFixMeFunction;

  isFetchingDefaultJobNames: boolean;
  refetchJobNames: $TSFixMeFunction;
  defaultJobNames: string[];

  isFetchingJob: boolean;
  isJobFetched: boolean;
  jobData: $TSFixMe;
  refetchJob: $TSFixMeFunction;

  scenariosData: $TSFixMe[];
  refetchScenarios: $TSFixMeFunction;
  defaultScenario: $TSFixMe;

  datasetsData: $TSFixMe[];
  getProjectCanvasData: $TSFixMeFunction;
  projectCanvasData: $TSFixMe;
  validateNodes: $TSFixMeFunction;

  currentJobId: string;
  currentScenarioId: string;

  destinationsData: $TSFixMe[];
  refetchDestinations: $TSFixMeFunction;

  refetchDatasets: $TSFixMeFunction;

  isSaved: boolean;
  setIsSaved: $TSFixMeFunction;
};

// @ts-ignore
export const JobContext = React.createContext<ContextTypes>();
