// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";

// Utils
import { WebPaths } from "src/routing/routes";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";

// Components
import { useRunProject } from "src/hooks/api";

const useActions = () => {
  const navigate = useNavigate();
  const { projectId } = useParams() || {};

  const { mutateAsync: runMutation, reset: resetRunMutation } = useRunProject();

  const onAdd = () => {
    if (!projectId) {
      return;
    }

    navigate(generatePath(WebPaths.NewScenario, { projectId }));
  };

  const onEdit = (scenarioId?: string) => {
    if (!projectId || !scenarioId) {
      return;
    }

    navigate(generatePath(WebPaths.Scenario, { projectId, scenarioId }));
  };

  const onDisplayOnDag = (scenarioId?: string) => {
    if (!projectId || !scenarioId) {
      return;
    }

    navigate(generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, { projectId, scenarioId }));
  };

  const onRun = async (scenarioId?: string) => {
    if (!projectId || !scenarioId) {
      return;
    }

    !!projectId && checkEnvRelaunch(projectId);

    await resetRunMutation();
    runMutation({ projectId, scenarioId });
    onDisplayOnDag(scenarioId);
  };

  return {
    onAdd,
    onEdit,
    onDisplayOnDag,
    onRun
  };
};

export default useActions;
