import React from "react";
import _ from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import { DataappAskAIConfigLlmTypeEnum } from "openapi/Models";
import { dataAppConfigFields } from "./CreateDataAppForm";

const useStyles = makeStyles({
  link: {
    color: ({ disabled }: { disabled?: boolean }) => (disabled ? "#00000061" : "#003656"),

    "&:hover": {
      textDecoration: "none"
    }
  }
});

interface IProps {
  readOnly?: boolean;
  value: DataappAskAIConfigLlmTypeEnum;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectAskAILLMModel: React.FC<IProps> = (props) => {
  const { link } = useStyles({ disabled: props.readOnly });

  return (
    <FormControl component="fieldset" disabled={props.readOnly}>
      <FormLabel component="legend">Models</FormLabel>
      <RadioGroup
        row={props.readOnly}
        aria-label={dataAppConfigFields.llmModel}
        name={dataAppConfigFields.llmModel}
        value={props.value}
        onChange={props.onChange}>
        <FormControlLabel
          value={DataappAskAIConfigLlmTypeEnum.Turbo}
          control={<Radio />}
          label={
            <>
              <a
                className={link}
                href="https://platform.openai.com/docs/models/gpt-4-turbo-and-gpt-4"
                target="_blank"
                rel="noreferrer">
                OpenAI GPT-4 Turbo
              </a>{" "}
              <Tooltip title="This is recommended for better accuracy of responses">
                <InfoOutlinedIcon
                  style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                />
              </Tooltip>
            </>
          }
        />
        <FormControlLabel
          value={DataappAskAIConfigLlmTypeEnum.O}
          control={<Radio />}
          label={
            <a
              className={link}
              href="https://platform.openai.com/docs/models/gpt-4o"
              rel="noreferrer"
              target="_blank">
              OpenAI GPT-4o
            </a>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default SelectAskAILLMModel;
