import React, { useState } from "react";

// Packages
import { v4 as uuidv4 } from "uuid";

// MUI
import {
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  CircularProgress,
  Tooltip,
  useTheme,
  makeStyles,
  IconButton
} from "@material-ui/core";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { ArrowDownIcon } from "src/icons/NewUX/ArrowDownIcon";

// Components
import { SubTopNavBarVariants } from "./SubTopNavBar";

type Props = {
  variant: string;
  moreOptions?: $TSFixMe[];
  onMoreOptionsClose?: $TSFixMeFunction;
  onDrawerClose?: $TSFixMeFunction;
  children?: React.JSX.Element;
  moreActionWidth?: string;
};

type StyleProps = {
  variant: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    marginLeft: "auto",
    columnGap: 10,
    alignItems: "center",
    paddingRight: (props: StyleProps) =>
      props?.variant === SubTopNavBarVariants.Drawer ? theme.spacing(2) : theme.spacing(3)
  },
  moreAction: {
    minHeight: 28,
    padding: 15
  },
  menuItemIcon: {
    minWidth: "auto",
    marginRight: 8
  },
  loader: { color: "#515151" }
}));

export const SubTopNavBarRightSection = (props: Props) => {
  const { variant, moreOptions, onDrawerClose, children, moreActionWidth } = props || {};

  const theme = useTheme();
  const classes = useStyles({ variant });

  // States - STARTS >>
  const [nestedComponent, setNestedComponent] = useState<React.JSX.Element | null>(null);
  // ENDS - States

  // Drop-down code - STARTS >>
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    setNestedComponent(null);
  };
  // ENDS - Drop-down

  return (
    <Grid container wrap="nowrap" alignItems="center" className={classes.root}>
      {children}

      {!!moreOptions && (
        <>
          {variant === SubTopNavBarVariants.Drawer ? (
            <IconButton color="primary" onClick={onOpen} size="small">
              <MoreVertRoundedIcon opacity={0.5} />
            </IconButton>
          ) : (
            <Button color="primary" onClick={onOpen} className={classes.moreAction}>
              <ArrowDownIcon color={theme.palette.text.primary} opacity={0.3} />
            </Button>
          )}

          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            onClose={onClose}
            PaperProps={{
              style: {
                marginTop: 6,
                width: moreActionWidth ?? 225,
                borderRadius: 12
              }
            }}>
            {Boolean(nestedComponent) && (
              <MenuItem key={uuidv4()} onClick={() => setNestedComponent(null)}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <ChevronLeftIcon />
                </ListItemIcon>
              </MenuItem>
            )}
            {Boolean(nestedComponent) && nestedComponent}

            {!Boolean(nestedComponent) &&
              moreOptions?.map((moreOption: $TSFixMe) => (
                <Tooltip key={uuidv4()} title={!!moreOption?.tooltip ? moreOption?.tooltip : ""}>
                  <span>
                    <MenuItem
                      onClick={() => {
                        if (!!moreOption?.nestedComponent) {
                          setNestedComponent(moreOption?.nestedComponent);
                        } else {
                          moreOption?.action();
                          !!moreOption?.isCloseMoreAction && onClose();
                        }
                      }}
                      disabled={!!moreOption?.isDisabled || !!moreOption?.isLoading}>
                      <ListItemIcon className={classes.menuItemIcon}>
                        {moreOption?.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={moreOption?.label}
                        primaryTypographyProps={{
                          variant: "body2"
                        }}
                      />
                      {!!moreOption?.isLoading && (
                        <ListItemSecondaryAction>
                          <CircularProgress size={18} color="inherit" className={classes.loader} />
                        </ListItemSecondaryAction>
                      )}
                      {!!moreOption?.nestedComponent && (
                        <ListItemSecondaryAction>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemSecondaryAction>
                      )}
                    </MenuItem>
                  </span>
                </Tooltip>
              ))}
          </Menu>
        </>
      )}

      {variant === SubTopNavBarVariants.Drawer && (
        <IconButton color="primary" onClick={onDrawerClose} size="small">
          <CloseRoundedIcon opacity={0.5} />
        </IconButton>
      )}
    </Grid>
  );
};

export default SubTopNavBarRightSection;
