import React, { useMemo, useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import Chart from "./Chart";
import { AIGuideOutputWrap } from "./AIGuideOutputWrap";
import { ChartDto, ExtractedChartDto } from "./GroupedCharts";

const useStyles = makeStyles({
  chartsWrap: {
    overflow: "auto",
    visibility: "hidden",
    opacity: 0,
    position: "absolute",
    zIndex: 1
  },
  selectedChart: {
    display: "block",
    visibility: "visible",
    opacity: 1,
    position: "relative",
    zIndex: 2
  },
  icon: {
    height: "32px",
    width: "24px !important"
  },
  chartWrap: {
    margin: "5px 12px 5px 12px",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

interface IProps {
  chartList: (ChartDto | ExtractedChartDto)[];
}

const Charts: React.FC<IProps> = ({ chartList }) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);

  const selectedChart = useMemo(() => chartList?.[value], [chartList, value]);

  if (chartList?.length === 1) {
    const chart: any = chartList?.[0];
    return (
      <Box className={classes.chartWrap}>
        <Chart
          plotlyJson={chart.plotlyJson}
          name={chart.name}
          dataFilePath={chart.dataFilePath}
          chartType={chart.chartType}
        />
      </Box>
    );
  }
  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    event.stopPropagation();
  };

  return (
    <Grid onScroll={handleScroll}>
      <AIGuideOutputWrap tabNames={chartList} value={value} handleChange={setValue}>
        {chartList.map((chart: any) => (
          <Box
            height="100%"
            key={chart.name}
            className={clsx(classes.chartsWrap, {
              [classes.selectedChart]: selectedChart.name === chart.name
            })}>
            <Chart
              plotlyJson={chart.plotlyJson}
              name={chart.name}
              dataFilePath={chart.dataFilePath}
              chartType={chart.chartType}
            />
          </Box>
        ))}
      </AIGuideOutputWrap>
    </Grid>
  );
};

export default React.memo(Charts);
