/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { EnumDescriptor } from './enum-descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { FieldDescriptor } from './field-descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { FileDescriptorProto } from './file-descriptor-proto';
// May contain unused imports in some cases
// @ts-ignore
import { FileOptions } from './file-options';
// May contain unused imports in some cases
// @ts-ignore
import { ServiceDescriptor } from './service-descriptor';

/**
 * 
 * @export
 * @interface FileDescriptor
 */
export interface FileDescriptor {
    /**
     * 
     * @type {FileDescriptorProto}
     * @memberof FileDescriptor
     */
    'proto'?: FileDescriptorProto;
    /**
     * 
     * @type {Array<Descriptor>}
     * @memberof FileDescriptor
     */
    'messageTypes'?: Array<Descriptor>;
    /**
     * 
     * @type {Array<EnumDescriptor>}
     * @memberof FileDescriptor
     */
    'enumTypes'?: Array<EnumDescriptor>;
    /**
     * 
     * @type {Array<ServiceDescriptor>}
     * @memberof FileDescriptor
     */
    'services'?: Array<ServiceDescriptor>;
    /**
     * 
     * @type {Array<FieldDescriptor>}
     * @memberof FileDescriptor
     */
    'extensions'?: Array<FieldDescriptor>;
    /**
     * 
     * @type {Array<FileDescriptor>}
     * @memberof FileDescriptor
     */
    'dependencies'?: Array<FileDescriptor>;
    /**
     * 
     * @type {Array<FileDescriptor>}
     * @memberof FileDescriptor
     */
    'publicDependencies'?: Array<FileDescriptor>;
    /**
     * 
     * @type {string}
     * @memberof FileDescriptor
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDescriptor
     */
    'package'?: string;
    /**
     * 
     * @type {FileDescriptor}
     * @memberof FileDescriptor
     */
    'file'?: FileDescriptor;
    /**
     * 
     * @type {string}
     * @memberof FileDescriptor
     */
    'fullName'?: string;
    /**
     * 
     * @type {FileOptions}
     * @memberof FileDescriptor
     */
    'options'?: FileOptions;
    /**
     * 
     * @type {string}
     * @memberof FileDescriptor
     */
    'syntax'?: FileDescriptorSyntaxEnum;
}

export const FileDescriptorSyntaxEnum = {
    Unknown: 'UNKNOWN',
    Proto2: 'PROTO2',
    Proto3: 'PROTO3'
} as const;

export type FileDescriptorSyntaxEnum = typeof FileDescriptorSyntaxEnum[keyof typeof FileDescriptorSyntaxEnum];


