import React from "react";
import { Checkbox, FormControlLabel, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { areEqual, FixedSizeList as List } from "react-window";

import OverflowTooltip from "src/components/OverflowTooltip";
import { DragHandle } from "src/icons/DragHandle";
import { IColumn } from "../TableSettingsMenu";

const useStyles = makeStyles({
  list: {
    maxHeight: "50vh",
    maxWidth: "300px",
    overflow: "hidden"
  },
  listItem: {
    padding: "0 14px",
    borderTop: "1px solid #e1e1e1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& label[class*='MuiFormControlLabel-root']": {
      marginBottom: "0",
      width: "100%",
      overflow: "hidden",
      "& > span": {
        overflow: "hidden",
        whiteSpace: "nowrap"
      }
    }
  }
});

interface IProps {
  isColumnLimitReached: boolean;
  filteredColumnList: IColumn[];
  onDragEnd: (result: DropResult) => void;
  onChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
}

const Item = ({ provided, item: column, style, isColumnLimitReached, onChange }: any) => {
  const classes = useStyles();
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...style,
        ...provided.draggableProps.style
      }}
      className={classes.listItem}>
      <FormControlLabel
        value={column.name}
        control={
          <Tooltip
            title={
              !column.checked && isColumnLimitReached
                ? "Selecting more columns than the maximum supported limit(50) will automatically deselect the previously chosen columns."
                : ""
            }>
            <Checkbox size={"small"} checked={column.checked} color="primary" />
          </Tooltip>
        }
        label={
          <OverflowTooltip
            value={<Typography variant="body2">{column.name}</Typography>}
            title={column.name}
            tooltipProps={{ placement: "left-start" }}
          />
        }
        labelPlacement="end"
        onChange={onChange}
      />
      <div {...provided.dragHandleProps}>
        <DragHandle />
      </div>
    </div>
  );
};

const Row = React.memo(function Row(props: any) {
  const { data, index, style } = props;
  const { items, isColumnLimitReached, onChange } = data;
  const item = items[index];
  return (
    <Draggable draggableId={item.name} index={index} key={item.name}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          isColumnLimitReached={isColumnLimitReached}
          onChange={onChange}
          index={index}
        />
      )}
    </Draggable>
  );
}, areEqual);
const ITEM_SIZE = 34;
const ColumnFilterDragDropMenu = ({
  isColumnLimitReached,
  onChange,
  filteredColumnList,
  onDragEnd
}: IProps) => {
  const classes = useStyles();
  const itemCount = filteredColumnList.length;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => (
          <Item
            provided={provided}
            isDragging={snapshot.isDragging}
            item={filteredColumnList[rubric.source.index]}
            onChange={onChange}
            isColumnLimitReached={isColumnLimitReached}
            index={rubric.source.index}
          />
        )}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={classes.list}>
            <List
              height={Math.min(300, ITEM_SIZE * itemCount + 1)}
              itemCount={itemCount}
              itemSize={ITEM_SIZE}
              outerRef={provided.innerRef}
              itemData={{ items: filteredColumnList, isColumnLimitReached, onChange }}
              width={300}>
              {Row}
            </List>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ColumnFilterDragDropMenu;
