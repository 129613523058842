import React from "react";
import { Box, Grid, Tab, Tabs, makeStyles } from "@material-ui/core";

import { OverflowTooltip } from "src/components";

const useStyles = makeStyles({
  tabs: {
    height: "44px",
    maxWidth: "fit-content",
    background: "#fff"
  },
  flexContainer: {
    height: "100%"
  },
  tab: {
    textTransform: "none",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "0px 12px"
  },
  tabWrap: {
    borderBottom: "1px solid #0003"
  },
  chartsContainerWrap: {
    width: "100%",
    overflow: "auto",
    background: "#fff",
    borderRadius: "8px"
  },
  wrapper: {
    alignItems: "flex-start"
  }
});

export const AIGuideOutputWrap = ({
  tabNames,
  children,
  handleChange,
  value
}: {
  tabNames: { name: string }[];
  children: React.ReactNode;
  handleChange: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.chartsContainerWrap}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.tabWrap}
        data-testid="ai-output-tab-wrap">
        <Tabs
          value={value}
          className={classes.tabs}
          classes={{
            flexContainer: classes.flexContainer
          }}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, newValue) => handleChange(newValue)}>
          {tabNames?.map(({ name }) => (
            <Tab
              classes={{
                wrapper: classes.wrapper
              }}
              label={
                <Box maxWidth="100%" data-testid="ai-output-tab-name">
                  <OverflowTooltip value={name} />
                </Box>
              }
              key={name}
              className={classes.tab}
            />
          ))}
        </Tabs>
      </Grid>
      {children}
    </Grid>
  );
};
