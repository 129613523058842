import React, { useState, useEffect, useMemo } from "react";
import _, { includes, isEqual, size } from "lodash";
import {
  Badge,
  Button,
  IconButton,
  makeStyles,
  Menu,
  Tooltip,
  Typography
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import { TableCog } from "src/icons/TableCog";
import ColumnFilterMenu from "./ColumnFilterMenu/ColumnFilterMenu";
import { MAX_COLUMN_AMOUNT } from "./constants";

const useStyles = makeStyles({
  settingsMenuContainer: {
    position: "absolute",
    right: "24px",
    top: "6px",
    zIndex: 10
  },
  customBadge: {
    "& span": {
      top: "2px",
      right: "3px"
    }
  },
  settingsMenuIcon: {
    color: "rgba(0, 54, 86, 0.5)",
    padding: "6px",
    background: "#FFFFFF",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#f7f7f7"
    }
  },
  headersSortingContainer: {
    borderTop: "1px solid #e1e1e1",
    padding: "8px 14px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#7c7c7c"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 14px"
  },
  infoTooltipStyles: {
    fontSize: "14px",
    cursor: "help",
    marginLeft: 4
  }
});

interface Props {
  originalColumns: string[];
  visibleColumns: string[];
  columnOrder: string[];
  setHiddenColumns: React.Dispatch<React.SetStateAction<string[]>>;
  setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>;
  onColumnChange?: (columnNames: string[]) => void;
}

export interface IColumn {
  name: string;
  checked: boolean;
}

export const TableSettingsMenu = ({
  columnOrder,
  setHiddenColumns,
  setColumnOrder,
  onColumnChange,
  originalColumns,
  visibleColumns
}: Props) => {
  const classes = useStyles();
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<any>(null);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const defaultColumns = useMemo(
    () => _.map(originalColumns, (name, index) => ({ name, checked: index < MAX_COLUMN_AMOUNT })),
    []
  );

  useEffect(() => {
    setColumns(defaultColumns);
    if (columnOrder.length > MAX_COLUMN_AMOUNT) {
      setHiddenColumns(columnOrder.slice(MAX_COLUMN_AMOUNT));
    }
  }, []);

  useEffect(() => {
    if (Boolean(settingsAnchorEl)) {
      const columnOrdercolumns = _.map(columnOrder, (name) => ({
        name,
        checked: includes(visibleColumns, name)
      }));
      if (!isEqual(columnOrdercolumns, columns)) {
        setColumns(columnOrdercolumns);
      }
    }
  }, [settingsAnchorEl]);

  const disabled = useMemo(() => _.isEqual(columns, defaultColumns), [columns, defaultColumns]);

  const { checkedCols, ascCols, descCols, ascDisabled, descDisabled, isColumnLimitReached } =
    useMemo(() => {
      const ascCols = _.orderBy(columns, "name", "asc");
      const descCols = _.orderBy(columns, "name", "desc");
      const checkedCols = _.filter(columns, { checked: true });
      const isColumnLimitReached = size(checkedCols) > MAX_COLUMN_AMOUNT - 1;

      return {
        checkedCols,
        ascCols,
        descCols,
        ascDisabled: _.isEqual(columns, ascCols),
        descDisabled: _.isEqual(columns, descCols),
        isColumnLimitReached
      };
    }, [columns]);

  const handleTableSettingsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setSettingsAnchorEl(null);
  };

  const handleHiddenColumnsChange = (e: React.ChangeEvent<{ checked: boolean; value: string }>) => {
    const checked = e?.target?.checked;
    const targetValue = e?.currentTarget.value;
    const targetIndex = _.findIndex(columns, { name: targetValue });
    if (checkedCols.length > MAX_COLUMN_AMOUNT - 1 && checked) {
      const firstCheckedIndex = _.findIndex(
        columns,
        { checked: true },
        targetIndex > MAX_COLUMN_AMOUNT - 1 ? undefined : targetIndex
      );
      setColumns(
        _.map(columns, (column, index) => {
          if (column.name === targetValue) {
            return { ...column, checked: true };
          }
          if (firstCheckedIndex === index) {
            return { ...column, checked: false };
          }

          return column;
        })
      );
    } else {
      setColumns(
        _.map(columns, (column) => {
          if (column.name === targetValue) {
            return { ...column, checked };
          } else {
            return column;
          }
        })
      );
    }
  };

  const handleDefault = () => {
    setColumns(defaultColumns);
  };

  const handleSort = (type: "asc" | "desc") => {
    setColumns(type === "asc" ? ascCols : descCols);
  };

  const handleApply = () => {
    setHiddenColumns(_.map(_.filter(columns, { checked: false }), "name"));
    setColumnOrder(_.map(columns, "name"));
    onColumnChange?.(_.map(checkedCols, "name"));
    setIsFilterApplied(!isEqual(columns, defaultColumns));
    handleCloseSettings();
  };

  return (
    <div className={classes.settingsMenuContainer}>
      <IconButton
        className={classes.settingsMenuIcon}
        aria-controls="table-settings-menu"
        aria-haspopup="true"
        data-testid="tableSettingsIcon"
        onClick={handleTableSettingsClick}
        color="primary">
        <Tooltip
          title={isFilterApplied ? `Showing ${size(checkedCols)}/${size(columns)} Columns` : ""}>
          <Badge
            variant="dot"
            overlap="rectangular"
            color="error"
            className={classes.customBadge}
            invisible={!isFilterApplied}>
            <TableCog />
          </Badge>
        </Tooltip>
      </IconButton>
      {Boolean(settingsAnchorEl) && (
        <Menu
          id="table-settings-menu"
          anchorEl={settingsAnchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          keepMounted
          open={Boolean(settingsAnchorEl)}
          onClose={handleCloseSettings}>
          <div>
            <ColumnFilterMenu
              columns={columns}
              setColumns={setColumns}
              onFormControlChange={handleHiddenColumnsChange}
              onColumnsChange={setColumns}
              isColumnLimitReached={isColumnLimitReached}
            />
          </div>
          <div className={classes.headersSortingContainer}>
            <Typography variant="body2">Headers</Typography>
            <div>
              <Button
                color="primary"
                data-testid="tableSettingsDefaultButton"
                disabled={disabled}
                onClick={handleDefault}>
                Default
              </Button>
              <Button
                color="primary"
                data-testid="tableSettingsAToZButton"
                disabled={ascDisabled}
                onClick={() => handleSort("asc")}>
                A to Z
              </Button>
              <Button
                color="primary"
                data-testid="tableSettingsZToAButton"
                disabled={descDisabled}
                onClick={() => handleSort("desc")}>
                Z to A
              </Button>
            </div>
          </div>
          <div className={classes.flex}>
            <Typography
              style={{ display: "flex", alignItems: "center" }}
              color="textSecondary"
              variant="caption">
              {`Selected ${_.size(checkedCols)} of ${columns?.length || 0}`}
              {columns?.length > 50 ? (
                <Tooltip title="Sample data view can only show a maximum of 50 columns at once">
                  <InfoOutlined className={classes.infoTooltipStyles} />
                </Tooltip>
              ) : null}
            </Typography>
            <Button
              variant="contained"
              style={{ background: "#4646b5", color: "#fff", textTransform: "capitalize" }}
              data-testid="tableSettingsApplyButton"
              size="small"
              onClick={handleApply}>
              Apply
            </Button>
          </div>
        </Menu>
      )}
    </div>
  );
};
