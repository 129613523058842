/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { Modal, Spinner } from "src/components";
import { Entities, ArtifactMini, ModelMini, EntityTypeEnum } from "src/types";
import { EntitiesList } from "./EntitiesList";
import { areEqual } from "src/utils/arrayUtils";
import { EntityIcon } from "src/icons/EntityIcon";
import { ArtifactIcon } from "src/icons/ArtifactIcon";
import { ModelsIcon } from "src/icons/ModelsIcon";
import { useGetAllowedDatasets } from "../../common/useGetAllowedDatasets";

type Props = {
  onClose: () => void;
  allEntities: Entities;
  selectedInputDatasets: Entities;
  selectedArtifacts?: Array<ArtifactMini>;
  selectedModels?: Array<ModelMini>;
  onSubmit: ({ updatedInputDatasets, updatedArtifacts, updatedModels }: $TSFixMe) => void;
  isSubmitInProgress: boolean;
  recipeId: string | undefined;
  showArtifacts?: boolean;
  showModels?: boolean;
  noEntitiesText?: string;
};

const useStyles = makeStyles({
  infoItem: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    lineHeight: 1.54
  },
  infoIcon: {
    color: "rgba(0,0,0,0.6)",
    paddingRight: "4px"
  },
  allEntitiesContainer: {
    flexWrap: "nowrap",
    gap: "32px",
    paddingTop: "8px"
  }
});
export const AddEntitiesDialog = ({
  onClose,
  selectedInputDatasets,
  allEntities,
  selectedArtifacts = [],
  selectedModels = [],
  onSubmit,
  isSubmitInProgress,
  showArtifacts = false,
  showModels = false,
  recipeId,
  noEntitiesText = "If you cannot see datasets, artifacts or models here, please add it to the canvas first"
}: Props) => {
  const classes = useStyles();
  const [inputDatasetsToUpdate, setInputDatasetsToUpdate] = React.useState(selectedInputDatasets);
  const [artifactsToUpdate, setArtifactsToUpdate] = React.useState(selectedArtifacts);
  const [modelsToUpdate, setModelsToUpdate] = React.useState(selectedModels);

  const handleSubmit = () => {
    onSubmit({
      updatedInputDatasets: inputDatasetsToUpdate,
      updatedArtifacts: artifactsToUpdate,
      updatedModels: modelsToUpdate
    });
    onClose();
  };

  const { allowedInputEntities, isFetching } = useGetAllowedDatasets({ allEntities, recipeId });
  const allowedInputDatasets = useMemo(
    () =>
      allowedInputEntities.filter(
        (dataset: $TSFixMe) =>
          dataset.entityMeta?.entityViewType?.toLowerCase() !==
            EntityTypeEnum.CHART.toLowerCase() &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !==
            EntityTypeEnum.ARTIFACT.toLowerCase() &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== EntityTypeEnum.MODEL.toLowerCase()
      ),
    [allowedInputEntities]
  );

  const allowedArtifacts = useMemo(
    () =>
      allowedInputEntities.filter(
        (dataset: $TSFixMe) =>
          dataset.entityMeta?.entityViewType?.toLowerCase() ===
          EntityTypeEnum.ARTIFACT.toLowerCase()
      ),
    [allowedInputEntities]
  );

  const allowedModels = useMemo(
    () =>
      allowedInputEntities.filter(
        (dataset: $TSFixMe) =>
          dataset.entityMeta?.entityViewType?.toLowerCase() === EntityTypeEnum.MODEL.toLowerCase()
      ),
    [allowedInputEntities]
  );

  const isSubmitDisabled = useMemo(
    () =>
      areEqual(selectedInputDatasets, inputDatasetsToUpdate) &&
      areEqual(selectedArtifacts, artifactsToUpdate) &&
      areEqual(selectedModels, modelsToUpdate),
    [
      artifactsToUpdate,
      inputDatasetsToUpdate,
      modelsToUpdate,
      selectedArtifacts,
      selectedInputDatasets,
      selectedModels
    ]
  );

  return (
    <Modal
      open={true}
      title="Add"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSubmit}
      submitDisabled={
        isSubmitDisabled ||
        (inputDatasetsToUpdate?.length === 0 &&
          modelsToUpdate?.length === 0 &&
          artifactsToUpdate?.length === 0)
      }
      cancelButtonLabel="Cancel"
      submitButtonLabel="Add Selected"
      submitButtonId="standardRecipeAddSelectedEntitiesBtn"
      isSubmitLoading={isSubmitInProgress}
      align="top"
      isNewTheme>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <Grid container direction="column">
            <Grid container direction="row" alignItems="center">
              <InfoIcon fontSize="small" className={classes.infoIcon} />
              <Typography className={classes.infoItem} test-id="standardRecipeNoEntitiesText">
                {noEntitiesText}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.allEntitiesContainer}
            test-id="standardRecipeAddDatasetsContainer">
            <Grid item xs>
              <EntitiesList
                title="Datasets"
                icon={<EntityIcon />}
                allItems={allowedInputDatasets}
                selectedItems={inputDatasetsToUpdate}
                onSelectedItemsChange={setInputDatasetsToUpdate}
                searchPlaceholder="Search Datasets by name"
              />
            </Grid>
            {showArtifacts && allowedArtifacts?.length > 0 && (
              <Grid item xs test-id="standardRecipeAddArtifactsContainer">
                <EntitiesList
                  title="Artifacts"
                  icon={<ArtifactIcon />}
                  allItems={allowedArtifacts}
                  selectedItems={artifactsToUpdate}
                  onSelectedItemsChange={setArtifactsToUpdate}
                  searchPlaceholder="Search Artifacts by name"
                />
              </Grid>
            )}
            {showModels && allowedModels?.length > 0 && (
              <Grid item xs test-id="standardRecipeAddModelsContainer">
                <EntitiesList
                  title="Models"
                  icon={<ModelsIcon />}
                  allItems={allowedModels}
                  selectedItems={modelsToUpdate}
                  onSelectedItemsChange={setModelsToUpdate}
                  searchPlaceholder="Search Models by name"
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Modal>
  );
};

export default React.memo(AddEntitiesDialog);
