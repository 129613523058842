import React, { useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import { Grid, makeStyles } from "@material-ui/core";

import AskAIInputDataset from "./AskAIInputDataset";
import { Entities, EntityFeaturesResponse } from "src/types";

const useStyles = makeStyles({
  inputDatasetsContainer: {
    flexWrap: "nowrap",
    gap: "16px",
    width: "95%",
    paddingBottom: "8px"
  },
  hoveredInputDataset: {
    position: "relative"
  }
});

const AskAIInputDatasets = ({
  inputDatasets,
  entityFeaturesMap,
  isAutoGenerateInProgress,
  hoverInputs
}: {
  inputDatasets: Entities;
  hoverInputs: string[];
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  isAutoGenerateInProgress: boolean;
}) => {
  const [expandedDatasetIndices, setExpandedDatasetIndices] = useState<number[]>([]);
  const classes = useStyles();

  React.useEffect(() => {
    if (isAutoGenerateInProgress) {
      setExpandedDatasetIndices([]);
    }
  }, [isAutoGenerateInProgress]);

  const handleExpandToggle = (expInd: number) => {
    setExpandedDatasetIndices((old: number[]) => {
      let newExpandedIndices = [...old];

      if (_.includes(newExpandedIndices, expInd)) {
        newExpandedIndices = _.without(newExpandedIndices, expInd);
      } else if (newExpandedIndices.length < 2) {
        newExpandedIndices = _.concat(newExpandedIndices, expInd);
      } else {
        newExpandedIndices = [newExpandedIndices[1], expInd];
      }

      return newExpandedIndices;
    });
  };

  return (
    <>
      {inputDatasets?.map((dataset, index) => {
        const isHoveredResponseInput = hoverInputs?.includes(dataset.displayName || dataset.name);
        const entityFeatures = entityFeaturesMap?.[dataset.name];
        const isExpanded = expandedDatasetIndices.includes(index);

        return (
          <Grid
            container
            key={dataset.id}
            className={clsx(`inputDataset-${dataset.displayName || dataset.name}`, {
              [classes.hoveredInputDataset]: isHoveredResponseInput
            })}>
            <AskAIInputDataset
              entityFeatures={entityFeatures}
              key={dataset.id}
              isExpanded={isExpanded}
              inputDataset={dataset}
              onExpandToggle={() => handleExpandToggle(index)}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default React.memo(AskAIInputDatasets);
