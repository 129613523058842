import React from "react";
import clsx from "clsx";

import _ from "lodash";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import EnvironmentTypeConfig from "src/pages/private/Environments/components/EnvironmentTypeConfig";
import Text from "components/Widget/Text";
import styles from "src/pages/private/Environments/Environments.module.scss";
import useGetEnvironments from "../../../../../../hooks/useGetEnvironment";
import { Environment } from "src/pages/private/Environments/Environments";
import { MachineLearningTask } from "src/pages/Projects/AddAutoMLRecipe/hooks/useGetAutoMLProblemTypes";
import { PSFields } from "../utils/PredictionService.constants";
import { envMetadataFind } from "pages/Projects/helpers/projects.helpers";
import { statusValues } from "src/pages/private/Environments/components/StatusBar/StatusBar";
import { useGetEnvironmentTypes } from "src/hooks/api/environments/useGetEnvironmentTypes";
import { useStyles as useEnvStyles } from "src/pages/Projects/ProjectSettings/styling";

const useStyles = makeStyles(() => ({
  envTypeSpan: {
    fontSize: 12,
    color: "#838383"
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  }
}));

interface IProps {
  value: string;
  recipeType?: string;
  onChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  onEnvChange: (environment: Environment) => void;
}

const SelectEnvironment: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { envMenuItem, roundEnvStatus } = useEnvStyles();

  const envTypesResult = useGetEnvironmentTypes();
  const { data } = envTypesResult;

  const envResults = useGetEnvironments({
    onSuccess: (data: any) => {
      const environment = _.find(data, { id: props.value });
      if (environment) {
        props.onEnvChange(environment);
      }
    },
    refetchOnMount: true
  });

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange(e);
    const env = _.find(envResults.data, { id: e.target.value });
    if (env) {
      props.onEnvChange(env);
    }
  };

  return (
    <Grid className={classes.flexEnd}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel shrink htmlFor={PSFields.environment.id}>
          Environment *
        </InputLabel>
        <Select
          label={PSFields.environment.label}
          required
          fullWidth
          defaultValue=""
          id={PSFields.environment.id}
          name={PSFields.environment.id}
          value={props.value}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          input={<OutlinedInput notched label="Environment *" />}
          displayEmpty>
          {envResults.data?.map((env: any) => {
            const { name: type } = envMetadataFind(data ?? [], env) || {};
            return (
              <MenuItem key={env.id} value={env.id}>
                <Box className={envMenuItem}>
                  <Grid container direction="column">
                    <Text value={env.name} />
                    <span className={classes.envTypeSpan}>
                      <EnvironmentTypeConfig
                        envType={type}
                        cores={env?.cores}
                        memInMbs={env?.memInMbs}
                        diskInGbs={env?.diskInGbs}
                      />
                    </span>
                  </Grid>
                  <div
                    className={clsx(
                      roundEnvStatus,
                      styles.envStatus,
                      styles[env?.launchStatus?.trim()?.toLowerCase()]
                    )}>
                    {statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text ||
                      env?.launchStatus ||
                      "Unknown"}
                  </div>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {props.recipeType === MachineLearningTask.TIMESERIES_FORECASTING && (
        <Tooltip title="The Prophet package must be manually installed on the chosen environment for time series prediction, if it is not already present">
          <InfoOutlined fontSize="small" />
        </Tooltip>
      )}
      {props.recipeType === MachineLearningTask.CLUSTERING && (
        <Tooltip title="The Hdbscan package must be manually installed on the chosen environment for clustering prediction, if it is not already present">
          <InfoOutlined fontSize="small" />
        </Tooltip>
      )}
    </Grid>
  );
};

export default SelectEnvironment;
