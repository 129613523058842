import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  Divider
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Entities } from "src/types";
import Search from "src/components/custom/Search/Search";
import { isEqual, sortBy } from "lodash";

type Props<T> = {
  title: string;
  icon: React.ReactNode;
  allItems: Entities;
  selectedItems: Array<T>;
  onSelectedItemsChange: (items: Array<T>) => void;
  searchPlaceholder: string;
};

const useStyles = makeStyles({
  entityTitleWrap: {
    flexWrap: "nowrap",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    padding: "6px 10px",
    gap: "8px",
    alignItems: "center"
  },
  entityList: {
    display: "flex",
    maxHeight: "192px",
    overflow: "auto",
    flexWrap: "nowrap",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  entitiesList: {
    borderRadius: "4px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    flexWrap: "nowrap",
    gap: "8px",
    minHeight: "300px"
  },
  listItemText: {
    fontSize: "14px",
    lineHeight: 1.54
  },
  checkbox: {
    padding: "5px 10px",
    "&$checked": {
      color: "#4646b5"
    }
  },
  checked: {}
});

export function EntitiesList<T>({
  title,
  icon,
  allItems,
  selectedItems,
  onSelectedItemsChange,
  searchPlaceholder
}: Props<T>) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredItems = useMemo(
    () => allItems.filter((dataset: $TSFixMe) => dataset.name.toLowerCase().includes(searchTerm)),
    [allItems, searchTerm]
  );
  const selectedItemsNames = useMemo(
    () => selectedItems.map((item: $TSFixMe) => item.name),
    [selectedItems]
  );

  const isSelectAllChecked = useMemo(() => {
    return isEqual(
      sortBy(selectedItemsNames),
      sortBy(filteredItems?.map((item: $TSFixMe) => item.name))
    );
  }, [selectedItemsNames, filteredItems]);

  return (
    <Grid container direction="column" className={classes.entitiesList}>
      <Grid item container direction="row" className={classes.entityTitleWrap}>
        {icon}
        <Typography test-id="standardRecipeEntityTitle">{title}</Typography>
      </Grid>
      <Grid item style={{ padding: "10px" }}>
        <Search
          placeholder={searchPlaceholder}
          onSearch={(event) => setSearchTerm(event.target.value.toLowerCase())}
          style={{ width: "100%" }}
          inputProps={{
            width: "100%",
            "test-id": "standardRecipeSearchDatasetField"
          }}
        />
      </Grid>
      <Grid item container direction="column" className={classes.entityList}>
        <FormControlLabel
          style={{ margin: 0, width: "100%", justifyContent: "stretch" }}
          control={
            <Checkbox
              checked={isSelectAllChecked}
              test-id="standardRecipeEntityCheckAll"
              classes={{
                root: classes.checkbox,
                checked: classes.checked
              }}
              size="small"
              {...(selectedItems.length !== 0 && selectedItems?.length !== filteredItems?.length
                ? { indeterminate: true }
                : {})}
              onChange={(e) => {
                if (e?.target?.checked) {
                  //@ts-expect-error
                  onSelectedItemsChange([...filteredItems]);
                } else {
                  onSelectedItemsChange([]);
                }
              }}
            />
          }
          label={
            <Typography
              color="primary"
              className={classes.listItemText}
              test-id="standardRecipeCheckAllLabel">
              Select all
            </Typography>
          }
        />
        <Divider />
        {filteredItems.map((item: $TSFixMe) => {
          const isChecked = selectedItemsNames.includes(item.name);
          return (
            <FormControlLabel
              key={item.id}
              style={{ margin: 0, width: "100%", justifyContent: "stretch" }}
              control={
                <Checkbox
                  test-id="standardRecipeEntityCheckbox"
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checked
                  }}
                  size="small"
                  onChange={() => {
                    const updatedItems = isChecked
                      ? selectedItems.filter((selectedItem: $TSFixMe) =>
                          selectedItem["id"]
                            ? selectedItem.id !== item.id
                            : selectedItem.name !== item.name
                        )
                      : [...selectedItems, item];
                    onSelectedItemsChange(updatedItems);
                  }}
                  checked={isChecked}
                />
              }
              label={
                <Typography
                  color="primary"
                  className={classes.listItemText}
                  test-id="standardRecipeEntityName">
                  {item.name}
                </Typography>
              }
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
