/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation, generatePath } from "react-router-dom";
import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";

import { Spinner } from "src/components";
import {
  useGetEntityFeatures,
  useGetInputEntities,
  useGetScenario,
  useCreateRecipe
} from "src/hooks/api";
import { useQuery } from "src/hooks";
import { CodeRecipeHeader } from "./CodeRecipeHeader/CodeRecipeHeader";
import CodeRecipeInputContainer from "./CodeRecipeInputContainer/CodeRecipeInputContainer";
import CodeRecipeTabContainer from "./CodeRecipeTabContainer/CodeRecipeTabContainer";
import { useCodeRecipeContext } from "./CodeRecipeContext/useCodeRecipeContext";
import { type Scenario, type Entities, type EntityFeaturesResponse } from "src/types";
import AddEntitiesDialog from "../AddStandardRecipe/AddEntitiesDialog/AddEntitiesDialog";
import { ExpandIcon } from "src/icons/ExpandIcon";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { useScenariosStore } from "src/store/store";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles({
  gridContainer: {
    height: "100%",
    gap: "18px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexGrow: 0,
    minWidth: "300px",
    maxWidth: "20%",
    flexBasis: "20%"
  },
  codeRecipeContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    backgroundColor: "#f5f5f5"
  },
  codeRecipeBodyContainer: {
    flex: 1,
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    flexWrap: "nowrap",
    background: "#f5f5f5",
    overflow: "hidden"
  },
  leftContainer: {
    border: "1px solid #BDBDBD",
    borderRadius: "12px",
    background: "#FFFFFF",
    height: "fit-content",
    maxHeight: "100%"
  },
  inputContainer: {
    height: "100%"
  },
  outputContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  codeContainer: {
    borderLeft: "1px solid #CECECE",
    width: ({ isJobPath, isExpanded }: { isJobPath?: boolean; isExpanded: boolean }) =>
      !!isJobPath ? "100%" : isExpanded ? "80%" : "calc(100vw - 80px)",
    flexBasis: ({ isJobPath, isExpanded }: { isJobPath?: boolean; isExpanded: boolean }) =>
      !!isJobPath ? "100%" : isExpanded ? "80%" : "calc(100vw - 80px)",
    minWidth: ({ isJobPath, isExpanded }: { isJobPath?: boolean; isExpanded: boolean }) =>
      !!isJobPath ? "100%" : isExpanded ? "80%" : "calc(100vw - 80px)"
  }
});

const CodeRecipeContainer = (props: $TSFixMe) => {
  const { entityIds } = props;
  const [isExpanded, setIsExpanded] = React.useState(true);
  const navigate = useNavigate();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const classes = useStyles({ isJobPath, isExpanded });

  const { projectId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const queryParameters = useQuery();

  const [showAddEntitiesDialog, setShowAddEntitiesDialog] = useState<boolean>(false);
  const scenarios = useScenariosStore((state) => state.scenarios);
  const {
    inputDatasets,
    recipe,
    onUpdateRecipe,
    scenarioData,
    entityFeaturesMap,
    setScenarioData,
    setEntityFeaturesMap,
    editorValue,
    setInputDatasets,
    setAllEntities,
    handleInputDatasetsChange,
    isSelectedEntitiesUpdateInProgess
  } = useCodeRecipeContext();

  useEffect(() => {
    setShowAddEntitiesDialog(inputDatasets?.length === 0);
  }, [inputDatasets]);

  const { isLoading, data: allEntities } = useGetInputEntities({
    id: projectId,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (data: Entities) => {
      const selectedEntitiesStr = sessionStorage.getItem("selectedEntities");
      const selectedEntities = selectedEntitiesStr ? JSON.parse(selectedEntitiesStr) : null;
      const filteredInputDatasets = data.filter(
        (dataset) =>
          entityIds?.includes(dataset.id) ||
          queryParameters?.get("entity") === dataset.id ||
          (selectedEntities?.includes(dataset.id) &&
            dataset.entityMeta?.entityViewType?.toLowerCase() !== "chart" &&
            dataset.entityMeta?.entityViewType?.toLowerCase() !== "artifact" &&
            dataset.entityMeta?.entityViewType?.toLowerCase() !== "model")
      );
      sessionStorage.setItem("selectedEntities", "");
      setAllEntities(data);
      setInputDatasets(filteredInputDatasets);
    }
  });

  const { isLoading: isScenarioLoading } = useGetScenario({
    scenarioId,
    onSuccess: (data: Scenario) => {
      setScenarioData(data);
    }
  });

  const { isFetching: isEntitiesLoading } = useGetEntityFeatures({
    datasetsToFetch: inputDatasets,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (entityFeatures: EntityFeaturesResponse) => {
      const newFeatures = inputDatasets?.reduce(
        (acc, dataset, index) => ({
          ...acc,
          [dataset?.name]: entityFeatures[index]
        }),
        {}
      );
      setEntityFeaturesMap(newFeatures);
    }
  });

  const { isFetching: isCreatingRecipe } = useCreateRecipe({
    inputEntities: inputDatasets,
    conditions: recipe?.condition?.expression,
    recipeId: recipe?.id,
    projectId,
    code: editorValue,
    entity: allEntities?.find((item: $TSFixMe) => item.id === queryParameters?.get("entity")),
    newAskAIFlow: true,
    onSuccess: (response) => {
      onUpdateRecipe(response.group);

      if (projectId && scenarioId) {
        navigate(
          generatePath(WebPaths.CodeRecipeContainer, {
            projectId,
            scenarioId,
            groupId: response.group?.id
          }),
          { replace: true }
        );
      }
    }
  });

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  const openAddEntitiesDialog = useCallback(() => setShowAddEntitiesDialog(true), []);
  const onCollapse = useCallback(() => setIsExpanded(false), []);

  return isLoading || isCreatingRecipe || isEntitiesLoading || isLoading ? (
    <Spinner />
  ) : (
    <Grid className={classes.codeRecipeContainer}>
      {!isScenarioLoading && <CodeRecipeHeader isDefaultScenario={isDefaultScenario} />}
      <Grid container direction="row" className={classes.codeRecipeBodyContainer}>
        {isExpanded ? (
          <Grid item className={classes.gridContainer}>
            <Grid className={classes.leftContainer}>
              <CodeRecipeInputContainer
                inputDatasets={inputDatasets}
                entityFeaturesMap={entityFeaturesMap}
                handleInputDatasetsChange={handleInputDatasetsChange}
                openAddEntitiesDialog={openAddEntitiesDialog}
                onCollapse={onCollapse}
                isDefaultScenario={isDefaultScenario}
              />
            </Grid>
          </Grid>
        ) : (
          <Box p="16px">
            <Grid item className={classes.leftContainer}>
              <Box height="100%" p="16px 8px" bgcolor="#D9D9D9" borderRadius="11px">
                <IconButton onClick={() => setIsExpanded(true)} size="small">
                  <ExpandIcon />
                </IconButton>
              </Box>
            </Grid>
          </Box>
        )}
        <Grid item className={classes.codeContainer}>
          <NewThemeWrapper>
            <CodeRecipeTabContainer isDefaultScenario={isDefaultScenario} />
          </NewThemeWrapper>
        </Grid>
      </Grid>
      {showAddEntitiesDialog && (
        <AddEntitiesDialog
          recipeId={recipe?.id}
          selectedInputDatasets={inputDatasets}
          allEntities={allEntities || []}
          onClose={() => {
            setShowAddEntitiesDialog(false);
          }}
          onSubmit={({ updatedInputDatasets }) => handleInputDatasetsChange(updatedInputDatasets)}
          isSubmitInProgress={isSelectedEntitiesUpdateInProgess}
          noEntitiesText="If you cannot see datasets here, please add it to the canvas first"
        />
      )}
    </Grid>
  );
};

export default React.memo(CodeRecipeContainer);
