import React from "react";
import { ClickAwayListener, MenuItem, Popper } from "@material-ui/core";

import _, { map } from "lodash";
import { OverflowTooltip } from "src/components";

interface IProps {
  anchorElColumn: HTMLTextAreaElement | null;
  filteredSuggestions: string[];
  highlightedIndex: number | null;
  menuItemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
  onSuggestionClick: (suggestion: string) => void;
  onClose: () => void;
}

const InputSuggestions: React.FC<IProps> = (props) => {
  const {
    anchorElColumn,
    highlightedIndex,
    menuItemRefs,
    filteredSuggestions,
    onClose,
    onSuggestionClick
  } = props;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={Boolean(anchorElColumn)}
        anchorEl={anchorElColumn}
        placement="top-start"
        style={{
          background: "white",
          maxHeight: "300px",
          overflowX: "auto",
          width: 225,
          zIndex: 1000,
          borderRadius: 12,
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
        }}>
        {map(filteredSuggestions, (item, index) => (
          <MenuItem
            key={`suggestion-${index}`}
            selected={index === highlightedIndex}
            onClick={() => onSuggestionClick(item)}
            ref={(el) => (menuItemRefs.current[index] = el)}>
            <OverflowTooltip value={item} tooltipProps={{ placement: "right" }} title={item} />
          </MenuItem>
        ))}
      </Popper>
    </ClickAwayListener>
  );
};

export default React.memo(InputSuggestions);
