/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateTransformDTO } from '../Models';
// @ts-ignore
import { DFSConfigGroupDetailsDto } from '../Models';
// @ts-ignore
import { DFSRunConfigGroupDto } from '../Models';
// @ts-ignore
import { DataConversationDto } from '../Models';
// @ts-ignore
import { DeleteDataConversationsRequest } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { GenerateCodeRequest } from '../Models';
// @ts-ignore
import { GenerateCodeResponse } from '../Models';
// @ts-ignore
import { GenerateOutputRequest } from '../Models';
// @ts-ignore
import { GenerateOutputResponse } from '../Models';
// @ts-ignore
import { GroupLogDto } from '../Models';
// @ts-ignore
import { GroupRunResultDto } from '../Models';
// @ts-ignore
import { LikeGeneratedOutputRequest } from '../Models';
// @ts-ignore
import { PipelineTestResult } from '../Models';
// @ts-ignore
import { RecipeQueueResponse } from '../Models';
// @ts-ignore
import { RecipeRunData } from '../Models';
// @ts-ignore
import { Schema } from '../Models';
// @ts-ignore
import { TestTransformDFSConfigDto } from '../Models';
// @ts-ignore
import { TransformVariableDto } from '../Models';
// @ts-ignore
import { UpdateDFSRunConfigGroupDto } from '../Models';
// @ts-ignore
import { UpdateDataConversationRequest } from '../Models';
/**
 * DfsRunConfigGroupControllerV2Api - axios parameter creator
 * @export
 */
export const DfsRunConfigGroupControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroupToQueue: async (groupId: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('addGroupToQueue', 'groupId', groupId)
            const localVarPath = `/v2/dfs-run-config-groups/add-recipe-to-queue/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearRecipeQueue: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('clearRecipeQueue', 'projectId', projectId)
            const localVarPath = `/v2/dfs-run-config-groups/clear-recipe-queue/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUUID 
         * @param {CreateTransformDTO} createTransformDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfigGroup: async (projectUUID: string, createTransformDTO: CreateTransformDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUUID' is not null or undefined
            assertParamExists('createConfigGroup', 'projectUUID', projectUUID)
            // verify required parameter 'createTransformDTO' is not null or undefined
            assertParamExists('createConfigGroup', 'createTransformDTO', createTransformDTO)
            const localVarPath = `/v2/dfs-run-config-groups/{project_UUID}`
                .replace(`{${"project_UUID"}}`, encodeURIComponent(String(projectUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransformDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataConversations: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('dataConversations', 'conversationId', conversationId)
            const localVarPath = `/v2/dfs-run-config-groups/{conversationId}/code-history`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataConversation: async (conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('deleteDataConversation', 'conversationId', conversationId)
            // verify required parameter 'deleteDataConversationsRequest' is not null or undefined
            assertParamExists('deleteDataConversation', 'deleteDataConversationsRequest', deleteDataConversationsRequest)
            const localVarPath = `/v2/dfs-run-config-groups/{conversationId}/code-history`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDataConversationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {DownloadOutputNotebookOutTypeEnum} [outType] 
         * @param {string} [transform] 
         * @param {string} [runConfigId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutputNotebook: async (id: string, scenarioId?: string, outType?: DownloadOutputNotebookOutTypeEnum, transform?: string, runConfigId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOutputNotebook', 'id', id)
            const localVarPath = `/v2/dfs-run-config-groups/{id}/download-output`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (outType !== undefined) {
                localVarQueryParameter['outType'] = outType;
            }

            if (transform !== undefined) {
                localVarQueryParameter['transform'] = transform;
            }

            if (runConfigId !== undefined) {
                localVarQueryParameter['runConfigId'] = runConfigId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroups: async (id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/dfs-run-config-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateCodeRequest} generateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCode: async (generateCodeRequest: GenerateCodeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateCodeRequest' is not null or undefined
            assertParamExists('generateCode', 'generateCodeRequest', generateCodeRequest)
            const localVarPath = `/v2/dfs-run-config-groups/generate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateOutputRequest} generateOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOutput: async (generateOutputRequest: GenerateOutputRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateOutputRequest' is not null or undefined
            assertParamExists('generateOutput', 'generateOutputRequest', generateOutputRequest)
            const localVarPath = `/v2/dfs-run-config-groups/generate-output`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateOutputRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {string} codeHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataConversation: async (conversationId: string, codeHistoryId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDataConversation', 'conversationId', conversationId)
            // verify required parameter 'codeHistoryId' is not null or undefined
            assertParamExists('getDataConversation', 'codeHistoryId', codeHistoryId)
            const localVarPath = `/v2/dfs-run-config-groups/{conversationId}/code-history/{codeHistoryId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"codeHistoryId"}}`, encodeURIComponent(String(codeHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeDetails: async (id: string, scenarioId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipeDetails', 'id', id)
            const localVarPath = `/v2/dfs-run-config-groups/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeQueue: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getRecipeQueue', 'projectId', projectId)
            const localVarPath = `/v2/dfs-run-config-groups/get-recipe-queue/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [transform] 
         * @param {string} [runConfigId] 
         * @param {string} [projectRunEntryId] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunTransformProgress: async (id: string, scenarioId?: string, transform?: string, runConfigId?: string, projectRunEntryId?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRunTransformProgress', 'id', id)
            const localVarPath = `/v2/dfs-run-config-groups/{id}/live-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (transform !== undefined) {
                localVarQueryParameter['transform'] = transform;
            }

            if (runConfigId !== undefined) {
                localVarQueryParameter['runConfigId'] = runConfigId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunTransformVariables: async (id: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRunTransformVariables', 'id', id)
            // verify required parameter 'testTransformDFSConfigDto' is not null or undefined
            assertParamExists('getRunTransformVariables', 'testTransformDFSConfigDto', testTransformDFSConfigDto)
            const localVarPath = `/v2/dfs-run-config-groups/{id}/variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testTransformDFSConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSchema1', 'id', id)
            const localVarPath = `/v2/dfs-run-config-groups/{id}/schema`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} historyId 
         * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeGeneratedOutput: async (historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'historyId' is not null or undefined
            assertParamExists('likeGeneratedOutput', 'historyId', historyId)
            // verify required parameter 'likeGeneratedOutputRequest' is not null or undefined
            assertParamExists('likeGeneratedOutput', 'likeGeneratedOutputRequest', likeGeneratedOutputRequest)
            const localVarPath = `/v2/dfs-run-config-groups/generated-output/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (historyId !== undefined) {
                localVarQueryParameter['historyId'] = historyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(likeGeneratedOutputRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {RecipeRunData} recipeRunData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reOrderRecipeQueue: async (projectId: string, recipeRunData: RecipeRunData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('reOrderRecipeQueue', 'projectId', projectId)
            // verify required parameter 'recipeRunData' is not null or undefined
            assertParamExists('reOrderRecipeQueue', 'recipeRunData', recipeRunData)
            const localVarPath = `/v2/dfs-run-config-groups/reorder-recipe-queue/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recipeRunData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} groupId 
         * @param {string} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRecipeFromQueue: async (projectId: string, groupId: string, scenarioId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeRecipeFromQueue', 'projectId', projectId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('removeRecipeFromQueue', 'groupId', groupId)
            // verify required parameter 'scenarioId' is not null or undefined
            assertParamExists('removeRecipeFromQueue', 'scenarioId', scenarioId)
            const localVarPath = `/v2/dfs-run-config-groups/remove-recipe/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runGroup: async (groupId: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('runGroup', 'groupId', groupId)
            const localVarPath = `/v2/dfs-run-config-groups/run/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {boolean} [hardStop] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopGroup: async (groupId: string, scenarioId?: string, hardStop?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('stopGroup', 'groupId', groupId)
            const localVarPath = `/v2/dfs-run-config-groups/stop/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (hardStop !== undefined) {
                localVarQueryParameter['hardStop'] = hardStop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
         * @param {number} [sampleRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTransformInGroup: async (groupId: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, sampleRows?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('testTransformInGroup', 'groupId', groupId)
            // verify required parameter 'testTransformDFSConfigDto' is not null or undefined
            assertParamExists('testTransformInGroup', 'testTransformDFSConfigDto', testTransformDFSConfigDto)
            const localVarPath = `/v2/dfs-run-config-groups/test/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sampleRows !== undefined) {
                localVarQueryParameter['sampleRows'] = sampleRows;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testTransformDFSConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Array<TestTransformDFSConfigDto>} testTransformDFSConfigDto 
         * @param {string} [mode] 
         * @param {number} [sampleRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTransformInGroupV2: async (groupId: string, testTransformDFSConfigDto: Array<TestTransformDFSConfigDto>, mode?: string, sampleRows?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('testTransformInGroupV2', 'groupId', groupId)
            // verify required parameter 'testTransformDFSConfigDto' is not null or undefined
            assertParamExists('testTransformInGroupV2', 'testTransformDFSConfigDto', testTransformDFSConfigDto)
            const localVarPath = `/v2/dfs-run-config-groups/testv2/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (sampleRows !== undefined) {
                localVarQueryParameter['sampleRows'] = sampleRows;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testTransformDFSConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {UpdateDataConversationRequest} updateDataConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataConversation: async (conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('updateDataConversation', 'conversationId', conversationId)
            // verify required parameter 'updateDataConversationRequest' is not null or undefined
            assertParamExists('updateDataConversation', 'updateDataConversationRequest', updateDataConversationRequest)
            const localVarPath = `/v2/dfs-run-config-groups/{conversationId}/code-history`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDFSRunConfigGroupDto} updateDFSRunConfigGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (id: string, updateDFSRunConfigGroupDto: UpdateDFSRunConfigGroupDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroup', 'id', id)
            // verify required parameter 'updateDFSRunConfigGroupDto' is not null or undefined
            assertParamExists('updateGroup', 'updateDFSRunConfigGroupDto', updateDFSRunConfigGroupDto)
            const localVarPath = `/v2/dfs-run-config-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDFSRunConfigGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DfsRunConfigGroupControllerV2Api - functional programming interface
 * @export
 */
export const DfsRunConfigGroupControllerV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DfsRunConfigGroupControllerV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGroupToQueue(groupId: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGroupToQueue(groupId, scenarioId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.addGroupToQueue']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearRecipeQueue(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearRecipeQueue(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.clearRecipeQueue']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUUID 
         * @param {CreateTransformDTO} createTransformDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConfigGroup(projectUUID: string, createTransformDTO: CreateTransformDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSRunConfigGroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConfigGroup(projectUUID, createTransformDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.createConfigGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataConversations(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataConversationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataConversations(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.dataConversations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataConversation(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataConversation(conversationId, deleteDataConversationsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.deleteDataConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {DownloadOutputNotebookOutTypeEnum} [outType] 
         * @param {string} [transform] 
         * @param {string} [runConfigId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOutputNotebook(id: string, scenarioId?: string, outType?: DownloadOutputNotebookOutTypeEnum, transform?: string, runConfigId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOutputNotebook(id, scenarioId, outType, transform, runConfigId, projectRunEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.downloadOutputNotebook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findGroups(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DFSRunConfigGroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findGroups(id, name, projectId, scenarioId, projectRunEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.findGroups']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GenerateCodeRequest} generateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCode(generateCodeRequest: GenerateCodeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCode(generateCodeRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.generateCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GenerateOutputRequest} generateOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateOutput(generateOutputRequest: GenerateOutputRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateOutput(generateOutputRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.generateOutput']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {string} codeHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataConversation(conversationId: string, codeHistoryId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataConversation(conversationId, codeHistoryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.getDataConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeDetails(id: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSConfigGroupDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeDetails(id, scenarioId, projectRunEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.getRecipeDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeQueue(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeQueueResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeQueue(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.getRecipeQueue']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [transform] 
         * @param {string} [runConfigId] 
         * @param {string} [projectRunEntryId] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRunTransformProgress(id: string, scenarioId?: string, transform?: string, runConfigId?: string, projectRunEntryId?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRunTransformProgress(id, scenarioId, transform, runConfigId, projectRunEntryId, limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.getRunTransformProgress']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRunTransformVariables(id: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransformVariableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRunTransformVariables(id, testTransformDFSConfigDto, scenarioId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.getRunTransformVariables']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchema1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchema1(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.getSchema1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} historyId 
         * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeGeneratedOutput(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeGeneratedOutput(historyId, likeGeneratedOutputRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.likeGeneratedOutput']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {RecipeRunData} recipeRunData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reOrderRecipeQueue(projectId: string, recipeRunData: RecipeRunData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reOrderRecipeQueue(projectId, recipeRunData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.reOrderRecipeQueue']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} groupId 
         * @param {string} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRecipeFromQueue(projectId: string, groupId: string, scenarioId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRecipeFromQueue(projectId, groupId, scenarioId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.removeRecipeFromQueue']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runGroup(groupId: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupRunResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runGroup(groupId, scenarioId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.runGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {boolean} [hardStop] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopGroup(groupId: string, scenarioId?: string, hardStop?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopGroup(groupId, scenarioId, hardStop, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.stopGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
         * @param {number} [sampleRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTransformInGroup(groupId: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, sampleRows?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTransformInGroup(groupId, testTransformDFSConfigDto, sampleRows, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.testTransformInGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Array<TestTransformDFSConfigDto>} testTransformDFSConfigDto 
         * @param {string} [mode] 
         * @param {number} [sampleRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTransformInGroupV2(groupId: string, testTransformDFSConfigDto: Array<TestTransformDFSConfigDto>, mode?: string, sampleRows?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTransformInGroupV2(groupId, testTransformDFSConfigDto, mode, sampleRows, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.testTransformInGroupV2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {UpdateDataConversationRequest} updateDataConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataConversation(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataConversation(conversationId, updateDataConversationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.updateDataConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDFSRunConfigGroupDto} updateDFSRunConfigGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(id: string, updateDFSRunConfigGroupDto: UpdateDFSRunConfigGroupDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSRunConfigGroupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(id, updateDFSRunConfigGroupDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DfsRunConfigGroupControllerV2Api.updateGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DfsRunConfigGroupControllerV2Api - factory interface
 * @export
 */
export const DfsRunConfigGroupControllerV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DfsRunConfigGroupControllerV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroupToQueue(groupId: string, scenarioId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.addGroupToQueue(groupId, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearRecipeQueue(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clearRecipeQueue(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUUID 
         * @param {CreateTransformDTO} createTransformDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfigGroup(projectUUID: string, createTransformDTO: CreateTransformDTO, options?: any): AxiosPromise<DFSRunConfigGroupDto> {
            return localVarFp.createConfigGroup(projectUUID, createTransformDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataConversations(conversationId: string, options?: any): AxiosPromise<Array<DataConversationDto>> {
            return localVarFp.dataConversations(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataConversation(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDataConversation(conversationId, deleteDataConversationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {DownloadOutputNotebookOutTypeEnum} [outType] 
         * @param {string} [transform] 
         * @param {string} [runConfigId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutputNotebook(id: string, scenarioId?: string, outType?: DownloadOutputNotebookOutTypeEnum, transform?: string, runConfigId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadOutputNotebook(id, scenarioId, outType, transform, runConfigId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroups(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<Array<DFSRunConfigGroupDto>> {
            return localVarFp.findGroups(id, name, projectId, scenarioId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateCodeRequest} generateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCode(generateCodeRequest: GenerateCodeRequest, options?: any): AxiosPromise<GenerateCodeResponse> {
            return localVarFp.generateCode(generateCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateOutputRequest} generateOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOutput(generateOutputRequest: GenerateOutputRequest, options?: any): AxiosPromise<GenerateOutputResponse> {
            return localVarFp.generateOutput(generateOutputRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {string} codeHistoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataConversation(conversationId: string, codeHistoryId: string, options?: any): AxiosPromise<DataConversationDto> {
            return localVarFp.getDataConversation(conversationId, codeHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeDetails(id: string, scenarioId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<DFSConfigGroupDetailsDto> {
            return localVarFp.getRecipeDetails(id, scenarioId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeQueue(projectId: string, options?: any): AxiosPromise<RecipeQueueResponse> {
            return localVarFp.getRecipeQueue(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [transform] 
         * @param {string} [runConfigId] 
         * @param {string} [projectRunEntryId] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunTransformProgress(id: string, scenarioId?: string, transform?: string, runConfigId?: string, projectRunEntryId?: string, limit?: number, options?: any): AxiosPromise<GroupLogDto> {
            return localVarFp.getRunTransformProgress(id, scenarioId, transform, runConfigId, projectRunEntryId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunTransformVariables(id: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, scenarioId?: string, options?: any): AxiosPromise<TransformVariableDto> {
            return localVarFp.getRunTransformVariables(id, testTransformDFSConfigDto, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema1(id: string, options?: any): AxiosPromise<Schema> {
            return localVarFp.getSchema1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} historyId 
         * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeGeneratedOutput(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: any): AxiosPromise<DataConversationDto> {
            return localVarFp.likeGeneratedOutput(historyId, likeGeneratedOutputRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {RecipeRunData} recipeRunData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reOrderRecipeQueue(projectId: string, recipeRunData: RecipeRunData, options?: any): AxiosPromise<void> {
            return localVarFp.reOrderRecipeQueue(projectId, recipeRunData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} groupId 
         * @param {string} scenarioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRecipeFromQueue(projectId: string, groupId: string, scenarioId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeRecipeFromQueue(projectId, groupId, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runGroup(groupId: string, scenarioId?: string, options?: any): AxiosPromise<GroupRunResultDto> {
            return localVarFp.runGroup(groupId, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} [scenarioId] 
         * @param {boolean} [hardStop] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopGroup(groupId: string, scenarioId?: string, hardStop?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.stopGroup(groupId, scenarioId, hardStop, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
         * @param {number} [sampleRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTransformInGroup(groupId: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, sampleRows?: number, options?: any): AxiosPromise<PipelineTestResult> {
            return localVarFp.testTransformInGroup(groupId, testTransformDFSConfigDto, sampleRows, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Array<TestTransformDFSConfigDto>} testTransformDFSConfigDto 
         * @param {string} [mode] 
         * @param {number} [sampleRows] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTransformInGroupV2(groupId: string, testTransformDFSConfigDto: Array<TestTransformDFSConfigDto>, mode?: string, sampleRows?: number, options?: any): AxiosPromise<PipelineTestResult> {
            return localVarFp.testTransformInGroupV2(groupId, testTransformDFSConfigDto, mode, sampleRows, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {UpdateDataConversationRequest} updateDataConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataConversation(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: any): AxiosPromise<DataConversationDto> {
            return localVarFp.updateDataConversation(conversationId, updateDataConversationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDFSRunConfigGroupDto} updateDFSRunConfigGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(id: string, updateDFSRunConfigGroupDto: UpdateDFSRunConfigGroupDto, options?: any): AxiosPromise<DFSRunConfigGroupDto> {
            return localVarFp.updateGroup(id, updateDFSRunConfigGroupDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DfsRunConfigGroupControllerV2Api - interface
 * @export
 * @interface DfsRunConfigGroupControllerV2Api
 */
export interface DfsRunConfigGroupControllerV2ApiInterface {
    /**
     * 
     * @param {string} groupId 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    addGroupToQueue(groupId: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    clearRecipeQueue(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectUUID 
     * @param {CreateTransformDTO} createTransformDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    createConfigGroup(projectUUID: string, createTransformDTO: CreateTransformDTO, options?: RawAxiosRequestConfig): AxiosPromise<DFSRunConfigGroupDto>;

    /**
     * 
     * @param {string} conversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    dataConversations(conversationId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataConversationDto>>;

    /**
     * 
     * @param {string} conversationId 
     * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    deleteDataConversation(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {DownloadOutputNotebookOutTypeEnum} [outType] 
     * @param {string} [transform] 
     * @param {string} [runConfigId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    downloadOutputNotebook(id: string, scenarioId?: string, outType?: DownloadOutputNotebookOutTypeEnum, transform?: string, runConfigId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [projectId] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    findGroups(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DFSRunConfigGroupDto>>;

    /**
     * 
     * @param {GenerateCodeRequest} generateCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    generateCode(generateCodeRequest: GenerateCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenerateCodeResponse>;

    /**
     * 
     * @param {GenerateOutputRequest} generateOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    generateOutput(generateOutputRequest: GenerateOutputRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenerateOutputResponse>;

    /**
     * 
     * @param {string} conversationId 
     * @param {string} codeHistoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    getDataConversation(conversationId: string, codeHistoryId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataConversationDto>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    getRecipeDetails(id: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<DFSConfigGroupDetailsDto>;

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    getRecipeQueue(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<RecipeQueueResponse>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [transform] 
     * @param {string} [runConfigId] 
     * @param {string} [projectRunEntryId] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    getRunTransformProgress(id: string, scenarioId?: string, transform?: string, runConfigId?: string, projectRunEntryId?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GroupLogDto>;

    /**
     * 
     * @param {string} id 
     * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    getRunTransformVariables(id: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<TransformVariableDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    getSchema1(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Schema>;

    /**
     * 
     * @param {string} historyId 
     * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    likeGeneratedOutput(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataConversationDto>;

    /**
     * 
     * @param {string} projectId 
     * @param {RecipeRunData} recipeRunData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    reOrderRecipeQueue(projectId: string, recipeRunData: RecipeRunData, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectId 
     * @param {string} groupId 
     * @param {string} scenarioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    removeRecipeFromQueue(projectId: string, groupId: string, scenarioId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} groupId 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    runGroup(groupId: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<GroupRunResultDto>;

    /**
     * 
     * @param {string} groupId 
     * @param {string} [scenarioId] 
     * @param {boolean} [hardStop] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    stopGroup(groupId: string, scenarioId?: string, hardStop?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} groupId 
     * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
     * @param {number} [sampleRows] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    testTransformInGroup(groupId: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, sampleRows?: number, options?: RawAxiosRequestConfig): AxiosPromise<PipelineTestResult>;

    /**
     * 
     * @param {string} groupId 
     * @param {Array<TestTransformDFSConfigDto>} testTransformDFSConfigDto 
     * @param {string} [mode] 
     * @param {number} [sampleRows] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    testTransformInGroupV2(groupId: string, testTransformDFSConfigDto: Array<TestTransformDFSConfigDto>, mode?: string, sampleRows?: number, options?: RawAxiosRequestConfig): AxiosPromise<PipelineTestResult>;

    /**
     * 
     * @param {string} conversationId 
     * @param {UpdateDataConversationRequest} updateDataConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    updateDataConversation(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataConversationDto>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateDFSRunConfigGroupDto} updateDFSRunConfigGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2ApiInterface
     */
    updateGroup(id: string, updateDFSRunConfigGroupDto: UpdateDFSRunConfigGroupDto, options?: RawAxiosRequestConfig): AxiosPromise<DFSRunConfigGroupDto>;

}

/**
 * DfsRunConfigGroupControllerV2Api - object-oriented interface
 * @export
 * @class DfsRunConfigGroupControllerV2Api
 * @extends {BaseAPI}
 */
export class DfsRunConfigGroupControllerV2Api extends BaseAPI implements DfsRunConfigGroupControllerV2ApiInterface {
    /**
     * 
     * @param {string} groupId 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public addGroupToQueue(groupId: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).addGroupToQueue(groupId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public clearRecipeQueue(projectId: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).clearRecipeQueue(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUUID 
     * @param {CreateTransformDTO} createTransformDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public createConfigGroup(projectUUID: string, createTransformDTO: CreateTransformDTO, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).createConfigGroup(projectUUID, createTransformDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public dataConversations(conversationId: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).dataConversations(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationId 
     * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public deleteDataConversation(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).deleteDataConversation(conversationId, deleteDataConversationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {DownloadOutputNotebookOutTypeEnum} [outType] 
     * @param {string} [transform] 
     * @param {string} [runConfigId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public downloadOutputNotebook(id: string, scenarioId?: string, outType?: DownloadOutputNotebookOutTypeEnum, transform?: string, runConfigId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).downloadOutputNotebook(id, scenarioId, outType, transform, runConfigId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [projectId] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public findGroups(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).findGroups(id, name, projectId, scenarioId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateCodeRequest} generateCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public generateCode(generateCodeRequest: GenerateCodeRequest, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).generateCode(generateCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateOutputRequest} generateOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public generateOutput(generateOutputRequest: GenerateOutputRequest, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).generateOutput(generateOutputRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationId 
     * @param {string} codeHistoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public getDataConversation(conversationId: string, codeHistoryId: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).getDataConversation(conversationId, codeHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public getRecipeDetails(id: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).getRecipeDetails(id, scenarioId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public getRecipeQueue(projectId: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).getRecipeQueue(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [transform] 
     * @param {string} [runConfigId] 
     * @param {string} [projectRunEntryId] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public getRunTransformProgress(id: string, scenarioId?: string, transform?: string, runConfigId?: string, projectRunEntryId?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).getRunTransformProgress(id, scenarioId, transform, runConfigId, projectRunEntryId, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public getRunTransformVariables(id: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).getRunTransformVariables(id, testTransformDFSConfigDto, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public getSchema1(id: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).getSchema1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} historyId 
     * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public likeGeneratedOutput(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).likeGeneratedOutput(historyId, likeGeneratedOutputRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {RecipeRunData} recipeRunData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public reOrderRecipeQueue(projectId: string, recipeRunData: RecipeRunData, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).reOrderRecipeQueue(projectId, recipeRunData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} groupId 
     * @param {string} scenarioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public removeRecipeFromQueue(projectId: string, groupId: string, scenarioId: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).removeRecipeFromQueue(projectId, groupId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public runGroup(groupId: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).runGroup(groupId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {string} [scenarioId] 
     * @param {boolean} [hardStop] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public stopGroup(groupId: string, scenarioId?: string, hardStop?: boolean, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).stopGroup(groupId, scenarioId, hardStop, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {TestTransformDFSConfigDto} testTransformDFSConfigDto 
     * @param {number} [sampleRows] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public testTransformInGroup(groupId: string, testTransformDFSConfigDto: TestTransformDFSConfigDto, sampleRows?: number, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).testTransformInGroup(groupId, testTransformDFSConfigDto, sampleRows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {Array<TestTransformDFSConfigDto>} testTransformDFSConfigDto 
     * @param {string} [mode] 
     * @param {number} [sampleRows] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public testTransformInGroupV2(groupId: string, testTransformDFSConfigDto: Array<TestTransformDFSConfigDto>, mode?: string, sampleRows?: number, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).testTransformInGroupV2(groupId, testTransformDFSConfigDto, mode, sampleRows, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationId 
     * @param {UpdateDataConversationRequest} updateDataConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public updateDataConversation(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).updateDataConversation(conversationId, updateDataConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDFSRunConfigGroupDto} updateDFSRunConfigGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigGroupControllerV2Api
     */
    public updateGroup(id: string, updateDFSRunConfigGroupDto: UpdateDFSRunConfigGroupDto, options?: RawAxiosRequestConfig) {
        return DfsRunConfigGroupControllerV2ApiFp(this.configuration).updateGroup(id, updateDFSRunConfigGroupDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DownloadOutputNotebookOutTypeEnum = {
    Out: 'OUT',
    Log: 'LOG'
} as const;
export type DownloadOutputNotebookOutTypeEnum = typeof DownloadOutputNotebookOutTypeEnum[keyof typeof DownloadOutputNotebookOutTypeEnum];
