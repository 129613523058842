import React from "react";
import { IconButton } from "@material-ui/core";

// Components
import Search from "src/components/custom/Search/Search";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";
import { ToggleView } from "src/components/custom";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { listViewPages, PagesViewConfiguration } from "src/constants";

interface IProps {
  openCreateDataApp: boolean;
  isTilesView: boolean;
  onViewChange: (tilesView: boolean) => void;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  searchValue: string;
}

export const DataAppsHeader: React.FC<IProps> = (props) => {
  const { isTilesView, openCreateDataApp, onSearch, searchValue, onViewChange, onClick } = props;

  // Project context
  const { project } = useProjectContext() || {};
  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");

  const toggleView = (bool: boolean) => {
    onViewChange(bool);
    localStorage.setItem(
      PagesViewConfiguration,
      JSON.stringify({ ...pagesViewConfiguration, [listViewPages.DATAAPPS]: bool })
    );
  };

  return (
    <SubTopNavBarWrapper
      subTopNavBarLeftSection={{
        backNavPath: project?.id && `/projects/${project?.id}/canvas`,
        component: (
          <SubTopNavBarBreadcrumbs project={project} openCreateDataApp={openCreateDataApp} />
        )
      }}
      subTopNavBarRightSection={{
        component: !openCreateDataApp && (
          <>
            <Search placeholder="Search DataApps" value={searchValue} onSearch={onSearch} />
            <IconButton color="primary" size="small" onClick={onClick}>
              <PlusIcon width={28} height={28} />
            </IconButton>
            <ToggleView isPrimaryView={isTilesView} setIsPrimaryView={toggleView} />
          </>
        )
      }}
    />
  );
};
