import React, { useRef, useState } from "react";
import { isEmpty, size, toLower, toUpper } from "lodash";
import { Grid, Paper, Avatar, Typography } from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import UploadFileList, { IFile } from "./Artifacts/UploadFileList";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { areFileTypesValid } from "src/helpers/helpers";

import { toastWrapper } from "src/utils/toastWrapper";
import { useForm } from "../../../utils/useForm";
import { useStyles } from "./CreateArtifact";
import { leadingTextInPath } from "src/utils/formatText";
import { fileMimeTypesValues } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";

const initialFormValues = {
  files: []
};

type Props = {
  open: boolean;
  onClose: () => void;
  toggleShouldArtifactsRefresh: $TSFixMeFunction;
  artifactName: string;
};

const AddFilesToArtifact = ({ onClose, toggleShouldArtifactsRefresh, artifactName }: Props) => {
  const { values, handleInputChange, resetForm } = useForm(initialFormValues);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const classes = useStyles({ disabled: isLoading });
  const fileRef = useRef(null);

  const cleanErrorMessage = () => {
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !areFileTypesValid(e.target.files)) {
      setErrorMessage(`Invalid File Type(s).`);
    } else {
      setErrorMessage(null);
      const newFiles = Object.values(e.target.files).map((file: File, index: number) => ({
        name: file.name,
        size: file.size,
        fileType: file.type,
        file,
        id: `${file.name}.${file.size}${index}`
      }));
      handleInputChange({
        target: { value: [...((values as $TSFixMe).files || []), ...newFiles], name: "files" }
      });
      if (isUploadOpen) {
        setIsLoading(true);
      }
      e.target.value = "";
    }

    // Below code resets the input type file. It's needed to accept every files selection. Without the below code, the subsequent selections are omitted.
    // @ts-ignore
    fileRef.current.value = "";
  };

  const handleOnClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async (e: $TSFixMe) => {
    e?.preventDefault();
    setIsLoading(true);
    setIsUploadOpen(true);
  };

  const handleCloseModal = () => {
    setIsDirty(false);
    setShowConfirmScreen(false);
    handleOnClose();
  };
  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    }
    handleOnClose();
  };
  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  const handleChangeFiles = (finished: boolean) => {
    if (finished) {
      onClose();
      setIsUploadOpen(false);
      setIsLoading(false);
      setIsDirty(false);
      toastWrapper({
        content: `Artifact ${artifactName} updated successfully`,
        type: "success"
      });
      toggleShouldArtifactsRefresh();
    }
  };

  const handleRemoveFile = (newFiles: IFile[]) => {
    handleInputChange({
      target: {
        value: newFiles,
        name: "files"
      }
    });
  };

  const handleError = (error: any, finished: boolean, allCanceled: boolean) => {
    if (finished) {
      if (!allCanceled) {
        onClose();
        setIsUploadOpen(false);
        toggleShouldArtifactsRefresh();
      }
      setIsLoading(false);
      setIsDirty(false);
    }
    console.error("error", error);
  };

  const getFileType = (file: IFile) => {
    let type = fileMimeTypesValues?.[file?.fileType]?.displayName;
    if (!!type) {
      return type;
    }

    type = leadingTextInPath({ text: file?.name });
    if (toLower(type) === "parquet") {
      return "Parquet";
    }

    return toUpper(type);
  };

  return (
    <>
      {showConfirmScreen && isDirty && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Confirm"
          content={[
            "Do you really want to close this window?",
            "If you leave you will lose any unsaved changes."
          ]}
          onClose={handleCancelClose}
          onSubmit={handleCloseModal}
          cancelLabel="Cancel"
          submitLabel="Close"
        />
      )}

      <Modal
        open
        size="md"
        title="Add Files"
        onClose={handleCloseAttempt}
        onSubmit={handleSubmit}
        submitLabel="Add Files"
        isSubmitDisabled={(values as $TSFixMe).files.length === 0}
        isSubmitting={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ height: 379 }}>
              <Grid
                container
                alignItems="center"
                style={{ display: "flex", justifyContent: "center" }}>
                <input
                  style={{ opacity: 0, height: 164, position: "absolute" }}
                  id="files"
                  multiple
                  name="files"
                  type="file"
                  onChange={handleFilesChange}
                  onClick={cleanErrorMessage}
                  required
                  ref={fileRef}
                  disabled={isLoading}
                />
                <label htmlFor="files" className={classes.uploadComponent}>
                  {errorMessage ? (
                    <Typography className={classes.error}>{errorMessage}</Typography>
                  ) : (
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="caption" color="textPrimary">
                          Drag & drop the files or click to browse.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.uploadButton}>BROWSE FILE *</Typography>
                      </Grid>
                    </Grid>
                  )}
                </label>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" style={{ rowGap: 16 }}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="subtitle2" color="textPrimary">
                    Selected Files
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.filesCountContainer}>
                    {size((values as $TSFixMe).files)}
                  </Avatar>
                </Grid>
              </Grid>
              {(values as $TSFixMe).files?.length > 0 && (
                <Alert severity="info" style={{ width: "100%" }}>
                  <Typography variant="caption">
                    Existing files with the same name in the Artifact will be overwritten!
                  </Typography>
                </Alert>
              )}
              {!isEmpty((values as $TSFixMe).files) && (
                <UploadFileList
                  files={(values as $TSFixMe).files}
                  name={artifactName}
                  getFileType={getFileType}
                  isUploadOpen={isUploadOpen}
                  onError={handleError}
                  onChange={handleChangeFiles}
                  onRemoveFile={handleRemoveFile}
                  onClose={handleOnClose}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AddFilesToArtifact;
