import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { getTransformGroupWithRethrow, getTemplateWithRethrow } from "src/api";
import { Recipe } from "src/types";

interface IReturn {
  recipe: Recipe;
  templates: $TSFixMe;
  configIds: Array<string>;
}

export const QUERY_KEY_RECIPE = "fetch-recipe-details";
export const useGetRecipe = ({
  recipeId,
  scenarioId,
  jobRunId,
  options
}: {
  recipeId?: string | null;
  scenarioId?: string;
  jobRunId?: string;
  options?: UseQueryOptions<IReturn>;
}) => {
  return useQuery<IReturn>({
    queryKey: [QUERY_KEY_RECIPE, recipeId],
    queryFn: async () => {
      const createdGroup = await getTransformGroupWithRethrow(recipeId, scenarioId, jobRunId);
      const recipe: Recipe = Array.isArray(createdGroup) ? createdGroup[0] : createdGroup;
      const templateIds = recipe?.runConfigs?.map((config: $TSFixMe) => config.templateId) || [];
      const templates = await Promise.all(
        templateIds.map((templateId) => getTemplateWithRethrow(templateId))
      );
      const configIds = recipe?.runConfigs?.map((config) => config.id);
      return { recipe, templates, configIds };
    },
    enabled: !!recipeId,
    cacheTime: 0,
    ...options
  });
};
