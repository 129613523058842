import React, { Dispatch, SetStateAction } from "react";

// Packages
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

// Constants
import { JobConfig } from "../../utils/Jobs.constants";

type Props = {
  project: $TSFixMe;
  jobName: string;
  setJobName: Dispatch<SetStateAction<string>>;
  updateJobName: $TSFixMeFunction;
  isReadonlyJobName?: boolean;
  shouldResetJobName?: boolean | number;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, jobName, setJobName, updateJobName, isReadonlyJobName, shouldResetJobName } =
    props || {};

  const { jobId } = useParams() || {};

  const onJobNameChange = (newName: string) => {
    !!jobId ? updateJobName(newName) : setJobName(newName);
  };

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="projects" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="jobs" to={`/projects/${project?.id}/jobs`}>
        <RenderText>Jobs</RenderText>
      </Link>
    ) : (
      <RenderText key="jobs" color="textSecondary">
        Jobs
      </RenderText>
    )
  );

  breadcrumbs.push(
    <EditName
      key="editJobName"
      fieldNameLabel="job name"
      inputValue={jobName}
      onSave={onJobNameChange}
      isResetName={shouldResetJobName}
      isLoading={!!isReadonlyJobName}
      containerProps={{
        style: {
          width: ((jobName || "")?.length + 1) * (JobConfig.JobNameFontSize - 4) + 16
        }
      }}
      data-testid="jobBreadcrumbJobName"
    />
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
