import React, { useMemo, useState } from "react";

// Packages
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import shallow from "zustand/shallow";
import clsx from "clsx";
import { toast } from "react-toastify";
import { delay, get, isEmpty, size } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Icons
import { AddRunIcon, EyeIcon, LogsIcon } from "src/icons/NewUX";
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { isRecipeRunInQueue as isRecipeRunInQueueHelper } from "src/pages/private/ProjectsModule/utils";

// Hooks
import { clearRunTransformGroupCache, UseGetProjectCanvasQueryKeys } from "src/hooks/api";
import { useAddRecipeToQueue, UseGetRecipeRunsQueueQueryKeys } from "src/hooks/api/recipes";
import useTransformGroup from "hooks/api/transforms/useTransformGroup";

// APIs
import { runTransformGroupWithRethrow } from "src/api";

// Stores
import { useCanvasStore } from "src/store/store";

// Components
import RecipeLogsDrawer from "pages/Projects/common/ShowLogsModal/RecipeLogsDrawer";
import { useTransformErrorUtils } from "src/pages/Projects/common/hooks/useTransformErrorUtils";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import NodeActionWrapper from "../NodeActionWrapper";

// Constants
import { newTheme as theme } from "src/styles";
import {
  RecipeTypes,
  RecipeStatuses,
  RecipeTypesPathMapping
} from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";
import StopCircleOutlined from "src/icons/StopCircleOutlined";
import useStopRecipe from "../../../hooks/useStopRecipe";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { Grid, Typography } from "@material-ui/core";

type Props = {
  open: boolean;
  closeContextMenu: $TSFixMeFunction;
  isDefaultScenario: boolean;
  setIsRunRequestPending: $TSFixMeFunction;
  setReloadTriggerWithDelay: $TSFixMeFunction;
  data: NodeData;
  children?: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& button": {
      minWidth: theme.spacing(4),
      "& svg": {
        color: theme.palette.common.white
      },
      "&.disabled": {
        opacity: 0.5,
        cursor: "not-allowed"
      }
    },
    "& .borderLeft": {
      borderLeft: "1px solid #ffffff4d",
      borderRadius: "initial"
    }
  }
}));

const ContextMenu = (props: Props) => {
  const {
    open = false,
    closeContextMenu,
    isDefaultScenario,
    setIsRunRequestPending,
    setReloadTriggerWithDelay,
    data,
    children
  } = props || {};

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { fetchAndRenderFullErrorLogs } = useTransformErrorUtils();
  const stopRecipe = useStopRecipe();
  const [stopRecipeModalOpen, setStopRecipeModalOpen] = useState(false);

  const classes = useStyles();

  const [
    isRecipesRunningAcrossScenariosStore,
    isPendingRecipeRunsInQueueStore,
    pendingRecipeRunsInQueueStore
  ] = useCanvasStore(
    (state: $TSFixMe) => [
      state.isRecipesRunningAcrossScenarios,
      state.isPendingRecipeRunsInQueue,
      state.pendingRecipeRunsInQueue
    ],
    shallow
  );

  // States - STARTS >>
  const [isRunning, setIsRunning] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLogs, setIsLogs] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Queries
  const { isLoading, data: recipe } = useTransformGroup(
    data?.id,
    data?.scenarioId,
    data?.jobProps?.jobRunId,
    {
      cacheTime: Infinity,
      refetchOnMount: true,
      enabled: !!open && areAllKeysPresentAndNotNil(data, ["scenarioId", "id"])
    }
  );

  // Mutations
  const {
    isLoading: isAddingRecipeToQueue,
    mutateAsync: addRecipeToQueueMutation,
    reset: resetAddRecipeToQueueMutation
  } = useAddRecipeToQueue();
  // << ENDS - Query hooks

  const handleCancel = () => {
    setStopRecipeModalOpen(false);
  };

  const onView = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    const recipeType = get(RecipeTypesPathMapping, data?.recipeType || RecipeTypes.Template);
    let path = `/projects/${data?.projectId}/scenario/${data?.scenarioId}/${recipeType}/${data?.id}`;

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = `/projects/${data?.projectId}`;
      path = `${path}/jobs/${data?.jobProps?.jobId}`;
      path = `${path}/scenario/${data?.scenarioId}`;
      path = `${path}/job-runs/${data?.jobProps?.jobRunId}`;
      path = `${path}/recipes/${recipeType}/${data?.id}`;

      data?.jobProps?.jobPathsSetter(
        `/projects/${data?.projectId}/jobs/${data?.jobProps?.jobId}/job-runs/${data?.jobProps?.jobRunId}/job-canvas`
      );
    }

    !!path && navigate(path);
  };

  const addRecipeToQueue = async () => {
    await resetAddRecipeToQueueMutation();
    await addRecipeToQueueMutation(
      { scenarioId: data?.scenarioId, id: data?.id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);
          await queryClient.invalidateQueries([UseGetProjectCanvasQueryKeys.ProjectCanvas]);

          toastWrapper({
            type: ToastTypes.Info,
            content: `Recipe ${data?.label} added to queue successfully!`
          });
        },
        onError: () => {
          toastWrapper({
            type: ToastTypes.Error,
            content: `Error while adding Recipe ${data?.label} to queue!`
          });
        },
        onSettled: () => {
          closeContextMenu();
        }
      }
    );
  };

  const runRecipe = async () => {
    if (!areAllKeysPresentAndNotNil(data, ["scenarioId", "id"])) {
      return;
    }

    !!data?.projectId && checkEnvRelaunch(data?.projectId);

    if (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore) {
      await addRecipeToQueue();
      return;
    }

    setIsRunning(() => true);
    setIsRunRequestPending(true);

    const toastId = toastWrapper({
      content: "View the recipe run log",
      actions: [
        {
          action: () => {
            setIsLogs(true);
          },
          label: "View Log"
        }
      ],
      type: ToastTypes.Info
    });

    try {
      setReloadTriggerWithDelay();

      delay(
        () => queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]),
        1000
      );

      await runTransformGroupWithRethrow(data?.id, data?.scenarioId, undefined, false);
      await clearRunTransformGroupCache(queryClient);
      queryClient.invalidateQueries({
        queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas]
      });
    } catch (e) {
      toast.dismiss(toastId);

      fetchAndRenderFullErrorLogs(e, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId,
        groupId: data?.id
      });
    } finally {
      setIsRunning(() => false);
      setIsRunRequestPending(false);
      closeContextMenu();
    }
  };

  const stopRunningRecipe = () => {
    stopRecipe.mutate(
      { groupId: data?.id, scenarioId: data?.scenarioId },
      {
        onSuccess: () => {
          setStopRecipeModalOpen(false);
          toastWrapper({ type: "info", content: "Recipe stop action is completed" });
          clearRunTransformGroupCache(queryClient);
          queryClient.invalidateQueries({
            queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas]
          });
        }
      }
    );
  };

  const isRecipeRunInQueue = useMemo(
    () =>
      isRecipeRunInQueueHelper({
        pendingRecipeRunsInQueue: pendingRecipeRunsInQueueStore,
        recipeId: data?.id,
        scenarioId: data?.scenarioId
      }),
    [pendingRecipeRunsInQueueStore, data?.id, data?.scenarioId]
  );

  const isRecipeRunDisabled = useMemo(
    () =>
      isRunning ||
      isAddingRecipeToQueue ||
      data?.status === RecipeStatuses.Running ||
      isRecipeRunInQueue ||
      size(recipe?.runConfigs) === 0,
    [isRunning, isAddingRecipeToQueue, data?.status, isRecipeRunInQueue, recipe?.runConfigs]
  );

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const isAddRecipeToQueue = useMemo(
    () =>
      data?.status !== RecipeStatuses.Running &&
      (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore),
    [data?.status, isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore]
  );

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    items.push(
      <NodeActionWrapper key="view" title="View">
        <IconButton size="small" onClick={onView}>
          <EyeIcon viewBox="0 0 20 20" />
        </IconButton>
      </NodeActionWrapper>
    );

    if (!data?.isJobCanvas) {
      items.push(
        <ButtonGroup
          key="buttongroup"
          variant="text"
          size="small"
          style={{ minWidth: "28px" }}
          className="borderLeft">
          {!!isLoading ? (
            <IconButton size="small" disabled>
              <CircularProgress size={theme.spacing(1.75)} />
            </IconButton>
          ) : (
            <NodeActionWrapper
              title={
                size(recipe?.runConfigs) === 0
                  ? "No transformations in Recipe!"
                  : data?.status === RecipeStatuses.Running
                    ? "Recipe Run is in progress..."
                    : isRecipeRunInQueue
                      ? "Recipe is already in queue!"
                      : !!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore
                        ? "Add to Queue"
                        : "Run Recipe"
              }>
              <IconButton
                size="small"
                style={{ minWidth: "24px" }}
                className={clsx({ disabled: isRecipeRunDisabled })}
                disabled={isRecipeRunDisabled}
                onClick={runRecipe}>
                {isAddRecipeToQueue ? (
                  <AddRunIcon width={18} height={16} viewBox="1 2 24 20" />
                ) : (
                  <RunIcon width={14} viewBox="0 1 16 16" />
                )}
              </IconButton>
            </NodeActionWrapper>
          )}

          {!isLoading && data?.status === RecipeStatuses.Running && (
            <NodeActionWrapper title={"Stop Recipe Run"}>
              <IconButton
                size="small"
                style={{ minWidth: "24px" }}
                className={clsx({ disabled: data?.status !== RecipeStatuses.Running })}
                disabled={stopRecipe.isLoading}
                onClick={() => {
                  setStopRecipeModalOpen(true);
                  closeContextMenu();
                }}>
                <StopCircleOutlined fill={stopRecipe.isLoading ? "grey" : undefined} />
              </IconButton>
            </NodeActionWrapper>
          )}
        </ButtonGroup>
      );

      items.push(
        <ButtonGroup
          key="buttongroup2"
          variant="text"
          size="small"
          style={{ minWidth: "auto" }}
          className="borderLeft">
          <NodeActionWrapper title="View Logs">
            <IconButton
              size="small"
              onClick={() => {
                setIsLogs(true);
              }}>
              <LogsIcon viewBox="0 -2 24 24" />
            </IconButton>
          </NodeActionWrapper>
        </ButtonGroup>
      );

      if (!!isDefaultScenario) {
        items.push(
          <NodeActionWrapper key="delete" title="Delete">
            <IconButton size="small" className="borderLeft" onClick={onDelete}>
              <TrashIcon viewBox="0 0 20 22" />
            </IconButton>
          </NodeActionWrapper>
        );
      }
    }

    return items;
  }, [
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    data?.status,
    isDefaultScenario,
    isLoading,
    isRecipeRunDisabled,
    recipe?.runConfigs,
    isRecipesRunningAcrossScenariosStore,
    isAddRecipeToQueue,
    isPendingRecipeRunsInQueueStore,
    stopRecipe.isLoading
  ]);

  return (
    <>
      {!!isLogs && (
        <RecipeLogsDrawer
          open
          name={data?.displayName || data?.name || ""}
          projectId={data?.projectId}
          scenarioId={data?.scenarioId}
          jobRunId={data?.jobProps?.jobRunId}
          groupId={data?.id}
          isJobPath={!!data?.jobProps}
          onClose={() => {
            setIsLogs(false);
            closeContextMenu();
          }}
        />
      )}

      {!!stopRecipeModalOpen && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title={
            <Grid direction="column" container>
              <Typography>Stop Recipe Run</Typography>
              <span style={{ fontSize: "12px", fontStyle: "italic", color: "grey" }}>
                This process may take some time to get completed.
              </span>
            </Grid>
          }
          content={[
            "This action updates the recipe status to Unbuilt, shuts down the related project environment, and restarts it. This may cause any other recipes depending on this environment to fail.",
            <>
              <b>Note:</b> If recipe execution gets completed before associated environment restart
              then the status of the recipe will be based on the run result.
            </>,
            "Are you sure you want to proceed with this?"
          ]}
          submitLabel="Yes, Proceed"
          onClose={handleCancel}
          isSubmitting={stopRecipe.isLoading}
          onSubmit={stopRunningRecipe}
        />
      )}

      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="group"
          deleteNote={
            <div>
              {data?.recipeType === RecipeTypes.RapidModel ? (
                <span>Note: Deleting this might impact associated DataApps (if any).</span>
              ) : (
                <span>Note: </span>
              )}
              {psMessage}
            </div>
          }
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
