import React, { useMemo, useState, useEffect } from "react";
import { Chip, Grid, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { includes } from "lodash";
import { toast } from "react-toastify";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import DeleteProjectRunModal from "./components/DeletePredictionRunModal";
import ModelDatasetSelector from "./components/ModelDatasetSelector";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import PredictionJobDefault from "./components/PredictionJobDefault";
import PredictionJobLogsDrawer from "./components/PredictionJobLogs/PredictionJobLogsDrawer";
import PredictionJobOutput from "./components/PredictionJobOutput";
import SubTopNavBarBreadcrumbs from "./components/SubTopNavbarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import useGetModelParentCanvas from "hooks/api/projects/useGetModelParentCanvas";
import useManualRunPrediction from "hooks/api/projects/useManualRunPrediction";
import useRunDetails from "hooks/api/projects/useRunDetails";
import useUpdateProjectRun from "hooks/api/projects/useUpdateProjectRun";
import { IDeleteRecord } from "./components/PredictionRunsTable";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { JobRunStatuses, jobRunStatusesNames } from "../Jobs/utils/Jobs.constants";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { Spinner } from "src/components";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { getStatusEquivalence } from "pages/Projects/common/ShowLogsModal/ShowLogsDrawerTitle";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { PredictionJobHelperText } from "./utils/PredictionJob.constants";
import { useProjectContext } from "../../context/useProjectContext";
import { useProjectsStore } from "src/store/store";
import { useStyles as useChipStyles } from "pages/Projects/common/ShowLogsModal/ShowLogsDrawerTitle";

const useStyles = makeStyles({
  leftItem: {
    background: "#fff",
    borderRadius: "4px",
    padding: "16px 16px !important",
    width: "350px",
    overflow: "hidden",
    boxShadow: "0px 4px 4px 0px #B2B2B240"
  },
  container: {
    marginTop: 0,
    maxHeight: "inherit",
    marginLeft: 0,
    maxWidth: "100%",
    minHeight: "calc(100vh - 112px)"
  },
  root: {
    margin: "10px 0px 0px 16px",
    maxHeight: "calc(100vh - 112px)"
  },
  rightItem: {
    paddingTop: 0,
    maxWidth: "calc(100vw - 382px)",
    maxHeight: "inherit",
    padding: "0px 0px 0px 12px !important"
  },
  flexCenter: {
    display: "flex",
    gap: "12px",
    alignItems: "center"
  }
});

const PredictionJobRun: React.FC = () => {
  const [deleteRecord, setDeleteRecord] = useState<IDeleteRecord | null>(null);
  const [predictionJobName, setPredictionJobName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);

  const [open, setOpen] = useState(false);
  const { project } = useProjectContext();
  const { projectId, projectRunId } = useParams();
  const { leftItem, container, root, rightItem, flexCenter } = useStyles();
  const classes = useChipStyles();

  const [runningPredictionJobIds] = useProjectsStore((state) => [state.runningPredictionJobIds]);

  const navigate = useNavigate();
  const updateRun = useUpdateProjectRun();
  const prediction = useManualRunPrediction();
  const { data, isLoading } = useRunDetails(projectRunId);
  const [model, setModel] = useState({
    id: data?.modelEntityId ?? "",
    name: "",
    targetCol: ""
  });
  const modelResponse = useGetModelParentCanvas(projectId, model.id);

  useEffect(() => {
    if (isOpenLogs) {
      setOpen(true);
    }
  }, [isOpenLogs]);

  useEffect(() => {
    if (data?.predictionJobName) {
      setPredictionJobName(data.predictionJobName);
    }
    if (data?.modelEntityId) {
      setModel({ ...model, id: data?.modelEntityId ?? "" });
    }
  }, [data?.predictionJobName, data?.modelEntityId]);

  const generatingPrediction = useMemo(
    () => prediction.isLoading || includes(runningPredictionJobIds, projectRunId),
    [prediction.isLoading, runningPredictionJobIds, projectRunId]
  );

  const handleUpdate = (name: string) => {
    if (!data?.runDetails?.id || !name) {
      return;
    }

    setPredictionJobName(name);
    updateRun.mutate(
      { id: data?.runDetails?.id, name },
      {
        onSuccess: () =>
          toastWrapper({
            type: ToastTypes.Success,
            content: PredictionJobHelperText.PredictionJobNameUpdated
          }),
        onError: () => setPredictionJobName(predictionJobName)
      }
    );
  };

  if (!projectRunId || !projectId) {
    return null;
  }

  const handlePredict = (modelName: string, futurePeriod?: string) => {
    checkEnvRelaunch(projectId);

    const toastId = toast(
      <div className={flexCenter}>
        <div>
          <RunIcon width={32} height={32} viewBox="0 0 16 16" color="#4646B5" />
        </div>
        <div>
          {
            "Prediction is in progress. This action might take time. Feel free to navigate to other pages and we shall update you once the output is ready"
          }
        </div>
      </div>,
      {
        type: "info",
        className: "toastify-info",
        autoClose: 10000
      }
    );
    prediction.mutate(
      {
        projectRunId,
        async: true,
        name: predictionJobName,
        data,
        modelName,
        futurePeriod
      },
      {
        onSuccess: () => toast.dismiss(toastId),
        onError: () => toast.dismiss(toastId)
      }
    );
  };

  const handleDelete = () => {
    setDeleteRecord({ id: projectRunId, name: data?.predictionJobName! });
  };

  const handleSuccess = () => {
    navigate(-1);
    setDeleteRecord(null);
  };

  const handleLogsClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchParams({});
    setOpen(false);
  };

  const disableLogs = !data?.status || data?.status === JobRunStatuses.Created;
  const disabled = !(
    data?.lastRunEntryId &&
    (data.status === JobRunStatuses.Success || data?.status === JobRunStatuses.SuccessWithWarn)
  );

  return (
    <NewThemeWrapper>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: `/projects/${projectId ?? project?.id}/prediction-job`,
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              predictionJobName={predictionJobName}
              onUpdate={handleUpdate}
            />
          )
        }}
        subTopNavBarRightSection={{
          moreOptions: [
            {
              label: "Delete",
              icon: <TrashIcon viewBox="0 0 20 22" />,
              action: handleDelete
            }
          ],
          component: (
            <>
              <Tooltip
                title={
                  !disableLogs
                    ? ""
                    : generatingPrediction
                      ? "Kindly wait for the run to start before checking the logs."
                      : "Kindly run the prediction before checking the logs"
                }>
                <div>
                  <IconButton
                    disabled={disableLogs}
                    data-testid="prediction-logs"
                    onClick={handleLogsClick}>
                    <LogsNewIconV2
                      // New UX change
                      // The static value will be replaced with new theme at platform-level.
                      color={disableLogs ? "#0000001f" : "#4646b5"}
                    />
                  </IconButton>
                </div>
              </Tooltip>
              {data?.status && (
                <Chip
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  className={classes[getStatusEquivalence(data?.status)]}
                  label={
                    <Typography variant="caption">{jobRunStatusesNames[data?.status]}</Typography>
                  }
                />
              )}
            </>
          )
        }}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <Grid item xs={12}>
          <div style={{ overflowY: "hidden" }}>
            <div className={root}>
              <Grid container spacing={3} className={container}>
                <Grid
                  item
                  xs="auto"
                  style={{ maxHeight: "inherit", overflowY: "auto" }}
                  className={leftItem}>
                  <ModelDatasetSelector
                    loading={generatingPrediction}
                    projectId={projectId}
                    disabled={!disabled || generatingPrediction}
                    model={model}
                    modelResponse={modelResponse}
                    projectRunId={projectRunId}
                    onModelChange={setModel}
                    onPredict={handlePredict}
                  />
                </Grid>
                <Grid item xs className={rightItem}>
                  {disabled ? (
                    <PredictionJobDefault
                      data-testid="predict-job-default-screen"
                      loading={generatingPrediction}
                    />
                  ) : (
                    <PredictionJobOutput
                      predictionJobName={predictionJobName}
                      data={data}
                      projectRunId={projectRunId}
                      projectId={projectId}
                      disabled={disabled}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}
      {deleteRecord && (
        <DeleteProjectRunModal
          name={deleteRecord.name}
          id={deleteRecord.id}
          open={!!deleteRecord}
          onCancel={() => setDeleteRecord(null)}
          onSuccess={handleSuccess}
        />
      )}
      {open && data?.lastRunEntryId && (
        <PredictionJobLogsDrawer
          open={open}
          projectId={projectId}
          logRunDetails={{
            runId: projectRunId,
            runEntryId: data?.lastRunEntryId,
            recipeId: data?.recipeId,
            recipeName: data?.recipeName,
            errorMsg: data?.errorMsg,
            jobName: predictionJobName
          }}
          onClose={handleClose}
        />
      )}
    </NewThemeWrapper>
  );
};

export default PredictionJobRun;
