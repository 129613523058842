import React, { useMemo, PropsWithChildren } from "react";

// Packages
import clsx from "clsx";
import { includes, isArray, isString } from "lodash";

// MUI
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

// Icons
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

// Components
import AskAiA11yBackdrop from "../AskAiA11yBackdrop/AskAiA11yBackdrop";

// Styles
import useStyles from "./Modal.styles";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

export enum ModalVariants {
  Delete = "delete"
}

enum ScrollToOptions {
  Paper = "paper",
  Body = "body"
}

type TooltipPlacements =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

type CustomTooltipProps = {
  placement: TooltipPlacements;
};

type Props = {
  variant?: string;
  size?: Breakpoint;
  open: boolean;
  askAiA11yBackdrop?: boolean;
  title?: string | React.ReactNode;
  content?: string | React.ReactNode | (string | React.ReactNode)[];
  cancelLabel?: string | React.ReactNode;
  submitLabel?: string | React.ReactNode;
  hideCloseIcon?: boolean;
  hideCancelAction?: boolean;
  hideSubmitAction?: boolean;
  isCancelDisabled?: boolean;
  isSubmitDisabled?: boolean;
  cancelActionInfo?: string;
  submitActionInfo?: string;
  // Usage of TooltipPros from MUI mandates to define children, title in subscribing components itself.
  // Hence, created custom type to add only required props.
  cancelActionTooltipProps?: CustomTooltipProps;
  submitActionTooltipProps?: CustomTooltipProps;
  isSubmitting?: boolean;
  onClose?: $TSFixMeFunction;
  onSubmit?: $TSFixMeFunction;
  PaperProps?: any;
  background?: string;
  isNewTheme?: boolean;
};

const Modal = (props: PropsWithChildren<Props>) => {
  const {
    variant,
    size = "sm",
    open = false, // $FixMe: Scope to be refactored. Relook at the ternary operator across the platform based on keepMounted flag
    askAiA11yBackdrop = false,
    title,
    content,
    cancelLabel,
    submitLabel,
    hideCloseIcon = false,
    hideCancelAction = false,
    hideSubmitAction = false,
    isCancelDisabled = false,
    isSubmitDisabled = false,
    cancelActionInfo = "",
    submitActionInfo = "",
    cancelActionTooltipProps = {},
    submitActionTooltipProps = {},
    isSubmitting = false,
    onClose: onModalClose,
    onSubmit: onModalSubmit,
    children,
    background,
    isNewTheme = false,
    ...rest
  } = props || {};

  const classes = useStyles({ background });

  const onClose = (event?: any, reason?: string) => {
    onModalClose?.(event, reason);
  };

  const onSubmit = () => {
    onModalSubmit?.();
  };

  const isDeleteModal = useMemo(() => includes([ModalVariants.Delete], variant), [variant]);

  const isModalHeader = useMemo(() => {
    if (!!title || !hideCloseIcon) {
      return true;
    }

    if (includes([ModalVariants.Delete], variant)) {
      return false;
    }

    return false;
  }, [variant, title, hideCloseIcon]);

  const isModalFooter = useMemo(() => {
    if (includes([ModalVariants.Delete], variant)) {
      return true;
    }

    if (!hideCancelAction || !hideSubmitAction) {
      return true;
    }

    return false;
  }, [variant, hideCancelAction, hideSubmitAction]);

  const footer = (
    <DialogActions className={classes.actions} data-testid="modalActions">
      {!hideCancelAction && (
        <Tooltip title={cancelActionInfo} {...cancelActionTooltipProps}>
          <span>
            <Button
              color="primary"
              variant="outlined"
              disabled={isCancelDisabled || isSubmitting}
              onClick={onClose}
              data-testid="modalCancelAction">
              {cancelLabel ?? (isDeleteModal ? "No" : "Cancel")}
            </Button>
          </span>
        </Tooltip>
      )}
      {!hideSubmitAction && (
        <Tooltip title={submitActionInfo} {...submitActionTooltipProps}>
          <span>
            <Button
              variant="contained"
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size={16} /> : undefined}
              disabled={isSubmitDisabled || isSubmitting}
              onClick={onSubmit}
              data-testid="modalSubmitAction">
              {submitLabel ?? (isDeleteModal ? "Yes" : "Submit")}
            </Button>
          </span>
        </Tooltip>
      )}
    </DialogActions>
  );
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={size}
      onClose={onClose}
      scroll={ScrollToOptions.Paper}
      {...rest}>
      {isModalHeader && (
        <DialogTitle className={classes.title} data-testid="modalTitle">
          {!!title &&
            (isString(title) ? (
              <Typography className={classes.titleText} variant="h6">
                {title}
              </Typography>
            ) : (
              title
            ))}
          {!hideCloseIcon && (
            <IconButton
              color="primary"
              size="small"
              onClick={onClose}
              className={classes.closeIcon}>
              <CloseRoundedIcon opacity={0.5} />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <div style={{ position: "relative" }}>
        {!!askAiA11yBackdrop && <AskAiA11yBackdrop size="small" />}

        <DialogContent
          dividers={!isDeleteModal}
          className={clsx({
            [classes.dialogueContentBackground]: !isDeleteModal
          })}
          data-testid="modalContent">
          <DialogContentText component="div" tabIndex={-1} data-testid="modalContentText">
            {isDeleteModal ? (
              <Box display="flex" gridGap={16}>
                <ReportProblemOutlinedIcon fontSize="large" />
                <Box display="flex" flexDirection="column" gridGap={16}>
                  {isArray(content)
                    ? content?.map((line, index) => (
                        <Typography
                          style={{ overflowWrap: "anywhere" }}
                          component="div"
                          key={`line-${index}`}
                          variant="body2">
                          {line}
                        </Typography>
                      ))
                    : content}
                </Box>
              </Box>
            ) : (
              children || content
            )}
          </DialogContentText>
        </DialogContent>
        {isModalFooter && (isNewTheme ? <NewThemeWrapper>{footer}</NewThemeWrapper> : footer)}
      </div>
    </Dialog>
  );
};

export default Modal;
