import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid, InputAdornment, MenuItem, Typography, makeStyles } from "@material-ui/core";

import Text from "src/components/Widget/Text";
import { Field } from "../../../../../components";

import {
  EnvironmentType,
  EnvironmentsTypes
} from "../../../../../constants/environments.constants";
import styles from "../../Environments.module.scss";
import EnvironmentTypeConfig from "../EnvironmentTypeConfig";

import {
  getEnvironmentConfigSession,
  setEnvironmentConfigSession
} from "../../utils/environments.helpers";

import _, { toLower } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 142px)",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 12,
    rowGap: theme.spacing(2.5)
  },
  dropdown: {
    maxHeight: 200
  }
}));

type Props = {
  isEnvironmentsTypesFetching: boolean;
  environmentsTypes: $TSFixMe[];
  nameError: string;
  setNameError: Function;
  readonly?: boolean;
  setTimer?: $TSFixMe;
  customConfigErrors: string[];
  setCustomConfigErrors: Dispatch<SetStateAction<string[]>>;
};

export const EnvironmentInputs = ({
  isEnvironmentsTypesFetching,
  environmentsTypes,
  setTimer,

  customConfigErrors
}: Props) => {
  const classes = useStyles();

  const [typeOption, setTypeOption] = useState();
  const [values, setValues] = useState(getEnvironmentConfigSession() || {});

  useEffect(() => setEnvironmentConfigSession({ ...values }), [values]);

  const handleChange = (event: $TSFixMe, id?: string) => {
    const { name, value } = event?.target;

    const thisValues = { ...(getEnvironmentConfigSession() || {}) };

    if (id === "envType" && event?.target?.value !== EnvironmentsTypes.Custom) {
      thisValues["envType"] = value;
      thisValues["cores"] = "";
      thisValues["memory"] = "";
      thisValues["diskSpace"] = "";
    } else {
      setValues({
        ...values,
        [name]: value
      });

      thisValues[name] = value;
    }

    setValues(() => ({ ...thisValues }));

    setEnvironmentConfigSession({ ...thisValues });

    setTimer(() => new Date().getTime());
  };

  useEffect(() => {
    if (!isEnvironmentsTypesFetching && values) {
      setTypeOption(
        (environmentsTypes || [])?.find(
          (env: EnvironmentType) => env.name.toLowerCase() === toLower(values.envType)
        )
      );
    }
  }, [isEnvironmentsTypesFetching, environmentsTypes, values]);

  return (
    <Grid container alignContent="flex-start" className={classes.root}>
      <Grid item xs={12}>
        <Field
          id="description"
          label="Environment Description"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleChange}
          multiline
          minRows={4}
          maxRows={4}
          value={getEnvironmentConfigSession().description}
        />
        <Typography variant="caption" className={styles.formTypographyBody2}>
          Provide additional information about this environment.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          id="envType"
          label="Environment Type"
          variant="outlined"
          size="small"
          fullWidth
          required
          select
          onChange={(event: $TSFixMe) => {
            handleChange(event, "envType");
          }}
          minRows={4}
          maxRows={4}
          value={getEnvironmentConfigSession().envType}
          SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
          {(environmentsTypes || [])
            // ?.filter((thisEnvType: $TSFixMe) => thisEnvType?.name !== EnvironmentsTypes.Custom)
            ?.map((typeOption: EnvironmentType) => {
              return (
                <MenuItem
                  key={typeOption.name}
                  value={typeOption.name}
                  //@ts-expect-error
                  onClick={() => setTypeOption(typeOption)}>
                  <Text value={typeOption.name} />
                </MenuItem>
              );
            })}
        </Field>
        {getEnvironmentConfigSession().envType !== EnvironmentsTypes.Custom && typeOption ? (
          <Typography variant="caption" className={styles.formTypographyBody2}>
            <EnvironmentTypeConfig
              cores={(typeOption as EnvironmentType).cores}
              memInMbs={(typeOption as EnvironmentType).memInMbs}
              diskInGbs={(typeOption as EnvironmentType).diskInGbs}
            />
          </Typography>
        ) : null}
      </Grid>
      {getEnvironmentConfigSession().envType === EnvironmentsTypes.Custom && (
        <>
          <Grid container justifyContent="space-between">
            <Grid item style={{ width: "47.5%" }}>
              <Field
                id="cores"
                label="Cores"
                value={getEnvironmentConfigSession().cores}
                onChange={(event: $TSFixMe) => {
                  handleChange(event, "cores");
                }}
                error={!!customConfigErrors[0]}
                helperText={customConfigErrors[0]}
                type="number"
                required
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item style={{ width: "47.5%" }}>
              <Field
                id="memory"
                label="Memory"
                value={getEnvironmentConfigSession().memory}
                onChange={(event: $TSFixMe) => {
                  handleChange(event, "memory");
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">GB</InputAdornment>
                }}
                error={!!customConfigErrors[1]}
                helperText={customConfigErrors[1]}
                type="number"
                required
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              id="diskSpace"
              label="Disk Space"
              value={getEnvironmentConfigSession().diskSpace}
              onChange={(event: $TSFixMe) => {
                handleChange(event, "diskSpace");
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">GB</InputAdornment>
              }}
              error={!!customConfigErrors[2]}
              helperText={customConfigErrors[2]}
              type="number"
              required
              variant="outlined"
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
