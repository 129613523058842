import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import shallow from "zustand/shallow";

import { useCanvasStore } from "src/store/store";
import {
  useSaveCodeRecipeMutation,
  useUpdateTransformGroupMutation,
  useTestTransformGroupV2Mutation,
  useRunTransformGroupMutation,
  useDeleteTransformMutation,
  useSaveCodeRecipeMutationV2,
  useAddAIThreadInputsMutation,
  useRemoveAIThreadInputsMutation,
  AI_GUIDE_THREADS_KEY
} from "src/hooks/api";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { useAddRecipeToQueue, UseGetRecipeRunsQueueQueryKeys } from "src/hooks/api/recipes";

import { handleResponse } from "src/utils/apiService";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { CodeRecipeContext } from "./CodeRecipeContext";
import { useTransformErrorUtils } from "../../common/hooks/useTransformErrorUtils";
import { RecipeStatuses } from "src/constants";
import { usePollRecipe } from "src/hooks/api/transforms/usePollRecipe";
import { isRecipeRunInQueue as isRecipeRunInQueueHelper } from "src/pages/private/ProjectsModule/utils";
import { useCodeRecipeApis } from "./useCodeRecipeApis";

import {
  type Scenario,
  type Entity,
  type Entities,
  type EntityFeaturesResponse,
  Recipe,
  AskAIResponse
} from "src/types";
import { InputEntityDtoInputEntityTypeEnum } from "openapi/Models/input-entity-dto";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { ThreadResponseDto } from "openapi/Models";

export enum OUTPUT_TYPE {
  DATASET = "DATASET",
  CHART = "CHART",
  TEXT = "TEXT",
  AUTO_INFER = "AUTO_INFER",
  PROMPT_SUGGESTIONS = "PROMPT_SUGGESTIONS",
  MODEL = "MODEL"
}

export type AskAIChatResponseType = AskAIResponse | AIChatResponseDto;

export const CodeRecipeContextProvider = ({
  templates: initialTemplates,
  recipe: initialRecipe,
  outputs: initialOutputs,
  isLoading,
  children
}: $TSFixMe) => {
  const { projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const queryClient = useQueryClient();

  // Stores - STARTS >>
  const [
    isRecipesRunningAcrossScenariosStore,
    isPendingRecipeRunsInQueueStore,
    pendingRecipeRunsInQueueStore
  ] = useCanvasStore(
    (state: $TSFixMe) => [
      state.isRecipesRunningAcrossScenarios,
      state.isPendingRecipeRunsInQueue,
      state.pendingRecipeRunsInQueue
    ],
    shallow
  );
  // << ENDS - Stores

  const [recipe, setRecipe] = React.useState<Recipe | null>(initialRecipe || null);
  const [recipeName, setRecipeName] = React.useState<string>(recipe?.name || "");
  const [scenarioData, setScenarioData] = React.useState<null | Scenario>(null);
  const [inputDatasets, setInputDatasets] = React.useState<Array<Entity>>([]);
  const [isOutputTypeChanged, setisOutputTypeChanged] = React.useState(false);
  const [entityFeaturesMap, setEntityFeaturesMap] = React.useState<{
    [id: string]: EntityFeaturesResponse;
  } | null>(null);

  const [templates, setTemplates] = React.useState<$TSFixMe[]>(initialTemplates || []);
  const [editorValue, setEditorValue] = React.useState(
    templates?.map((template: any) => template.code)?.join("\r\n\n")
  );
  const [previewTabs, setPreviewTabs] = React.useState<$TSFixMe>([]);
  const [allEntities, setAllEntities] = React.useState<Array<Entity>>([]);
  const [inputNames, setInputNames] = React.useState<string[]>([]);
  const [pinnedNames, setPinnedNames] = React.useState<string[]>([]);
  const [outputType, setOutputType] = React.useState<OUTPUT_TYPE>(OUTPUT_TYPE.AUTO_INFER);
  const [isRetryInProgress, setIsRetryInProgress] = React.useState<boolean>(false);

  useEffect(() => {
    initialRecipe && setRecipe(initialRecipe);
    setTemplates(initialTemplates);
    setEditorValue(initialTemplates?.map((template: any) => template.code)?.join("\r\n\n"));
  }, [initialRecipe]);

  const [isTestInProgress, setIsTestInProgress] = React.useState(false);
  const [generateQueryLimit, setGenerateQueryLimit] = React.useState<number | undefined>(undefined);
  const [isSelectedEntitiesUpdateInProgess, setIsSelectedEntitiesUpdateInProgess] =
    React.useState<boolean>(false);
  const [responses, setResponses] = React.useState<Array<AskAIChatResponseType>>([]);
  const [codeErrorDetails, setCodeErrorDetails] = React.useState<{
    lineNo: number;
    line: string;
  } | null>(null);

  const setReloadTriggerWithDelay = useCanvasStore((state) => state.setReloadTriggerWithDelay);

  const outputs = React.useMemo(
    () => (initialOutputs?.length > 0 ? initialOutputs : ["sample-output"]),
    [initialOutputs]
  );

  const onUpdateRecipe = (recipe: Recipe) => {
    setRecipeName(recipe?.name);
    setRecipe(recipe);
  };

  const updateTransformGroupMutation = useUpdateTransformGroupMutation();
  const saveCodeRecipeMutation = useSaveCodeRecipeMutation();
  const saveAndTestCodeRecipeMutation = useSaveCodeRecipeMutation();
  const testTransformGroupMutation = useTestTransformGroupV2Mutation();
  const runTransformGroupMutation = useRunTransformGroupMutation();
  const saveCodeRecipeMutationV2 = useSaveCodeRecipeMutationV2();
  const deleteTransformMutation = useDeleteTransformMutation();
  const addAIThreadInputsMutation = useAddAIThreadInputsMutation();
  const removeAIThreadInputsMutation = useRemoveAIThreadInputsMutation();
  const { fetchAndRenderFullErrorLogs, renderTestTransformErrors } = useTransformErrorUtils();

  const newAskAIFlow = recipe?.newAskAIFlow || false;
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });
  const {
    onAutoGenerateCode,
    autoGenerateApiInfo: {
      autoGenerateQueryReset,
      autoGenerateQueryInputNames,
      autoGenerateQueryUserInput,
      isAutoGenerateInProgress,
      isAutoGeneratedSuccess,
      autoGenerateOutputType
    },
    isFetchingChats,
    fetchSuggestions,
    fetchSuggestionsApiInfo,
    threadsApiInfo: { isGetThreadsLoading, recipeThread }
  } = useCodeRecipeApis({
    generateQueryLimit,
    newAskAIFlow: recipe?.newAskAIFlow || false,
    recipeId: recipe?.id,
    inputDatasets,
    responses,
    setResponses,
    inputNames
  });

  const getCodeStrings = () => {
    const pattern =
      /(def transform\(entities(?:, context)?\))([\s\S]*?)(?=\bdef transform\(entities(?:, context)?\)|$)/g;

    const codeStrings = [...editorValue.matchAll(pattern)]
      ?.map((match) => match[0])
      ?.filter((str) => str.trim() !== "");
    return codeStrings;
  };

  const handleSave = React.useCallback(() => {
    const codeStrings = getCodeStrings();

    if (codeStrings?.length === 0) {
      handleResponse({
        errorMessage: "Unable to save recipe - The provided code format is incorrect"
      });
      setEditorValue("");
      return;
    }
    saveCodeRecipeMutation.mutate(
      {
        codeStrings,
        projectId: projectId!,
        recipe,
        templates
      },
      {
        onSuccess: ({ templatesResponse, transformGroupResponse, hasNewTemplates }) => {
          if (hasNewTemplates) {
            setResponses((responses: any) =>
              responses.map((response: any) => ({ ...response, transformId: null }))
            );
          }
          setTemplates(templatesResponse);
          onUpdateRecipe(transformGroupResponse);
          setEditorValue(templatesResponse?.map((template) => template.code)?.join("\r\n\n"));
          handleResponse({
            successMessage: `Recipe is saved successfully.`
          });
        }
      }
    );
  }, [editorValue, projectId, recipe, saveCodeRecipeMutation, templates]);

  const handleTest = React.useCallback(
    (sampleRows?: number) => {
      setIsTestInProgress(true);
      const codeStrings = getCodeStrings();

      if (codeStrings?.length === 0) {
        handleResponse({
          errorMessage: "Unable to save recipe - The provided code format is incorrect"
        });
        setEditorValue("");
        setIsTestInProgress(false);
        return;
      }
      saveAndTestCodeRecipeMutation.mutate(
        {
          codeStrings,
          projectId: projectId!,
          recipe,
          templates
        },
        {
          onSuccess: ({ templatesResponse, transformGroupResponse, hasNewTemplates }) => {
            if (hasNewTemplates) {
              setResponses((responses: any) =>
                responses.map((response: any) => ({ ...response, transformId: null }))
              );
            }
            setTemplates(templatesResponse);
            onUpdateRecipe(transformGroupResponse);
            setEditorValue(templatesResponse?.map((template) => template.code)?.join("\r\n\n"));
            testTransformGroupMutation.mutate(
              {
                recipeId: recipe?.id,
                projectId,
                sampleRows,
                testTransformConfig: transformGroupResponse?.runConfigs?.map((config: any) => ({
                  ...config,
                  templateId: config.templateId,
                  name: config.name
                }))
              },
              {
                onSuccess: (response: $TSFixMe) => {
                  const outputDatasets = response?.dataMap
                    ? Object.keys(response?.dataMap).map((item) => ({
                        name: item,
                        data: response?.dataMap?.[item],
                        type: OUTPUT_TYPE.DATASET,
                        id: uuidv4()
                      }))
                    : [];
                  const outputCharts =
                    response?.entityViewData.map((item: $TSFixMe) => ({
                      ...item,
                      type: OUTPUT_TYPE.CHART,
                      id: uuidv4()
                    })) || [];

                  setPreviewTabs([...outputDatasets, ...outputCharts]);
                  setCodeErrorDetails(null);
                  handleResponse({ successMessage: `Test successful` });
                },
                onError: (error: $TSFixMe) => {
                  renderTestTransformErrors(error, false, true);
                  const responseData = error?.response?.data;
                  responseData?.extraInfo?.runErrDetails &&
                    setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
                },
                onSettled: () => {
                  setIsTestInProgress(false);
                }
              }
            );
          },
          onError: () => {
            setIsTestInProgress(false);
          }
        }
      );
    },
    [
      editorValue,
      fetchAndRenderFullErrorLogs,
      projectId,
      recipe,
      saveAndTestCodeRecipeMutation,
      templates,
      testTransformGroupMutation
    ]
  );

  const onRunSuccess = (response: any) => {
    if (response && !response.msg && response.pipelineRunResult) {
      setCodeErrorDetails(null);
      handleResponse({ successMessage: `Run successful` });
      setReloadTriggerWithDelay();
    }
  };

  const onRunError = (error: $TSFixMe) => {
    fetchAndRenderFullErrorLogs(error, { projectId, scenarioId, groupId: recipe?.id }, false, true);
    const responseData = error?.response?.data;
    responseData?.extraInfo && setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
  };

  const {
    isLoading: isAddingRecipeToQueue,
    mutateAsync: addRecipeToQueueMutation,
    reset: resetAddRecipeToQueueMutation
  } = useAddRecipeToQueue();

  const addRecipeToQueue = async () => {
    await resetAddRecipeToQueueMutation();
    await addRecipeToQueueMutation(
      { scenarioId, id: recipe?.id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);

          toastWrapper({
            type: ToastTypes.Info,
            content: `Recipe ${recipeName || recipe?.name} added to queue successfully!`
          });
        },
        onError: () => {
          toastWrapper({
            type: ToastTypes.Error,
            content: `Error while adding Recipe ${recipeName || recipe?.name} to queue!`
          });
        }
      }
    );
  };

  const handleRun = React.useCallback(async () => {
    if (!scenarioId || !recipe?.id) {
      return;
    }

    !!projectId && checkEnvRelaunch(projectId);

    if (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore) {
      addRecipeToQueue();
    } else {
      let timeoutId: $TSFixMe;

      runTransformGroupMutation.mutate(
        { recipeId: recipe?.id, scenarioId: scenarioId! },
        {
          onSuccess: onRunSuccess,
          onError: onRunError
        }
      );

      return () => clearTimeout(timeoutId);
    }
  }, [projectId, recipe?.id, scenarioId]);

  const handleUpdateRecipe = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      const runConfigs = recipe?.runConfigs?.length
        ? recipe?.runConfigs?.map((item) => item.id)
        : [];
      const updatedPayload = {
        ...recipe,
        runConfigs,
        projectId,
        name: recipe?.name || recipeName,
        displayName: recipe?.displayName || recipe?.name || recipeName,
        parents: recipe?.parents,
        ...payload
      };
      updateTransformGroupMutation.mutate(
        {
          recipeId: recipe?.id,
          transformGroup: updatedPayload,
          projectId: projectId!
        },
        {
          onSuccess: async (response: $TSFixMe) => {
            onUpdateRecipe(response);
            await onSuccess?.(response);
          },
          onError: () => {
            onError?.();
          }
        }
      );
    },
    [projectId, recipe, recipeName]
  );

  const {
    data: runningRecipe,
    isFetched: isFetchedRunningRecipe,
    isError,
    isSuccess,
    error: runError
  } = usePollRecipe({
    enabled: !!(recipe?.id && recipe?.status === RecipeStatuses.Running),
    recipeId: recipe?.id,
    scenarioId
  });

  useEffect(() => {
    if (isSuccess && runningRecipe?.status === RecipeStatuses.Success) {
      onRunSuccess(runningRecipe);
    } else if (isSuccess && runningRecipe?.status === RecipeStatuses.Error) {
      onRunError(runError);
    }
  }, [runningRecipe, runError]);

  useEffect(() => {
    isError && onRunError(runError);
  }, [runError]);

  const isSaveInProgress = React.useMemo(
    () => saveCodeRecipeMutation.isLoading,
    [saveCodeRecipeMutation.isLoading]
  );

  const isRunInProgress = React.useMemo(
    () =>
      isFetchedRunningRecipe
        ? runningRecipe?.status === RecipeStatuses.Running
        : runTransformGroupMutation.isLoading || recipe?.status === RecipeStatuses.Running,
    [recipe, runTransformGroupMutation.isLoading, runningRecipe?.status, isFetchedRunningRecipe]
  );

  const isAskAiRecipeUpdateInProgress =
    saveCodeRecipeMutationV2.isLoading || deleteTransformMutation.isLoading;

  const allInputEntities = React.useMemo(() => {
    return allEntities?.filter((entity) => !outputs.includes(entity.name));
  }, [allEntities, outputs]);

  const isSaveDisabled =
    !editorValue ||
    isSaveInProgress ||
    inputDatasets?.length === 0 ||
    isTestInProgress ||
    isAskAiRecipeUpdateInProgress ||
    isLoading ||
    (newAskAIFlow && isGetThreadsLoading);

  const saveToolTip =
    inputDatasets?.length === 0 || !editorValue ? "Add input and code to enable save" : undefined;

  const isRecipeRunInQueue = useMemo(
    () =>
      isRecipeRunInQueueHelper({
        pendingRecipeRunsInQueue: pendingRecipeRunsInQueueStore,
        recipeId: recipe?.id,
        scenarioId: scenarioId
      }),
    [pendingRecipeRunsInQueueStore, recipe?.id, scenarioId]
  );

  const isAddRecipeToQueue = useMemo(
    () =>
      !isRunInProgress &&
      (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore),
    [isRunInProgress, isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore]
  );

  const runTooltip = useMemo(
    () =>
      inputDatasets?.length === 0 || !editorValue
        ? "Add input and code to enable run"
        : isAutoGenerateInProgress
          ? "Query Run is in progress"
          : isRunInProgress
            ? "Recipe run is in progress"
            : isRecipeRunInQueue
              ? "Recipe is already in queue!"
              : isAddingRecipeToQueue
                ? "Recipe is being added to queue"
                : !!isAddRecipeToQueue
                  ? "Add to Queue"
                  : "Run Recipe",

    [
      inputDatasets?.length,
      editorValue,
      isAutoGenerateInProgress,
      isRunInProgress,
      isRecipeRunInQueue,
      isAddingRecipeToQueue,
      isAddRecipeToQueue
    ]
  );

  const testTooltip =
    inputDatasets?.length === 0 || !editorValue
      ? "Add input and code to enable test"
      : isRunInProgress
        ? "Recipe run is in progress"
        : isAutoGenerateInProgress
          ? "Query Run is in progress"
          : undefined;

  const isRunDisabled = useMemo(
    () =>
      !recipe?.id ||
      !editorValue ||
      isRunInProgress ||
      isRecipeRunInQueue ||
      isAddingRecipeToQueue ||
      isAskAiRecipeUpdateInProgress ||
      isAutoGenerateInProgress ||
      (newAskAIFlow && isGetThreadsLoading) ||
      templates?.length === 0,
    [
      recipe?.id,
      editorValue,
      isRunInProgress,
      isRecipeRunInQueue,
      isAddingRecipeToQueue,
      isAskAiRecipeUpdateInProgress,
      isAutoGenerateInProgress,
      isGetThreadsLoading,
      newAskAIFlow,
      templates?.length
    ]
  );

  const isTestDisabled =
    !recipe?.id ||
    !editorValue ||
    isTestInProgress ||
    inputDatasets?.length === 0 ||
    isSaveInProgress ||
    isRunInProgress ||
    isAskAiRecipeUpdateInProgress ||
    isAutoGenerateInProgress ||
    isLoading ||
    (newAskAIFlow && isGetThreadsLoading);

  const onAddCodeToRecipe = React.useCallback(
    ({
      code: newCode,
      onSuccess,
      codeHistoryId
    }: {
      code: string;
      onSuccess?: (transformId: string) => void;
      codeHistoryId: string;
    }) => {
      saveCodeRecipeMutationV2.mutate(
        {
          recipe,
          code: newCode,
          codeHistoryId,
          projectId: projectId!
        },
        {
          onSuccess: ({ templateResponse, saveTransformResponse, transformGroupResponse }) => {
            const updatedTemplates = [...templates, templateResponse];
            onUpdateRecipe(transformGroupResponse);
            setTemplates((templates: $TSFixMe) => [...templates, templateResponse]);
            setEditorValue(updatedTemplates?.map((template) => template.code)?.join("\r\n\n"));
            onSuccess?.(saveTransformResponse.id);
          }
        }
      );
    },
    [projectId, recipe, saveCodeRecipeMutationV2, templates]
  );

  const onRemoveCodeFromRecipe = React.useCallback(
    ({ transformId, onSuccess }: { transformId: string; onSuccess: () => void }) => {
      recipe &&
        deleteTransformMutation.mutate(
          { transformId, recipeId: recipe.id },
          {
            onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
              onUpdateRecipe(transformGroupResponse);
              const recipeTemplateIds =
                transformGroupResponse?.runConfigs?.map((config) => config.templateId) || [];
              const updatedTemplates = templates?.filter((currTemplate) =>
                recipeTemplateIds.includes(currTemplate.id)
              );
              const updatedCode = updatedTemplates?.map((template) => template.code);
              setTemplates(updatedTemplates);
              setEditorValue(updatedCode?.join("\r\n\n"));
              onSuccess?.();
            }
          }
        );
    },
    [deleteTransformMutation, recipe, templates]
  );

  const isQueryAssociatedWithRecipe = React.useCallback(
    (identifier: string) => {
      return (
        (deleteTransformMutation.isLoading &&
          deleteTransformMutation.variables?.transformId === identifier) ||
        (saveCodeRecipeMutationV2.isLoading &&
          saveCodeRecipeMutationV2.variables?.codeHistoryId === identifier)
      );
    },
    [deleteTransformMutation.isLoading, saveCodeRecipeMutationV2.isLoading]
  );

  const handleInputDatasetsChange = React.useCallback(
    (newEntities: Entities) => {
      const addedItems = _.differenceBy(newEntities, inputDatasets, "id");
      const deletedItems = _.differenceBy(inputDatasets, newEntities, "id");
      if (!recipe?.id) {
        setInputDatasets(newEntities);
        return;
      }
      setIsSelectedEntitiesUpdateInProgess(true);
      const payload = {
        parents: newEntities?.map((entity) => ({ id: entity.id, type: "ENTITY" }))
      };
      const onThreadUpdate = (response: ThreadResponseDto) => {
        queryClient.setQueryData(
          ["projects", projectId, AI_GUIDE_THREADS_KEY],
          (prevData: ThreadResponseDto[] | undefined) => [
            ...(prevData || [])?.map((thread) =>
              thread.entityId === recipe?.id ? response : thread
            )
          ]
        );
      };

      const onSuccess = async () => {
        setInputDatasets(newEntities);
        recipeThread &&
          addedItems?.length > 0 &&
          (await addAIThreadInputsMutation.mutate(
            {
              request: {
                threadId: recipeThread?.threadId!,
                inputEntities: _.map(addedItems, (entity) => ({
                  inputEntityType: InputEntityDtoInputEntityTypeEnum.Entity,
                  entityId: entity.id,
                  entityName: entity.name
                }))
              }
            },
            {
              onSuccess: onThreadUpdate
            }
          ));
        recipeThread &&
          deletedItems?.length > 0 &&
          (await removeAIThreadInputsMutation.mutate(
            {
              threadId: recipeThread?.threadId!,
              request: {
                askAIInputIds: _.map(deletedItems, "id")
              }
            },
            { onSuccess: onThreadUpdate }
          ));
        setIsSelectedEntitiesUpdateInProgess(false);
      };
      handleUpdateRecipe({ payload, onSuccess });
    },
    [handleUpdateRecipe, recipe?.id, recipeThread, inputDatasets]
  );

  const isDeletableRecipe = useMemo(
    () =>
      !recipe?.condition?.expression &&
      recipe?.runConfigs?.length === 0 &&
      responses?.length === 0 &&
      !editorValue &&
      previewTabs?.length === 0,
    [recipe, responses, editorValue, previewTabs]
  );

  const allDatasets = useMemo(() => {
    const intermediateDatasets = newAskAIFlow
      ? _.filter(
          _.flatMap(
            responses.map(
              (response: any) => response.outputEntityList || response.outputEntityResponseList
            )
          ),
          (resp) => resp?.outputType === "DATASET"
        )
      : _.flatMap(responses, (response: any) =>
          response.outputType === OUTPUT_TYPE.DATASET ? response.queryOutputs || [] : []
        );
    return [...inputDatasets, ...intermediateDatasets];
  }, [responses, inputDatasets, newAskAIFlow]);

  const allRecipeDatasets = useMemo(() => {
    const intermediateDatasets =
      responses?.flatMap((response: any) =>
        response.outputType === OUTPUT_TYPE.DATASET && !!response.transformId
          ? response.queryOutputs || []
          : []
      ) || [];
    return [...inputDatasets, ...intermediateDatasets];
  }, [responses, inputDatasets]);

  const allRecipeCharts = useMemo(() => {
    return (
      responses?.flatMap((response: any) =>
        response.outputType === OUTPUT_TYPE.CHART && !!response.transformId
          ? response.queryOutputs || []
          : []
      ) || []
    );
  }, [responses]);

  const allRecipeModels = useMemo(() => {
    return (
      responses?.flatMap((response: any) =>
        response.outputType === OUTPUT_TYPE.MODEL && !!response.transformId
          ? response.queryOutputs || []
          : []
      ) || []
    );
  }, [responses]);

  useEffect(() => {
    if (!pinnedNames || !inputNames) return;
    const updatedPinnedNames = _.intersection(pinnedNames, inputNames);
    const sortedPinnedNames = _.sortBy(pinnedNames);
    const sortedUpdatedPinnedNames = _.sortBy(updatedPinnedNames);

    if (!_.isEqual(sortedPinnedNames, sortedUpdatedPinnedNames)) {
      setPinnedNames(updatedPinnedNames);
    }
  }, [inputNames, pinnedNames]);

  const contextValue = React.useMemo(() => {
    return {
      jobData,
      jobRunData,
      recipe,
      inputDatasets,
      entityFeaturesMap,
      allEntities,
      allInputEntities,
      handleInputDatasetsChange,
      handleUpdateRecipe,
      recipeId: recipe?.id,
      isRunInProgress,
      isTestInProgress,
      isSaveInProgress,
      isSaveDisabled,
      isAddRecipeToQueue,
      isAddingRecipeToQueue,
      isRunDisabled,
      saveToolTip,
      runTooltip,
      testTooltip,
      isOutputTypeChanged,
      setisOutputTypeChanged,
      handleSave,
      handleRun,
      isTestDisabled,
      scenarioData,
      onUpdateRecipe,
      reset: autoGenerateQueryReset,
      onAddCodeToRecipe,
      onRemoveCodeFromRecipe,
      isAskAiRecipeUpdateInProgress,
      previewTabs,
      handleTest,
      setScenarioData,
      setEntityFeaturesMap,
      editorValue,
      setEditorValue,
      setInputDatasets,
      setAllEntities,
      responses,
      setResponses,
      onAutoGenerateCode,
      isAutoGenerateInProgress,
      inputNames,
      pinnedNames,
      outputType,
      setInputNames,
      setOutputType,
      setPinnedNames,
      isAutoGeneratedSuccess,
      isSelectedEntitiesUpdateInProgess,
      setPreviewTabs,
      autoGenerateQueryInputNames,
      autoGenerateQueryUserInput,
      setGenerateQueryLimit,
      generateQueryLimit,
      codeErrorDetails,
      isQueryAssociatedWithRecipe,
      isDeletableRecipe,
      allDatasets,
      allRecipeCharts,
      allRecipeDatasets,
      allRecipeModels,
      isGetThreadsLoading,
      newAskAIFlow,
      threadId: recipeThread?.threadId,
      isFetchingChats,
      fetchSuggestions,
      fetchSuggestionsApiInfo,
      isRetryInProgress,
      setIsRetryInProgress,
      autoGenerateOutputType
    };
  }, [
    jobData,
    jobRunData,
    allEntities,
    allInputEntities,
    entityFeaturesMap,
    handleInputDatasetsChange,
    handleRun,
    handleSave,
    handleUpdateRecipe,
    inputDatasets,
    isAskAiRecipeUpdateInProgress,
    isAddRecipeToQueue,
    isAddingRecipeToQueue,
    isRunDisabled,
    isRunInProgress,
    isSaveDisabled,
    isSaveInProgress,
    isTestDisabled,
    isTestInProgress,
    onAddCodeToRecipe,
    onRemoveCodeFromRecipe,
    recipe,
    saveToolTip,
    runTooltip,
    testTooltip,
    scenarioData,
    previewTabs,
    handleTest,
    setScenarioData,
    setEntityFeaturesMap,
    editorValue,
    setEditorValue,
    setInputDatasets,
    setAllEntities,
    responses,
    setResponses,
    onAutoGenerateCode,
    isAutoGenerateInProgress,
    inputNames,
    outputType,
    setInputNames,
    setOutputType,
    isAutoGeneratedSuccess,
    isSelectedEntitiesUpdateInProgess,
    setPreviewTabs,
    autoGenerateQueryInputNames,
    autoGenerateQueryUserInput,
    setGenerateQueryLimit,
    generateQueryLimit,
    codeErrorDetails,
    isQueryAssociatedWithRecipe,
    isDeletableRecipe,
    allDatasets,
    allRecipeCharts,
    allRecipeDatasets,
    allRecipeModels,
    isGetThreadsLoading,
    recipeThread,
    isFetchingChats,
    fetchSuggestions,
    fetchSuggestionsApiInfo,
    isRetryInProgress,
    setIsRetryInProgress,
    autoGenerateOutputType
  ]);

  return <CodeRecipeContext.Provider value={contextValue}>{children}</CodeRecipeContext.Provider>;
};
