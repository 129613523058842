import React, { useCallback, useMemo, useState } from "react";
import { Button, Grid, InputProps, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useNavigate } from "react-router";
import { isArray, find, isEmpty, filter, includes, toLower, size } from "lodash";

// Helpers
import { deleteProjectMessageLines } from "src/pages/private/ProjectsModule/utils/Projects.helpers";

// Components
import CreateProject from "../Projects/CreateProject";
import InfoCard from "../../components/InfoCards/InfoCards";
import NoProjectFound from "./NoProjectFound";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { Search, Spinner } from "../../components";
import { deleteProjectWithRethrow, getDashboardProjects } from "../../api";
import { useDrawerStore, useProjectsStore } from "../../store/store";
import {
  projectsGetter,
  projectsSetter,
  shouldRefreshProjectsToggler,
  sideComponentSetter
} from "../../store/store.selectors";
import CopyDownloadIcon from "src/icons/NewUX/CopyDownloadIcon";
import CopyProjectModal from "../Projects/CopyProject/components/CopyProjectModal";
import CreateProjectButton from "../Projects/CreateProjectButton";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ProjectSettings from "../Projects/ProjectSettings/ProjectSettings";
import backgroundImg from "src/assets/images/dashboard_background.png";
import styles from "./Dashboard.module.scss";
import { ImageIcon } from "src/icons/NewUX/ImageIcon";
import { JobsList } from "./JobsList";
import { ProjectImageModal } from "../Projects/ProjectImage.tsx/ProjectImageModal";
import { ProjectSettingsDrawerTitle } from "../Projects/common/ProjectSettingsDrawerTitle";
import { SettingsIcon } from "src/icons/NewUX/SettingsIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { defaultImages } from "src/pages/common/defaultImages";
import { toastWrapper } from "src/utils/toastWrapper";
import { usePrivateRouteContext } from "src/routing/PrivateRoute/context/usePrivateRouteContext";
import NoJobsFound from "./NoJobsFound";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles({
  jobWrap: {
    background: "#fff",
    borderRadius: "12px",
    padding: "24px",
    minWidth: "736px"
  },
  viewAllBtn: {
    color: "#003656",
    borderRadius: "20px",
    background: "#fff",
    margin: "40px 0px"
  },

  noResultMessage: {
    fontSize: "16px",
    fontStyle: "italic",
    color: "#7C7C7C",
    padding: "0px 16px",
    fontWeight: 400,
    backgroundColor: "#ffff",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
  },
  noResultToolTip: {
    backgroundColor: "transparent",
    margin: " -13px 0 0 0"
  }
});

const Dashboard = () => {
  const { jobWrap, viewAllBtn, noResultMessage, noResultToolTip } = useStyles();
  const { jobs, isFetchingProjects, isFetchingJobs } = usePrivateRouteContext();
  const navigate = useNavigate();
  const [selectProjectImageId, setSelectedProjectImageId] = useState<string | null>(null);
  const [copyProjectId, setCopyProjectId] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>();

  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [targetIdState, setTargetIdState] = useState("");

  const [isDeletingProject, setIsDeletingProject] = useState(false);
  const [isFetchingProjectsPostDelete, setIsFetchingProjectsPostDelete] = useState(false);
  const [updatingProjectIds] = useProjectsStore((state) => [state.updatingProjectIds]);
  const projects = useProjectsStore(projectsGetter);
  const setProjects = useProjectsStore(projectsSetter);
  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);

  const setSideComponent = useDrawerStore(sideComponentSetter);

  const transformList = (dataArray: $TSFixMe, section: $TSFixMe) => {
    let imageIndex = 0;
    return dataArray.map((data: $TSFixMe) => {
      const updatedImage = !data.image ? defaultImages[imageIndex] : null;
      const isLastImage = imageIndex === defaultImages.length - 1;
      // Loop over default Images to fill project cards
      imageIndex = data.image ? imageIndex : isLastImage ? 0 : imageIndex + 1;
      const transformData = {
        ...data,
        id: data.id,
        title: data.name,
        img: data.image,
        imgSrc: updatedImage,
        description: data.description
      };
      if (section === "projects") {
        (transformData as $TSFixMe).link = "/projects";
      }

      return transformData;
    });
  };

  const handleSearch: InputProps["onChange"] = (event) => {
    setSearchValue(event.target.value);
  };

  const { projectsList, selectedProject, noMatch } = useMemo(() => {
    const filteredProjectList = filter(projects, (project) =>
      includes(toLower(project.name), toLower(searchValue))
    );

    const list = transformList(
      isEmpty(filteredProjectList) && size(jobs) < 0 ? projects : filteredProjectList,
      "projects"
    );

    return {
      projectsList: list,
      selectedProject: list?.find((item: any) => item.id === selectProjectImageId),
      noMatch: searchValue && isEmpty(filteredProjectList)
    };
  }, [projects, selectProjectImageId, searchValue]);

  const handleOpenCreateProjectModal = useCallback(() => {
    setShowCreateProjectModal(true);
  }, []);

  const handleCloseCreateProjectModal = useCallback(() => {
    setShowCreateProjectModal(false);
  }, []);

  const handleDeleteModal = (id: string) => {
    setTargetIdState(id);
    setShowConfirmScreen(true);
  };

  const handleCancelClose = () => {
    setTargetIdState("");
    setShowConfirmScreen(false);
  };

  const handleCloseModal = async () => {
    await deleteProject();
  };

  const deleteProject = async () => {
    setIsDeletingProject(() => true);

    try {
      await deleteProjectWithRethrow(targetIdState, true);

      toastWrapper({
        type: "success",
        content: "Project deleted successfully!"
      });

      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });

      setIsDeletingProject(() => false);
      setShowConfirmScreen(() => false);
      setIsFetchingProjectsPostDelete(() => true);

      const projects = await getDashboardProjects();
      isArray(projects) && setProjects(projects);

      setIsFetchingProjectsPostDelete(() => false);
    } catch (error) {
      console.error({ error });
    } finally {
      setIsDeletingProject(() => false);
      setShowConfirmScreen(() => false);
      setIsFetchingProjectsPostDelete(() => false);
    }
  };

  const handleEditProject = (projectId: string) => {
    setSideComponent({
      sideComponent: ProjectSettings,
      sideComponentProps: {
        title: (
          <ProjectSettingsDrawerTitle
            id={projectId}
            onDelete={() => handleDeleteModal(projectId)}
          />
        ),
        data: { projectId, projects }
      }
    });
  };

  const navigateToViewAllProjects = () => {
    navigate(WebPaths.Projects);
  };

  const getCardOptions = (data: $TSFixMe) => {
    return [
      {
        label: "Project Image",
        click: () => {
          setSelectedProjectImageId(data?.id);
        },
        icon: <ImageIcon />
      },
      {
        label: "Project Settings",
        click: () => handleEditProject(data?.id),
        icon: <SettingsIcon />
      },
      {
        label: "Copy Project",
        click: () => setCopyProjectId(data?.id),
        icon: <CopyDownloadIcon />
      },
      {
        label: "Delete Project",
        click: () => handleDeleteModal(data?.id),
        icon: <TrashIcon viewBox="0 0 20 20" />,
        disabled: includes(updatingProjectIds, data?.id),
        tooltip: includes(updatingProjectIds, data?.id)
          ? "Please wait until the project is getting updated"
          : ""
      }
    ];
  };

  const project = useMemo(
    () => projects?.find((eachProject: $TSFixMe) => eachProject?.id === targetIdState),
    [projects, targetIdState]
  );

  const copyProject = useMemo(
    () => find(projects, (proj) => proj.id === copyProjectId),
    [projects, copyProjectId]
  );
  const availableWindowSize = window.innerWidth * 0.8;
  const numberOfCards = Math.floor((availableWindowSize + 24) / 380);
  const startLoc = (availableWindowSize + 24 - numberOfCards * 380) / 2;

  const getChildren = () => {
    return (
      <>
        {!isEmpty(projects) && (
          <Grid item container xs={12} style={{ padding: `0 ${startLoc}px`, marginBottom: "15px" }}>
            <Typography data-testid="recentProjectText" className={styles.sectionContent}>
              Recent Projects
            </Typography>
            <Tooltip
              open={!!noMatch}
              classes={{ tooltip: noResultToolTip }}
              title={<span className={noResultMessage}>No matching results found!</span>}>
              <Grid style={{ marginLeft: "auto" }}>
                <Search onSearch={handleSearch} value={searchValue} placeholder="Search projects" />
              </Grid>
            </Tooltip>
          </Grid>
        )}
        {isEmpty(projectsList) ? (
          <NoProjectFound onClick={handleOpenCreateProjectModal} />
        ) : (
          <>
            <Grid
              container
              style={{
                gap: "24px",
                width: availableWindowSize,
                paddingLeft: startLoc
              }}
              justifyContent="flex-start">
              {projectsList.map((data: $TSFixMe, i: number) => {
                if (i >= numberOfCards) {
                  return null;
                }
                return (
                  <Grid
                    key={data.id}
                    item
                    xs
                    style={{
                      flex: "0 0 356px",
                      maxWidth: "356px",
                      height: "460px",
                      minHeight: "460px",
                      maxHeight: "460px"
                    }}>
                    <InfoCard data={data} options={getCardOptions(data)} />
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              style={{
                paddingLeft: projectsList.length === 1 ? startLoc : "0px",
                margin: projects.length < 2 ? "20px 0" : 0
              }}
              alignItems="center"
              justifyContent={"center"}
              container>
              {projects.length > 1 && (
                <Button
                  variant="text"
                  className={viewAllBtn}
                  onClick={() => navigateToViewAllProjects()}>
                  {`View all ${projects.length} Projects`}
                </Button>
              )}
            </Grid>
          </>
        )}

        <Grid key="recent-jobs" container className={styles.dashboardContainer}>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: startLoc,
              paddingRight: startLoc
            }}>
            <Typography data-testid="recentJobText" className={styles.sectionContent}>
              Recent Jobs
            </Typography>
          </Grid>
          <Grid
            style={{
              marginLeft: startLoc,
              marginRight: startLoc,
              marginBottom: "50px"
            }}
            container
            direction="column"
            wrap="nowrap"
            className={jobWrap}>
            {jobs?.length > 0 ? (
              jobs.map((data: $TSFixMe, i: number) => {
                if (i > 5) {
                  return null;
                }
                return <JobsList data={data} key={i} />;
              })
            ) : (
              <NoJobsFound />
            )}
            {}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {showCreateProjectModal && (
        <CreateProject open={true} onClose={handleCloseCreateProjectModal} />
      )}
      {selectProjectImageId && (
        <ProjectImageModal
          project={selectedProject}
          handleClose={() => setSelectedProjectImageId(null)}
          onSuccess={() => toggleShouldProjectsRefresh()}
        />
      )}
      {copyProjectId && copyProject?.name && (
        <CopyProjectModal
          id={copyProjectId}
          name={copyProject.name}
          envId={copyProject.envId}
          open={!!copyProjectId}
          onClose={() => setCopyProjectId(null)}
        />
      )}
      {showConfirmScreen && (
        <NewThemeWrapper>
          <Modal
            open={true}
            variant={ModalVariants.Delete}
            title="Delete Project"
            content={deleteProjectMessageLines(project?.name)}
            isSubmitting={isDeletingProject}
            onClose={handleCancelClose}
            onSubmit={handleCloseModal}
          />
        </NewThemeWrapper>
      )}
      <SubTopNavBarWrapper
        subTopNavBarRightSection={{
          component: <CreateProjectButton onNewProjectClick={handleOpenCreateProjectModal} />
        }}
      />
      {isFetchingJobs || isFetchingProjects || isFetchingProjectsPostDelete ? (
        <Grid container style={{ minHeight: "calc(100vh - 94px)" }}>
          <Spinner />
        </Grid>
      ) : (
        <div
          className="container-height"
          style={{
            padding: "64px 10% 0px 10%",
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: "100% auto, 100% auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            overflowX: "hidden"
          }}>
          {getChildren()}
        </div>
      )}
    </>
  );
};

export default Dashboard;
