/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import { UninterpretedOption } from './uninterpreted-option';
// May contain unused imports in some cases
// @ts-ignore
import { UninterpretedOptionOrBuilder } from './uninterpreted-option-or-builder';
// May contain unused imports in some cases
// @ts-ignore
import { UnknownFieldSet } from './unknown-field-set';

/**
 * 
 * @export
 * @interface FieldOptions
 */
export interface FieldOptions {
    /**
     * 
     * @type {UnknownFieldSet}
     * @memberof FieldOptions
     */
    'unknownFields'?: UnknownFieldSet;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptions
     */
    'initialized'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptions
     */
    'deprecated'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldOptions
     */
    'serializedSize'?: number;
    /**
     * 
     * @type {object}
     * @memberof FieldOptions
     */
    'parserForType'?: object;
    /**
     * 
     * @type {FieldOptions}
     * @memberof FieldOptions
     */
    'defaultInstanceForType'?: FieldOptions;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptions
     */
    'packed'?: boolean;
    /**
     * 
     * @type {Array<UninterpretedOption>}
     * @memberof FieldOptions
     */
    'uninterpretedOptionList'?: Array<UninterpretedOption>;
    /**
     * 
     * @type {number}
     * @memberof FieldOptions
     */
    'uninterpretedOptionCount'?: number;
    /**
     * 
     * @type {Array<UninterpretedOptionOrBuilder>}
     * @memberof FieldOptions
     */
    'uninterpretedOptionOrBuilderList'?: Array<UninterpretedOptionOrBuilder>;
    /**
     * 
     * @type {string}
     * @memberof FieldOptions
     */
    'ctype'?: FieldOptionsCtypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FieldOptions
     */
    'jstype'?: FieldOptionsJstypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptions
     */
    'lazy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptions
     */
    'weak'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FieldOptions
     */
    'initializationErrorString'?: string;
    /**
     * 
     * @type {Descriptor}
     * @memberof FieldOptions
     */
    'descriptorForType'?: Descriptor;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FieldOptions
     */
    'allFields'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FieldOptions
     */
    'allFieldsRaw'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof FieldOptions
     */
    'memoizedSerializedSize'?: number;
}

export const FieldOptionsCtypeEnum = {
    String: 'STRING',
    Cord: 'CORD',
    StringPiece: 'STRING_PIECE'
} as const;

export type FieldOptionsCtypeEnum = typeof FieldOptionsCtypeEnum[keyof typeof FieldOptionsCtypeEnum];
export const FieldOptionsJstypeEnum = {
    Normal: 'JS_NORMAL',
    String: 'JS_STRING',
    Number: 'JS_NUMBER'
} as const;

export type FieldOptionsJstypeEnum = typeof FieldOptionsJstypeEnum[keyof typeof FieldOptionsJstypeEnum];


