import React, { useCallback, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import { useNavigate, useLocation, useParams, generatePath } from "react-router-dom";

// Icons
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

import { ScenarioSettings } from "../../../icons/ScenarioSettings";
import DeleteNodeModal from "../../ViewData/DeleteNodeModal/DeleteNodeModal";
import { toastWrapper } from "src/utils/toastWrapper";
import { useJobsContext } from "src/pages/private/ProjectsModule/pages/Jobs/context/useJobsContext";
import { useScenariosStore } from "src/store/store";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { WebPaths } from "src/routing/routes";
import { Scenario } from "src/types";
import { useGetScenario } from "src/hooks/api";
import useUpdateEntities from "src/hooks/api/entities/useUpdateEntities";

const ChartsHeader = ({ handleChangeName, chartName, jobData, jobRunData }: $TSFixMe) => {
  const { projectId, chartId, scenarioId, jobRunId } = useParams<$TSFixMe>();

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPaths, jobPathsSetter } = useJobsContext() || {};

  const scenarios = useScenariosStore((state) => state.scenarios);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [scenarioData, setScenarioData] = useState<Scenario | null>(null);
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const { isLoading } = useGetScenario({
    scenarioId,
    onSuccess: (data: Scenario) => {
      setScenarioData(data);
    }
  });
  const updateEntityMutation = useUpdateEntities();

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  const handleScenarioSettings = useCallback(() => {
    if (!projectId || !scenarioId) {
      return;
    }

    navigate(generatePath(WebPaths.Scenario, { projectId, scenarioId }));
  }, [projectId, scenarioId]);

  const handleDeleteSuccess = () => {
    navigate(-1);
  };

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const updateChartName = async (name: $TSFixMe) => {
    updateEntityMutation.mutate(
      {
        displayName: name,
        id: chartId!,
        showSuccessMsg: false
      },
      {
        onSuccess: (response: any) => {
          if (response && !response.msg) {
            handleChangeName(name);
          }
          toastWrapper({
            type: "success",
            content: "Chart Name updated successfully."
          });
        },
        onError: () => {
          setShouldResetName(Date.now());
        }
      }
    );
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        nodeId={chartId}
        nodeName={chartName}
        nodeType="chart"
        onDeleteSuccess={handleDeleteSuccess}
      />

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(isJobPath
            ? {
                backNavAction: () => {
                  jobPathsSetter(jobPaths.slice(0, -1));
                }
              }
            : {
                backNavPath: `/projects/${projectId}/scenario/${scenarioId}/canvas`
              }),
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              chartName={chartName}
              updateChartName={updateChartName}
              shouldResetChartName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath && isDefaultScenario
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: handleOpenDeleteModal
                  }
                ]
              }
            : {}),
          ...(!isJobPath && !isLoading && !isDefaultScenario
            ? {
                component: (
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<ScenarioSettings width={18} height={18} viewBox="0 0 24 24" />}
                    onClick={handleScenarioSettings}>
                    Scenario Settings
                  </Button>
                )
              }
            : {})
        }}
      />
    </>
  );
};

export default ChartsHeader;
