/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { InputIdDto } from './input-id-dto';

/**
 * 
 * @export
 * @interface AIChatRequestDto
 */
export interface AIChatRequestDto {
    /**
     * 
     * @type {number}
     * @memberof AIChatRequestDto
     */
    'sampleRows'?: number;
    /**
     * 
     * @type {Array<InputIdDto>}
     * @memberof AIChatRequestDto
     */
    'inputIds'?: Array<InputIdDto>;
    /**
     * 
     * @type {string}
     * @memberof AIChatRequestDto
     */
    'useCase': AIChatRequestDtoUseCaseEnum;
    /**
     * 
     * @type {string}
     * @memberof AIChatRequestDto
     */
    'query': string;
    /**
     * 
     * @type {string}
     * @memberof AIChatRequestDto
     */
    'outputType': AIChatRequestDtoOutputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AIChatRequestDto
     */
    'llmType'?: AIChatRequestDtoLlmTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AIChatRequestDto
     */
    'dataSharingStrategy'?: AIChatRequestDtoDataSharingStrategyEnum;
    /**
     * 
     * @type {number}
     * @memberof AIChatRequestDto
     */
    'temperature'?: number;
}

export const AIChatRequestDtoUseCaseEnum = {
    ConversationUseCase: 'CONVERSATION_USE_CASE',
    ProblemTypeUseCase: 'PROBLEM_TYPE_USE_CASE',
    TargetColumnUseCase: 'TARGET_COLUMN_USE_CASE',
    OneShotUseCase: 'ONE_SHOT_USE_CASE',
    PromptSuggestionsUseCase: 'PROMPT_SUGGESTIONS_USE_CASE',
    RagUseCase: 'RAG_USE_CASE',
    ExplainCodeUseCase: 'EXPLAIN_CODE_USE_CASE'
} as const;

export type AIChatRequestDtoUseCaseEnum = typeof AIChatRequestDtoUseCaseEnum[keyof typeof AIChatRequestDtoUseCaseEnum];
export const AIChatRequestDtoOutputTypeEnum = {
    Text: 'TEXT',
    Chart: 'CHART',
    Image: 'IMAGE',
    Dataset: 'DATASET',
    TextNoCode: 'TEXT_NO_CODE',
    Function: 'FUNCTION',
    Unrecognized: 'UNRECOGNIZED',
    AutoInfer: 'AUTO_INFER',
    Model: 'MODEL'
} as const;

export type AIChatRequestDtoOutputTypeEnum = typeof AIChatRequestDtoOutputTypeEnum[keyof typeof AIChatRequestDtoOutputTypeEnum];
export const AIChatRequestDtoLlmTypeEnum = {
    Turbo: 'OPENAI_GPT_4_TURBO',
    O: 'OPENAI_GPT_4_O'
} as const;

export type AIChatRequestDtoLlmTypeEnum = typeof AIChatRequestDtoLlmTypeEnum[keyof typeof AIChatRequestDtoLlmTypeEnum];
export const AIChatRequestDtoDataSharingStrategyEnum = {
    SampleData: 'SAMPLE_DATA',
    OnlyMetadata: 'ONLY_METADATA'
} as const;

export type AIChatRequestDtoDataSharingStrategyEnum = typeof AIChatRequestDtoDataSharingStrategyEnum[keyof typeof AIChatRequestDtoDataSharingStrategyEnum];


