/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AdditionDSArgsRequestDto } from '../Models';
// @ts-ignore
import { ConnectCardResponse } from '../Models';
// @ts-ignore
import { ConnectorStatusResponse } from '../Models';
// @ts-ignore
import { CreateConnectorDto } from '../Models';
// @ts-ignore
import { CreateDataSourceDto } from '../Models';
// @ts-ignore
import { DataConnectorSchemaDto } from '../Models';
// @ts-ignore
import { DataConnectorSyncFrequencyDto } from '../Models';
// @ts-ignore
import { DataSourceDashBoardDto } from '../Models';
// @ts-ignore
import { DataSourceDto } from '../Models';
// @ts-ignore
import { DataSourceSchemaDto } from '../Models';
// @ts-ignore
import { DataSourceUsageDto } from '../Models';
// @ts-ignore
import { DatasourceDetailsDto } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { ListDataSourceFileRequestDto } from '../Models';
// @ts-ignore
import { ListFilesResult } from '../Models';
// @ts-ignore
import { ListTableResult } from '../Models';
// @ts-ignore
import { RefreshDataSourceRequest } from '../Models';
// @ts-ignore
import { SampleDSDataResult } from '../Models';
// @ts-ignore
import { SampleDataFromDSRequestDto } from '../Models';
// @ts-ignore
import { ThirdPartyCreateConnectorResult } from '../Models';
// @ts-ignore
import { ThirdPartyProbableDatasetsResult } from '../Models';
// @ts-ignore
import { ThirdPartySampleDatasetsResult } from '../Models';
// @ts-ignore
import { ThirdPartySourcesListResult } from '../Models';
// @ts-ignore
import { UpdateDataSourceDto } from '../Models';
/**
 * DataSourceControllerApi - axios parameter creator
 * @export
 */
export const DataSourceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectorDetails: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('connectorDetails', 'id', id)
            const localVarPath = `/v2/data-source/{id}/connector-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDataSourceDto} createDataSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create4: async (createDataSourceDto: CreateDataSourceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDataSourceDto' is not null or undefined
            assertParamExists('create4', 'createDataSourceDto', createDataSourceDto)
            const localVarPath = `/v2/data-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataSourceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {CreateConnectorDto} createConnectorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnector: async (sourceId: string, createConnectorDto: CreateConnectorDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('createConnector', 'sourceId', sourceId)
            // verify required parameter 'createConnectorDto' is not null or undefined
            assertParamExists('createConnector', 'createConnectorDto', createConnectorDto)
            const localVarPath = `/v2/data-source/create-connector/{sourceId}`
                .replace(`{${"sourceId"}}`, encodeURIComponent(String(sourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConnectorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete5: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete5', 'id', id)
            const localVarPath = `/v2/data-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectors: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/data-source/third-party-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateConnectorDto} createConnectorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectCardUrl: async (id: string, createConnectorDto: CreateConnectorDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConnectCardUrl', 'id', id)
            // verify required parameter 'createConnectorDto' is not null or undefined
            assertParamExists('getConnectCardUrl', 'createConnectorDto', createConnectorDto)
            const localVarPath = `/v2/data-source/{id}/create-connector-card`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConnectorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connectorId 
         * @param {string} tableName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorSampleData: async (connectorId: string, tableName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('getConnectorSampleData', 'connectorId', connectorId)
            // verify required parameter 'tableName' is not null or undefined
            assertParamExists('getConnectorSampleData', 'tableName', tableName)
            const localVarPath = `/v2/data-source/{connectorId}/connector/sample-data`
                .replace(`{${"connectorId"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tableName !== undefined) {
                localVarQueryParameter['tableName'] = tableName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} connectorId 
         * @param {number} [tableCountStart] 
         * @param {number} [tableCountEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorSchema: async (connectorId: string, tableCountStart?: number, tableCountEnd?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('getConnectorSchema', 'connectorId', connectorId)
            const localVarPath = `/v2/data-source/{connectorId}/connector/schema`
                .replace(`{${"connectorId"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tableCountStart !== undefined) {
                localVarQueryParameter['tableCountStart'] = tableCountStart;
            }

            if (tableCountEnd !== undefined) {
                localVarQueryParameter['tableCountEnd'] = tableCountEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenantDataSources: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/data-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSource: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataSource', 'id', id)
            const localVarPath = `/v2/data-source/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSourceByName: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getDataSourceByName', 'name', name)
            const localVarPath = `/v2/data-source/by-name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSourceUsage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataSourceUsage', 'id', id)
            const localVarPath = `/v2/data-source/{id}/usage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSources: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/data-source/full-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProbableDatasets: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProbableDatasets', 'id', id)
            const localVarPath = `/v2/data-source/probable-data-sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataSourceId 
         * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleData: async (dataSourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataSourceId' is not null or undefined
            assertParamExists('getSampleData', 'dataSourceId', dataSourceId)
            // verify required parameter 'additionDSArgsRequestDto' is not null or undefined
            assertParamExists('getSampleData', 'additionDSArgsRequestDto', additionDSArgsRequestDto)
            const localVarPath = `/v2/data-source/{dataSourceId}/sample-data`
                .replace(`{${"dataSourceId"}}`, encodeURIComponent(String(dataSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionDSArgsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SampleDataFromDSRequestDto} sampleDataFromDSRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleData1: async (sampleDataFromDSRequestDto: SampleDataFromDSRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleDataFromDSRequestDto' is not null or undefined
            assertParamExists('getSampleData1', 'sampleDataFromDSRequestDto', sampleDataFromDSRequestDto)
            const localVarPath = `/v2/data-source/sample-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sampleDataFromDSRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/data-source/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListDataSourceFileRequestDto} listDataSourceFileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFile: async (listDataSourceFileRequestDto: ListDataSourceFileRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listDataSourceFileRequestDto' is not null or undefined
            assertParamExists('listFile', 'listDataSourceFileRequestDto', listDataSourceFileRequestDto)
            const localVarPath = `/v2/data-source/list-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listDataSourceFileRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} datasourceId 
         * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTables: async (datasourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasourceId' is not null or undefined
            assertParamExists('listTables', 'datasourceId', datasourceId)
            // verify required parameter 'additionDSArgsRequestDto' is not null or undefined
            assertParamExists('listTables', 'additionDSArgsRequestDto', additionDSArgsRequestDto)
            const localVarPath = `/v2/data-source/{datasourceId}/list-tables`
                .replace(`{${"datasourceId"}}`, encodeURIComponent(String(datasourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(additionDSArgsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshDataSourceRequest} refreshDataSourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshStatus: async (refreshDataSourceRequest: RefreshDataSourceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshDataSourceRequest' is not null or undefined
            assertParamExists('refreshStatus', 'refreshDataSourceRequest', refreshDataSourceRequest)
            const localVarPath = `/v2/data-source/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshDataSourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataConnectorSyncFrequencyDto} dataConnectorSyncFrequencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSyncFrequency: async (dataConnectorSyncFrequencyDto: DataConnectorSyncFrequencyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataConnectorSyncFrequencyDto' is not null or undefined
            assertParamExists('setSyncFrequency', 'dataConnectorSyncFrequencyDto', dataConnectorSyncFrequencyDto)
            const localVarPath = `/v2/data-source/sync-frequency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataConnectorSyncFrequencyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sync1', 'id', id)
            const localVarPath = `/v2/data-source/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDataSourceDto} updateDataSourceDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        update4: async (updateDataSourceDto: UpdateDataSourceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDataSourceDto' is not null or undefined
            assertParamExists('update4', 'updateDataSourceDto', updateDataSourceDto)
            const localVarPath = `/v2/data-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataSourceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDataSourceDto} updateDataSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update5: async (id: string, updateDataSourceDto: UpdateDataSourceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update5', 'id', id)
            // verify required parameter 'updateDataSourceDto' is not null or undefined
            assertParamExists('update5', 'updateDataSourceDto', updateDataSourceDto)
            const localVarPath = `/v2/data-source/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataSourceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataSourceControllerApi - functional programming interface
 * @export
 */
export const DataSourceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataSourceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectorDetails(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectorStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectorDetails(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.connectorDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateDataSourceDto} createDataSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create4(createDataSourceDto: CreateDataSourceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create4(createDataSourceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.create4']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {CreateConnectorDto} createConnectorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnector(sourceId: string, createConnectorDto: CreateConnectorDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdPartyCreateConnectorResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnector(sourceId, createConnectorDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.createConnector']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete5(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete5(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.delete5']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConnectors(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdPartySourcesListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConnectors(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getAllConnectors']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateConnectorDto} createConnectorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectCardUrl(id: string, createConnectorDto: CreateConnectorDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectCardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectCardUrl(id, createConnectorDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getConnectCardUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} connectorId 
         * @param {string} tableName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorSampleData(connectorId: string, tableName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdPartySampleDatasetsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorSampleData(connectorId, tableName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getConnectorSampleData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} connectorId 
         * @param {number} [tableCountStart] 
         * @param {number} [tableCountEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorSchema(connectorId: string, tableCountStart?: number, tableCountEnd?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataConnectorSchemaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorSchema(connectorId, tableCountStart, tableCountEnd, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getConnectorSchema']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTenantDataSources(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSourceDashBoardDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTenantDataSources(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getCurrentTenantDataSources']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSource(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSource(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getDataSource']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSourceByName(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSourceByName(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getDataSourceByName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSourceUsage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSourceUsageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSourceUsage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getDataSourceUsage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataSources(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasourceDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSources(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getDataSources']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProbableDatasets(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdPartyProbableDatasetsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProbableDatasets(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getProbableDatasets']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataSourceId 
         * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSampleData(dataSourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SampleDSDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleData(dataSourceId, additionDSArgsRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getSampleData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {SampleDataFromDSRequestDto} sampleDataFromDSRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSampleData1(sampleDataFromDSRequestDto: SampleDataFromDSRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SampleDSDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleData1(sampleDataFromDSRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getSampleData1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchema2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSourceSchemaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchema2(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.getSchema2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ListDataSourceFileRequestDto} listDataSourceFileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFile(listDataSourceFileRequestDto: ListDataSourceFileRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFilesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFile(listDataSourceFileRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.listFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} datasourceId 
         * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTables(datasourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTableResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTables(datasourceId, additionDSArgsRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.listTables']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {RefreshDataSourceRequest} refreshDataSourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshStatus(refreshDataSourceRequest: RefreshDataSourceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataSourceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshStatus(refreshDataSourceRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.refreshStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {DataConnectorSyncFrequencyDto} dataConnectorSyncFrequencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSyncFrequency(dataConnectorSyncFrequencyDto: DataConnectorSyncFrequencyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSyncFrequency(dataConnectorSyncFrequencyDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.setSyncFrequency']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sync1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sync1(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.sync1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDataSourceDto} updateDataSourceDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async update4(updateDataSourceDto: UpdateDataSourceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update4(updateDataSourceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.update4']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDataSourceDto} updateDataSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update5(id: string, updateDataSourceDto: UpdateDataSourceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSourceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update5(id, updateDataSourceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataSourceControllerApi.update5']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DataSourceControllerApi - factory interface
 * @export
 */
export const DataSourceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataSourceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectorDetails(id: string, options?: any): AxiosPromise<ConnectorStatusResponse> {
            return localVarFp.connectorDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDataSourceDto} createDataSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create4(createDataSourceDto: CreateDataSourceDto, options?: any): AxiosPromise<DataSourceDto> {
            return localVarFp.create4(createDataSourceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sourceId 
         * @param {CreateConnectorDto} createConnectorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnector(sourceId: string, createConnectorDto: CreateConnectorDto, options?: any): AxiosPromise<ThirdPartyCreateConnectorResult> {
            return localVarFp.createConnector(sourceId, createConnectorDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete5(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.delete5(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectors(options?: any): AxiosPromise<ThirdPartySourcesListResult> {
            return localVarFp.getAllConnectors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateConnectorDto} createConnectorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectCardUrl(id: string, createConnectorDto: CreateConnectorDto, options?: any): AxiosPromise<ConnectCardResponse> {
            return localVarFp.getConnectCardUrl(id, createConnectorDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connectorId 
         * @param {string} tableName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorSampleData(connectorId: string, tableName: string, options?: any): AxiosPromise<ThirdPartySampleDatasetsResult> {
            return localVarFp.getConnectorSampleData(connectorId, tableName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} connectorId 
         * @param {number} [tableCountStart] 
         * @param {number} [tableCountEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorSchema(connectorId: string, tableCountStart?: number, tableCountEnd?: number, options?: any): AxiosPromise<DataConnectorSchemaDto> {
            return localVarFp.getConnectorSchema(connectorId, tableCountStart, tableCountEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenantDataSources(name?: string, options?: any): AxiosPromise<Array<DataSourceDashBoardDto>> {
            return localVarFp.getCurrentTenantDataSources(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSource(id: string, options?: any): AxiosPromise<DataSourceDto> {
            return localVarFp.getDataSource(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSourceByName(name: string, options?: any): AxiosPromise<DataSourceDto> {
            return localVarFp.getDataSourceByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSourceUsage(id: string, options?: any): AxiosPromise<Array<DataSourceUsageDto>> {
            return localVarFp.getDataSourceUsage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataSources(options?: any): AxiosPromise<Array<DatasourceDetailsDto>> {
            return localVarFp.getDataSources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProbableDatasets(id: string, options?: any): AxiosPromise<ThirdPartyProbableDatasetsResult> {
            return localVarFp.getProbableDatasets(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataSourceId 
         * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleData(dataSourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: any): AxiosPromise<SampleDSDataResult> {
            return localVarFp.getSampleData(dataSourceId, additionDSArgsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SampleDataFromDSRequestDto} sampleDataFromDSRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleData1(sampleDataFromDSRequestDto: SampleDataFromDSRequestDto, options?: any): AxiosPromise<SampleDSDataResult> {
            return localVarFp.getSampleData1(sampleDataFromDSRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema2(options?: any): AxiosPromise<Array<DataSourceSchemaDto>> {
            return localVarFp.getSchema2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListDataSourceFileRequestDto} listDataSourceFileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFile(listDataSourceFileRequestDto: ListDataSourceFileRequestDto, options?: any): AxiosPromise<ListFilesResult> {
            return localVarFp.listFile(listDataSourceFileRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} datasourceId 
         * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTables(datasourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: any): AxiosPromise<ListTableResult> {
            return localVarFp.listTables(datasourceId, additionDSArgsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshDataSourceRequest} refreshDataSourceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshStatus(refreshDataSourceRequest: RefreshDataSourceRequest, options?: any): AxiosPromise<Array<DataSourceDto>> {
            return localVarFp.refreshStatus(refreshDataSourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataConnectorSyncFrequencyDto} dataConnectorSyncFrequencyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSyncFrequency(dataConnectorSyncFrequencyDto: DataConnectorSyncFrequencyDto, options?: any): AxiosPromise<void> {
            return localVarFp.setSyncFrequency(dataConnectorSyncFrequencyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync1(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.sync1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDataSourceDto} updateDataSourceDto 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        update4(updateDataSourceDto: UpdateDataSourceDto, options?: any): AxiosPromise<DataSourceDto> {
            return localVarFp.update4(updateDataSourceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDataSourceDto} updateDataSourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update5(id: string, updateDataSourceDto: UpdateDataSourceDto, options?: any): AxiosPromise<DataSourceDto> {
            return localVarFp.update5(id, updateDataSourceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataSourceControllerApi - interface
 * @export
 * @interface DataSourceControllerApi
 */
export interface DataSourceControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    connectorDetails(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ConnectorStatusResponse>;

    /**
     * 
     * @param {CreateDataSourceDto} createDataSourceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    create4(createDataSourceDto: CreateDataSourceDto, options?: RawAxiosRequestConfig): AxiosPromise<DataSourceDto>;

    /**
     * 
     * @param {string} sourceId 
     * @param {CreateConnectorDto} createConnectorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    createConnector(sourceId: string, createConnectorDto: CreateConnectorDto, options?: RawAxiosRequestConfig): AxiosPromise<ThirdPartyCreateConnectorResult>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    delete5(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getAllConnectors(options?: RawAxiosRequestConfig): AxiosPromise<ThirdPartySourcesListResult>;

    /**
     * 
     * @param {string} id 
     * @param {CreateConnectorDto} createConnectorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getConnectCardUrl(id: string, createConnectorDto: CreateConnectorDto, options?: RawAxiosRequestConfig): AxiosPromise<ConnectCardResponse>;

    /**
     * 
     * @param {string} connectorId 
     * @param {string} tableName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getConnectorSampleData(connectorId: string, tableName: string, options?: RawAxiosRequestConfig): AxiosPromise<ThirdPartySampleDatasetsResult>;

    /**
     * 
     * @param {string} connectorId 
     * @param {number} [tableCountStart] 
     * @param {number} [tableCountEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getConnectorSchema(connectorId: string, tableCountStart?: number, tableCountEnd?: number, options?: RawAxiosRequestConfig): AxiosPromise<DataConnectorSchemaDto>;

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getCurrentTenantDataSources(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataSourceDashBoardDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getDataSource(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DataSourceDto>;

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getDataSourceByName(name: string, options?: RawAxiosRequestConfig): AxiosPromise<DataSourceDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getDataSourceUsage(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataSourceUsageDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getDataSources(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatasourceDetailsDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getProbableDatasets(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ThirdPartyProbableDatasetsResult>;

    /**
     * 
     * @param {string} dataSourceId 
     * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getSampleData(dataSourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<SampleDSDataResult>;

    /**
     * 
     * @param {SampleDataFromDSRequestDto} sampleDataFromDSRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getSampleData1(sampleDataFromDSRequestDto: SampleDataFromDSRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<SampleDSDataResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    getSchema2(options?: RawAxiosRequestConfig): AxiosPromise<Array<DataSourceSchemaDto>>;

    /**
     * 
     * @param {ListDataSourceFileRequestDto} listDataSourceFileRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    listFile(listDataSourceFileRequestDto: ListDataSourceFileRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ListFilesResult>;

    /**
     * 
     * @param {string} datasourceId 
     * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    listTables(datasourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ListTableResult>;

    /**
     * 
     * @param {RefreshDataSourceRequest} refreshDataSourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    refreshStatus(refreshDataSourceRequest: RefreshDataSourceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataSourceDto>>;

    /**
     * 
     * @param {DataConnectorSyncFrequencyDto} dataConnectorSyncFrequencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    setSyncFrequency(dataConnectorSyncFrequencyDto: DataConnectorSyncFrequencyDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    sync1(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {UpdateDataSourceDto} updateDataSourceDto 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    update4(updateDataSourceDto: UpdateDataSourceDto, options?: RawAxiosRequestConfig): AxiosPromise<DataSourceDto>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateDataSourceDto} updateDataSourceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApiInterface
     */
    update5(id: string, updateDataSourceDto: UpdateDataSourceDto, options?: RawAxiosRequestConfig): AxiosPromise<DataSourceDto>;

}

/**
 * DataSourceControllerApi - object-oriented interface
 * @export
 * @class DataSourceControllerApi
 * @extends {BaseAPI}
 */
export class DataSourceControllerApi extends BaseAPI implements DataSourceControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public connectorDetails(id: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).connectorDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDataSourceDto} createDataSourceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public create4(createDataSourceDto: CreateDataSourceDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).create4(createDataSourceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sourceId 
     * @param {CreateConnectorDto} createConnectorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public createConnector(sourceId: string, createConnectorDto: CreateConnectorDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).createConnector(sourceId, createConnectorDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public delete5(id: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).delete5(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getAllConnectors(options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getAllConnectors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateConnectorDto} createConnectorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getConnectCardUrl(id: string, createConnectorDto: CreateConnectorDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getConnectCardUrl(id, createConnectorDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connectorId 
     * @param {string} tableName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getConnectorSampleData(connectorId: string, tableName: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getConnectorSampleData(connectorId, tableName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} connectorId 
     * @param {number} [tableCountStart] 
     * @param {number} [tableCountEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getConnectorSchema(connectorId: string, tableCountStart?: number, tableCountEnd?: number, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getConnectorSchema(connectorId, tableCountStart, tableCountEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getCurrentTenantDataSources(name?: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getCurrentTenantDataSources(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getDataSource(id: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getDataSource(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getDataSourceByName(name: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getDataSourceByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getDataSourceUsage(id: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getDataSourceUsage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getDataSources(options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getDataSources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getProbableDatasets(id: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getProbableDatasets(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataSourceId 
     * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getSampleData(dataSourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getSampleData(dataSourceId, additionDSArgsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SampleDataFromDSRequestDto} sampleDataFromDSRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getSampleData1(sampleDataFromDSRequestDto: SampleDataFromDSRequestDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getSampleData1(sampleDataFromDSRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public getSchema2(options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).getSchema2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListDataSourceFileRequestDto} listDataSourceFileRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public listFile(listDataSourceFileRequestDto: ListDataSourceFileRequestDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).listFile(listDataSourceFileRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} datasourceId 
     * @param {AdditionDSArgsRequestDto} additionDSArgsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public listTables(datasourceId: string, additionDSArgsRequestDto: AdditionDSArgsRequestDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).listTables(datasourceId, additionDSArgsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshDataSourceRequest} refreshDataSourceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public refreshStatus(refreshDataSourceRequest: RefreshDataSourceRequest, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).refreshStatus(refreshDataSourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataConnectorSyncFrequencyDto} dataConnectorSyncFrequencyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public setSyncFrequency(dataConnectorSyncFrequencyDto: DataConnectorSyncFrequencyDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).setSyncFrequency(dataConnectorSyncFrequencyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public sync1(id: string, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).sync1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDataSourceDto} updateDataSourceDto 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public update4(updateDataSourceDto: UpdateDataSourceDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).update4(updateDataSourceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDataSourceDto} updateDataSourceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataSourceControllerApi
     */
    public update5(id: string, updateDataSourceDto: UpdateDataSourceDto, options?: RawAxiosRequestConfig) {
        return DataSourceControllerApiFp(this.configuration).update5(id, updateDataSourceDto, options).then((request) => request(this.axios, this.basePath));
    }
}

