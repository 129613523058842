import React, { useEffect, useState, useMemo } from "react";

// Packages
import { useParams, useNavigate, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { Grid } from "@material-ui/core";

// Icons
import IconButton from "@material-ui/core/IconButton";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";
import { SplashIcon } from "src/icons/NewUX";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// APIs
import { getJobRunHistoryDetailsWithRethrow } from "src/api/projects";

// Hooks
import {
  prefetchDataSources,
  prefetchScenarios,
  prefetchVariables,
  prefetchJob,
  prefetchJobDestinations,
  useGetJobs,
  useDeleteJob
} from "src/hooks/api";

// Stores
import { useJobsStore } from "src/store/store";
import {
  shouldRefreshJobsGetter,
  shouldRefreshJobsToggler,
  watchingJobsSetter,
  watchingJobsGetter,
  watchingJobsIntervalIdSetter,
  watchingJobsIntervalIdGetter
} from "src/store/store.selectors";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import { Search, SplashSection, Spinner } from "src/components";
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";
import { JobsTable, JobRunOutputModal, JobGlobalVariables } from "./components";

// Constants
import {
  JobRunStatuses,
  JobDeletePromptDetails,
  JobsSplashSection,
  JobsHelperText
} from "./utils/Jobs.constants";

import { useJobsContext } from "./context/useJobsContext";

import { JobsParams } from "src/types";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

import { useStyles } from "./Jobs.styles";
import { WebPaths } from "src/routing/routes";
import { QUERY_KEY_PROJECT_DETAILS } from "src/hooks/api/projects/useProjectDetails";

const Jobs = () => {
  const classes = useStyles();

  const { projectId } = useParams<JobsParams>() || {};

  const navigate = useNavigate();

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPathsSetter } = useJobsContext() || {};
  const setJobsBackNavPath = () => {
    jobPathsSetter(`/projects/${projectId}/jobs`);
  };

  const queryClient = useQueryClient();

  // Stores - STARTS >>
  const toggleShouldJobsRefresh = useJobsStore(shouldRefreshJobsToggler);
  const watchingJobsStore = useJobsStore(watchingJobsGetter);
  const setWatchingJobsStore = useJobsStore(watchingJobsSetter);
  const watchingJobsIntervalIdStore = useJobsStore(watchingJobsIntervalIdGetter);
  const setWatchingJobsIntervalIdStore = useJobsStore(watchingJobsIntervalIdSetter);
  // << ENDS - Stores

  // States - STARTS >>
  const [jobsData, setJobsData] = useState<$TSFixMe>([]);

  const [searchValue, setSearchValue] = useState("");

  const [isJobOutputModalOpen, setIsJobOutputModalOpen] = useState(false);

  const [jobData, setJobData] = useState<$TSFixMe>({});
  const [lastRunData, setLastRunData] = useState<$TSFixMe>({});

  const [deletingJobId, setDeletingJobId] = useState("");
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);

  const [isJobRunParametersOpen, setIsJobRunParametersOpen] = useState(false);
  const [jobRunParametersData, setJobRunParametersData] = useState<$TSFixMe>({});
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isJobDeleting,
    mutateAsync: deleteJobMutation,
    reset: resetDeleteJobMutation
  } = useDeleteJob();

  // Queries
  const {
    isLoading,
    data: jobsQueryData,
    refetch: refetchJobs
  } = useGetJobs({ projectId, shouldRefreshJobsGetter });

  const getSortedJobsWithLastJobRun = () =>
    (jobsQueryData || [])
      ?.filter((eachJob: $TSFixMe) => !!eachJob?.lastRunEntry)
      ?.sort(
        (a: $TSFixMe, b: $TSFixMe) =>
          (b?.lastRunEntry?.updated || b?.lastRunEntry?.created) -
          (a?.lastRunEntry?.updated || a?.lastRunEntry?.created)
      );

  const getSortedJobsWithoutLastJobRun = () =>
    (jobsQueryData || [])
      ?.filter((eachJob: $TSFixMe) => !eachJob?.lastRunEntry)
      ?.sort(
        (a: $TSFixMe, b: $TSFixMe) =>
          (b?.dto?.updated || b?.dto?.created) - (a?.dto?.updated || a?.dto?.created)
      );

  useEffect(
    () =>
      setJobsData(() => [...getSortedJobsWithLastJobRun(), ...getSortedJobsWithoutLastJobRun()]),
    [jobsQueryData]
  );

  useEffect(() => {
    refetchJobs();
  }, [shouldRefreshJobsGetter]);
  // << ENDS - Query hooks

  const jobs = useMemo(() => {
    let dataFiltered = jobsData;

    if ((dataFiltered || [])?.length === 0) return [];

    if (searchValue) {
      return dataFiltered?.filter((item: $TSFixMe) => {
        return Object.values(item?.dto || {}).some((itemValue: $TSFixMe) => {
          return itemValue?.toLowerCase?.().includes?.(searchValue?.toLowerCase());
        });
      });
    }

    return dataFiltered;
  }, [jobsData, searchValue]);

  const onSearch = ({ target: { value } }: $TSFixMe) => {
    setSearchValue(value);
  };

  const onViewOutputOpen = (thisJob: $TSFixMe, thisLastRunData: $TSFixMe) => {
    setIsJobOutputModalOpen(true);
    setJobData(thisJob);
    setLastRunData(thisLastRunData);
  };

  const onViewOutputClose = () => {
    setIsJobOutputModalOpen(false);
    setJobData({});
    setLastRunData({});
  };

  const prefetchJobQueries = ({ jobId }: $TSFixMe = {}) => {
    prefetchDataSources({ queryClient });
    prefetchVariables({ queryClient, projectId });

    if (!!jobId) {
      prefetchScenarios({ queryClient, projectId, jobId });
      prefetchJob({ queryClient, jobId });
      prefetchJobDestinations({ queryClient, jobId });
    } else {
      prefetchScenarios({ queryClient, projectId });
    }
  };

  const editJob = (jobId: string) => {
    if (projectId) {
      navigate(generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, { projectId, jobId }));
    }
  };

  // Delete job - STARTS >>
  const deleteJob = async () => {
    if (!deletingJobId) {
      setShowConfirmScreen(() => false);
      return;
    }

    resetDeleteJobMutation();

    await deleteJobMutation(
      { jobId: deletingJobId },
      {
        onSuccess: () => {
          toastWrapper({ type: "success", content: JobsHelperText.JobDeletedMessage });
          queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
        },
        onSettled: () => {
          setShowConfirmScreen(() => false);
        }
      }
    );
  };

  const promptConfirmDeleteJob = (jobId: string) => {
    setDeletingJobId(() => jobId);
    setShowConfirmScreen(() => true);
  };

  const cancelDeleteJob = () => {
    setShowConfirmScreen(() => false);
  };
  // << ENDS - Delete job

  // Polling jobs - STARTS >>
  const getFilteredWatchingJobs = (jobs: $TSFixMe) =>
    jobs?.filter((eachJob: $TSFixMe) => eachJob?.lastRunEntry?.status === JobRunStatuses.Created);

  const resetWatchingJobsStore = () => {
    clearInterval(watchingJobsIntervalIdStore);
    setWatchingJobsStore([]);
  };

  const getJob = async () => {
    Promise.all(
      watchingJobsStore.map(
        async (eachJob: $TSFixMe) =>
          await getJobRunHistoryDetailsWithRethrow(eachJob?.lastRunEntry?.id)
      )
    )
      .then(async (thisJobRuns) => {
        if (thisJobRuns?.length > 0) {
          // Checking if status of any job's last-run-entry is changed compared to previous status of respective job's last-run-entry.
          const hasStatusChanged = thisJobRuns?.reduce((acc, eachThisJobRun) => {
            const previousJob = jobs?.find(
              (eachJob: $TSFixMe) => eachJob?.lastRunEntry?.id === eachThisJobRun?.entryDto?.id
            );
            return previousJob?.lastRunEntry?.status !== eachThisJobRun?.entryDto?.status || acc;
          }, false);

          if (hasStatusChanged) {
            const thisJobRunsIds = thisJobRuns?.map(
              (eachThisJobRun: $TSFixMe) => eachThisJobRun?.entryDto?.id
            );

            // Filtering for jobs' last-run-entries left for polling.
            const filteredJobs = jobs?.filter(
              (thisJob: $TSFixMe) => !thisJobRunsIds?.includes(thisJob?.lastRunEntry?.id)
            );

            // jobs has more details of last-run-entries than the ones in current API's response.
            // Hence, by mapping the current APIs' entry IDs to jobs' last run-entry IDs,
            // getting all the details of this current API's job-run-entries from jobs.
            const updatedJobs =
              jobs
                ?.filter((eachJob: $TSFixMe) => thisJobRunsIds?.includes(eachJob?.lastRunEntry?.id))
                ?.map((eachJob: $TSFixMe) => {
                  const thisLastRunEntry = thisJobRuns.find(
                    (eachThisJobRun: $TSFixMe) =>
                      eachJob?.lastRunEntry?.id === eachThisJobRun?.entryDto?.id
                  );

                  eachJob.lastRunEntry = thisLastRunEntry?.entryDto;
                  return eachJob;
                }) || [];

            // Merging the jobs.
            setJobsData([...filteredJobs, ...updatedJobs]);

            // Filtering out for in-progress jobs' last-run-entry.
            const filteredWatchingJobRunsIds =
              thisJobRuns
                ?.filter(
                  (eachThisJobRun: $TSFixMe) =>
                    ![
                      JobRunStatuses.Success,
                      JobRunStatuses.Failure,
                      JobRunStatuses.TimedOut,
                      JobRunStatuses.RecipeTimedOut
                    ].includes(eachThisJobRun?.entryDto?.status)
                )
                ?.map((eachThisJobRun: $TSFixMe) => eachThisJobRun?.entryDto?.id) || [];

            const filteredWatchingJobs =
              jobs?.filter((eachJob: $TSFixMe) =>
                filteredWatchingJobRunsIds?.includes(eachJob?.lastRunEntry?.id)
              ) || [];

            setWatchingJobsStore(filteredWatchingJobs);

            if (filteredWatchingJobs?.length === 0) {
              resetWatchingJobsStore();
            }
          }
        }
      })
      .catch((error: $TSFixMe) => {
        console.error(error);

        resetWatchingJobsStore();
        toggleShouldJobsRefresh();
      });
  };

  useEffect(() => {
    let intervalId: $TSFixMe = null;
    if (watchingJobsIntervalIdStore !== null || isJobDeleting) {
      clearInterval(watchingJobsIntervalIdStore);
    }

    if (watchingJobsStore?.length > 0 && !isJobDeleting) {
      const filteredWatchingJobs = getFilteredWatchingJobs(watchingJobsStore);

      if (filteredWatchingJobs?.length === 0) {
        resetWatchingJobsStore();
      } else {
        // $FixMe: Below API can be refactored with better polling approach.
        // Watching for job changes
        intervalId = setInterval(() => {
          getJob();
        }, 2000);

        setWatchingJobsIntervalIdStore(intervalId);
      }
    }

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }

      if (watchingJobsIntervalIdStore !== null) {
        clearInterval(watchingJobsIntervalIdStore);
      }
    };
  }, [watchingJobsStore, isJobDeleting]);

  useEffect(() => {
    if ((jobs || [])?.length > 0) {
      const filteredWatchingJobs = getFilteredWatchingJobs(jobs);

      if (filteredWatchingJobs?.length === 0) {
        resetWatchingJobsStore();
      } else {
        setWatchingJobsStore(filteredWatchingJobs);
      }
    }
  }, [jobs]);
  // << ENDS - Polling jobs

  const onJobRunParametersViewOpen = (runData: $TSFixMe) => {
    setIsJobRunParametersOpen(() => true);
    setJobRunParametersData(
      () =>
        Object.entries(runData?.computedVariables || {})?.map(([key, value]: $TSFixMe) => ({
          ["key"]: key,
          ["value"]: value
        })) || []
    );
  };

  const onJobRunParametersViewClose = () => {
    setIsJobRunParametersOpen(() => false);
    setJobRunParametersData(() => ({}));
  };

  return (
    <>
      {showConfirmScreen && (
        <Modal
          open={showConfirmScreen}
          variant={ModalVariants.Delete}
          title="Delete Job"
          content={[JobDeletePromptDetails.messageLine1, JobDeletePromptDetails.messageLine2]}
          onClose={cancelDeleteJob}
          onSubmit={deleteJob}
          isCancelDisabled={isJobDeleting}
          isSubmitDisabled={isJobDeleting}
          isSubmitting={isJobDeleting}
        />
      )}

      {isJobOutputModalOpen && (
        <JobRunOutputModal
          setJobRunOutputBackNavPath={setJobsBackNavPath}
          jobData={jobData}
          lastRunData={lastRunData}
          onViewOutputClose={onViewOutputClose}
        />
      )}

      {isJobRunParametersOpen && (
        <JobGlobalVariables
          close={onJobRunParametersViewClose}
          jobParametersData={jobRunParametersData}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(project?.id ? { backNavPath: `/projects/${project?.id}/canvas` } : {}),
          component: <SubTopNavBarBreadcrumbs project={project} />
        }}
        subTopNavBarRightSection={{
          component: (
            <>
              <Search onSearch={onSearch} placeholder={JobsHelperText.SearchJobsPlaceholder} />

              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  prefetchJobQueries();
                  navigate(
                    generatePath(`${WebPaths.JobRoutes}${WebPaths.CreateJob}`, {
                      projectId: projectId as string
                    })
                  );
                }}>
                <PlusIcon width={28} height={28} />
              </IconButton>
            </>
          )
        }}
      />

      <Grid item xs={12} className={classes.wrapperContainer}>
        {isLoading ? (
          <Spinner />
        ) : (jobs || [])?.length === 0 ? (
          <SplashSection
            gridContainerStyle={classes.gridContainer}
            gridIconStyle={classes.gridIcon}
            button={{
              color: "primary",
              onClick: () => {
                prefetchJobQueries();
                navigate(
                  generatePath(`${WebPaths.JobRoutes}${WebPaths.CreateJob}`, {
                    projectId: projectId as string
                  })
                );
              },
              label: JobsSplashSection?.actionLabel
            }}
            titleSection={{
              title: JobsSplashSection.title,
              subtitleLines: [JobsSplashSection.subTitleLine1, JobsSplashSection.subTitleLine2]
            }}
            icon={<SplashIcon />}
          />
        ) : (
          <JobsTable
            projectId={projectId}
            jobs={jobs}
            onViewOutputOpen={onViewOutputOpen}
            onJobRunParametersViewOpen={onJobRunParametersViewOpen}
            onEditJob={editJob}
            onDeleteJob={promptConfirmDeleteJob}
            prefetchJobQueries={prefetchJobQueries}
            hideJobRunCanvas={false}
            hideJobRunParameters={false}
          />
        )}
      </Grid>
    </>
  );
};

export default Jobs;
