import React, { useMemo } from "react";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import { Edit } from "@material-ui/icons";
import { Grid, Card, Typography, makeStyles } from "@material-ui/core";

import DataAppTileStatus from "./DataAppTileStatus";
import infoCardsStyles from "src/components/InfoCards/InfoCards.module.scss";
import { ConfigIcon } from "src/icons/ConfigIcon";
import { DataAppDtoDataAppTypeEnum } from "openapi/Models/data-app-dto";
import { DataAppType } from "../../DataApps.type";
import { Delete } from "src/icons/Delete";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { MoreOptions, OverflowTooltip } from "src/components";
import { askAITypesInfoList } from "../../utils/DataApps.constants";
import { dateFormat } from "src/utils/dateFormat";
import { deleteDisabled, deleteTooltip } from "../../utils/DataApps.helpers";

const useStyles = makeStyles({
  root: {
    borderRadius: 12
  },
  containerWrap: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 16px 0px 16px"
  },
  img: {
    aspectRatio: "auto",
    border: "1px solid #e0e0e0",
    borderRadius: "4px"
  },
  containerDataWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: "16px"
  },
  containerFirstDataItem: {
    paddingTop: "8px"
  },
  containerDataItem: {
    display: "flex",
    paddingTop: "16px",
    whiteSpace: "pre"
  },
  container: {
    height: "100%"
  },
  dataAppTileWrap: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0px"
  },
  alert: {
    padding: "0px 16px",
    marginTop: "8px",
    borderRadius: "0px 0px 4px 4px"
  },
  alertMessage: {
    padding: "6px 0"
  },
  link: {
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
});

interface DataApp extends DataAppType {
  imgComponent?: React.ReactNode;
  projectName?: string;
}
type Props = {
  data: DataApp;
  onDelete: (data: DataApp) => void;
  onConfigOpen: (dataApp: DataAppType) => void;
  onLogsOpen: (dataApp: DataAppType) => void;
  onEdit: (dataApp: DataAppType) => void;
  isRoleYieldsDataAppView: boolean;
};

export const DataAppTile = ({
  data,
  isRoleYieldsDataAppView,
  onConfigOpen,
  onDelete,
  onLogsOpen,
  onEdit
}: Props) => {
  const location = useLocation();
  const classes = useStyles();
  const {
    name,
    displayName,
    description,
    projectId,
    updater,
    updatedOn,
    iconUrl,
    id,
    launchStatus,
    imgComponent,
    projectName,
    stoppedBy,
    groupDisplayName,
    scenarioId,
    dataAppType,
    askAIConfig,
    groupId
  } = data;

  const inputType = useMemo(() => {
    switch (dataAppType) {
      case DataAppDtoDataAppTypeEnum.RapidModel: {
        return "Rapid Model";
      }

      case DataAppDtoDataAppTypeEnum.Askai: {
        return _.find(askAITypesInfoList, { value: askAIConfig?.inputType })?.label ?? "-";
      }

      default: {
        return "-";
      }
    }
  }, [askAIConfig, dataAppType]);

  const options = [
    {
      label: "Edit",
      id: "dataAppEdit",
      disabled: false,
      icon: (
        <Typography color="textSecondary">
          <Edit />
        </Typography>
      ),
      click: () => onEdit(data)
    },
    {
      id: "dataAppDelete",
      label: "Delete",
      click: () => onDelete(data),
      tooltip: deleteTooltip(launchStatus),
      disabled: deleteDisabled(launchStatus),
      icon: (
        <Typography color="textSecondary">
          <Delete />
        </Typography>
      )
    },
    {
      label: "Logs",
      id: "dataAppLogs",
      icon: <LogsNewIconV2 fill="#003656" width="24" height="24" />,
      click: () => onLogsOpen(data)
    },
    {
      label: "Configs",
      id: "dataAppConfig",
      disabled: false,
      icon: <ConfigIcon />,
      click: () => onConfigOpen(data)
    }
  ];

  return (
    <Card className={classes.root}>
      <Grid className={classes.dataAppTileWrap}>
        <Grid className={classes.containerWrap}>
          {imgComponent ? (
            <Grid item>{imgComponent}</Grid>
          ) : (
            <Grid
              item
              component="img"
              className={classes.img}
              src={`data:image/jpeg;base64,${iconUrl}`}
              alt={description}
            />
          )}
          <Grid className={classes.containerDataWrap}>
            <Grid container justifyContent="space-between">
              <Grid item style={{ maxWidth: 325 }}>
                <Typography component="span" variant="h6">
                  <Link
                    className={classes.link}
                    to={`${location.pathname === "/" ? "dataapps-ui" : location.pathname}/${name}`}
                    data-testid="dataAppNameLink">
                    <OverflowTooltip value={displayName || name} style={{ whiteSpace: "pre" }} />
                  </Link>
                </Typography>
              </Grid>
              <Grid item>
                <MoreOptions options={options} data-testid="dataAppMoreOptionsAction" />
              </Grid>
            </Grid>
            <Grid className={classes.containerFirstDataItem}>
              <Grid container alignItems="flex-end" style={{ columnGap: 5 }}>
                <Grid item>
                  <Typography component="span" variant="body2" color="textSecondary">
                    Project:
                  </Typography>
                </Grid>
                <Grid item style={{ maxWidth: 290 }}>
                  <Typography component="span" variant="body2" data-testid="dataAppProject">
                    {isRoleYieldsDataAppView ? (
                      <OverflowTooltip
                        value={projectName || projectId}
                        style={{ whiteSpace: "pre" }}
                      />
                    ) : (
                      <Link
                        className={classes.link}
                        to={`/projects/${projectId}`}
                        data-testid="dataAppProjectLink">
                        <OverflowTooltip
                          value={projectName || projectId}
                          style={{ whiteSpace: "pre" }}
                        />
                      </Link>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.containerFirstDataItem}>
              <Grid container alignItems="flex-end" style={{ columnGap: 5 }}>
                <Grid item>
                  <Typography component="span" variant="body2" color="textSecondary">
                    Recipe:
                  </Typography>
                </Grid>
                <Grid item style={{ maxWidth: 290 }}>
                  <Typography component="span" variant="body2">
                    {groupDisplayName ? (
                      <Link
                        className={classes.link}
                        to={`/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe/${groupId}`}
                        data-testid="dataAppRecipeLink">
                        <OverflowTooltip value={groupDisplayName} style={{ whiteSpace: "pre" }} />
                      </Link>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.containerFirstDataItem}>
              <Grid container alignItems="flex-end" style={{ columnGap: 5 }}>
                <Grid item>
                  <Typography component="span" variant="body2" color="textSecondary">
                    Desc:
                  </Typography>
                </Grid>
                <Grid item style={{ maxWidth: 300, maxHeight: 20, overflowY: "hidden" }}>
                  <Typography component="span" variant="body2" data-testid="dataAppDescription">
                    <OverflowTooltip
                      value={description || "-"}
                      tooltipProps={{
                        placement: "top"
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.containerFirstDataItem}>
              <Grid container alignItems="flex-end" style={{ columnGap: 5 }}>
                <Grid item>
                  <Typography component="span" variant="body2" color="textSecondary">
                    Input Type:
                  </Typography>
                </Grid>
                <Grid item style={{ maxWidth: 300, maxHeight: 20, overflowY: "hidden" }}>
                  <Typography component="span" variant="body2" data-testid="dataAppInputType">
                    <OverflowTooltip
                      value={inputType}
                      tooltipProps={{
                        placement: "top"
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.containerFirstDataItem}>
              <Grid container alignItems="flex-end" style={{ columnGap: 5 }}>
                <Grid item>
                  <Typography component="span" variant="body2" color="textSecondary">
                    Last Shutdown By:
                  </Typography>
                </Grid>
                <Grid item style={{ maxWidth: 215 }}>
                  <Typography component="span" variant="body2" data-testid="dataAppStoppedBy">
                    <OverflowTooltip value={stoppedBy} style={{ whiteSpace: "pre" }} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.containerDataItem}>
              <Typography
                component="span"
                color="textSecondary"
                variant="body2"
                data-testid="dataAppCreatedOn">
                Updated on: {dateFormat(updatedOn)}
              </Typography>
            </Grid>
            <Grid className={classes.containerDataItem}>
              {updater && (
                <div className={infoCardsStyles.text}>
                  <div className={infoCardsStyles.userMenuItemIndicator}>
                    {updater?.charAt(0)?.toUpperCase()}
                  </div>
                  <OverflowTooltip
                    style={{ maxWidth: 300, whiteSpace: "nowrap" }}
                    value={
                      <Typography color="textSecondary" variant="caption">
                        {updater}
                      </Typography>
                    }
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <DataAppTileStatus id={id} launchStatus={launchStatus} />
      </Grid>
    </Card>
  );
};
