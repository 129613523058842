import React, { useMemo, useState } from "react";
import _, { compact, flatten } from "lodash";
import { Button, CircularProgress, TextField, Tooltip, makeStyles } from "@material-ui/core";

import CopyDataApp from "./CopyDataApp";
import CopyEnvironment from "./CopyEnvironment";
import CopyJobRuns from "./CopyJobs";
import CopyPredictionService from "./CopyPredictionService";
import CopyTenant from "./CopyTenant";
import NewModal from "components/Modal/NewModal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import useAuthStore from "src/stores/auth.store";
import useCopyProject, { ICopyPayload } from "hooks/api/projects/useCopyProject";
import useDownloadProject from "hooks/api/projects/useDownloadProject";
import useProjectDetails from "hooks/api/projects/useProjectDetails";
import { Download } from "src/icons/Download";
import { InfoOutlined } from "@material-ui/icons";
import { Spinner } from "src/components";
import { projectMetadata } from "../utils/CopyDownloadProject.constants";
import { useForm } from "src/utils/useForm";
import { useGetEnvironments } from "hooks/api/environments/useGetEnvironments";
import { validateNameField } from "src/utils/formFieldUtils";

interface IProps {
  id: string;
  envId: string;
  open: boolean;
  name: string;
  onClose: () => void;
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    overflowY: "auto",
    maxHeight: "calc(100vh - 200px)",
    overflowX: "hidden",
    padding: "10px"
  },
  infoWrapper: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 160px)",
    overflowX: "hidden"
  },
  flex: {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  ul: {
    padding: "0px 16px",
    fontSize: 14,
    color: "grey",

    "& li": {
      marginTop: "10px"
    }
  },
  italics: {
    fontSize: 14,
    color: "grey",
    marginLeft: "2px"
  },
  paddingeLeft: {
    paddingLeft: "10px"
  }
});

const CopyProjectModal: React.FC<IProps> = (props) => {
  const { id, name, envId, open, onClose } = props;
  const [showInfo, setShowInfo] = useState(true);
  const [hasAutoSelectedPS, setHasAutoSelectedPS] = useState(false);
  const { data, isLoading } = useProjectDetails(id);

  const envDetails = useGetEnvironments({
    refetchOnMount: true,
    cacheTime: Infinity
  });

  const copyProject = useCopyProject();
  const downloadProject = useDownloadProject();
  const tenantId = useAuthStore((state) => state.tenantId);

  const { wrapper, flex, ul, italics, paddingeLeft, infoWrapper } = useStyles();
  const { values, handleInputChange, setValues } = useForm({
    [projectMetadata.name.id]: `Copy of ${name}`,
    [projectMetadata.environment.id]: envId ?? "",
    [projectMetadata.tenant.id]: tenantId ?? ""
  });

  const { isValid, error } = validateNameField({
    fieldName: _.get(values, projectMetadata.name.id),
    fieldNameLabel: `project name`
  });

  const disabled = useMemo(
    () =>
      _.some(
        [projectMetadata.name.id, projectMetadata.environment.id, projectMetadata.tenant.id],
        (id) => _.isEmpty(_.trim(_.get(values, id)))
      ),
    [values]
  );
  const handleDataAppChange = (
    key: string,
    value: string[] | string | boolean,
    psAssociatedWithdataApp: string[]
  ) => {
    setValues({
      ...values,
      [key]: value,
      psAssociatedWithdataApp,
      [projectMetadata.predictionServices.id]: _.uniq([
        ...(psAssociatedWithdataApp ?? []),
        ...(_.get(values, projectMetadata.predictionServices.id) ?? [])
      ])
    });

    if (!_.isEmpty(psAssociatedWithdataApp)) {
      setHasAutoSelectedPS(true);
    }
  };

  const handleChange = (key: string, value: string[] | string | boolean) => {
    setValues({ ...values, [key]: value });
  };

  const handleCopy = () => {
    copyProject.mutate(
      {
        ...(_.omit(values, [
          projectMetadata.predictionServices.id,
          "psAssociatedWithdataApp"
        ]) as Omit<ICopyPayload, "projectId">),
        projectId: id,
        [projectMetadata.predictionServices.id]: _.uniq(
          _.get(values, projectMetadata.predictionServices.id) ?? []
        )
      },
      {
        onSuccess: () => {
          onClose();
        }
      }
    );
  };

  const handleSubmit = () => {
    if (showInfo) {
      setShowInfo(!showInfo);
    } else {
      handleCopy();
    }
  };

  const handleDownload = () => {
    downloadProject.mutate(
      {
        ...(_.omit(values, [
          projectMetadata.predictionServices.id,
          "psAssociatedWithdataApp"
        ]) as Omit<ICopyPayload, "projectId">),
        projectId: id,
        [projectMetadata.predictionServices.id]: _.uniq(
          _.get(values, projectMetadata.predictionServices.id) ?? []
        )
      },
      {
        onSuccess: () => {
          onClose();
        }
      }
    );
  };

  const header = (
    <div className={flex}>
      <span>Copy Project</span>
      <Tooltip title="Duplicate project name will be appended with a sequence number">
        <InfoOutlined />
      </Tooltip>
    </div>
  );

  const infoContent = (
    <div className={infoWrapper}>
      <h6>Note:</h6>
      <div className={paddingeLeft}>
        <ul className={ul}>
          <li>
            When copying components like DataApps, Prediction Services, Environments, etc. a new
            name may be automatically generated for the copied item.
          </li>
          <li>
            When artifacts/models are input or output within copy action, they may undergo renaming,
            requiring manual updates in recipe input/output.
          </li>
          <li>
            For rapid model recipe outputs, they gets renamed during copy action as they are
            uniquely generated based on project and recipe name so they too may require manual
            updates in recipe input/output.
          </li>
        </ul>
        <h6>Same Tenant</h6>
        <ul className={ul}>
          <li>
            For datasets imported via local files, you can use the 'Add file' option to include data
            (Make sure schema matches as that of original dataset). For datasets imported via
            connectors, use the 'Reload' option to fetch the latest data.
          </li>
          <i style={{ fontSize: 12 }}>
            -- These options are accessible via right-click on datasets within the copied project.
          </i>
          <br />
          <i style={{ fontSize: 12 }}>-- Make sure schema matches as that of original dataset.</i>
        </ul>
        <h6>Different Tenant</h6>
        <ul className={ul}>
          <li>
            While copying to different tenant, connector based datasets are copied without data
            connector info for security reasons.
          </li>
          <i style={{ fontSize: 12 }}>
            -- You can use the 'Add file' option to include data. Make sure schema matches as that
            of original dataset.
          </i>
          <br />
          <i style={{ fontSize: 12 }}>
            -- This option is accessible via right-click on datasets within the copied project.
          </i>
          <li>
            Copy action to diff tenant does not copy actual data of artifacts and models if present
            in parent project. You need to manually add files to them.
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <NewThemeWrapper>
      <NewModal
        header={header}
        open={open}
        data-testid="copy-download-project"
        width={700}
        height="100%"
        background="#fff"
        keepMounted={false}
        submitDisabled={
          (!showInfo && (disabled || copyProject.isLoading || isLoading || envDetails.isLoading)) ||
          !isValid
        }
        submitTooltip={
          !showInfo && envDetails.isLoading
            ? "Please wait until the environment is loaded for this project"
            : ""
        }
        footerLeftContent={
          true ? (
            <span />
          ) : (
            <Button
              size="small"
              startIcon={downloadProject.isLoading ? <CircularProgress size={16} /> : <Download />}
              color="primary"
              onClick={handleDownload}>
              {downloadProject.isLoading ? "Preparing for Download" : "Download the Project"}
            </Button>
          )
        }
        loading={copyProject.isLoading}
        cancelButtonLabel="Close"
        submitButtonLabel={showInfo ? "Proceed" : "Copy"}
        onClose={onClose}
        onFinish={handleSubmit}>
        {showInfo ? (
          infoContent
        ) : (
          <div className={wrapper}>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <TextField
                  fullWidth
                  id={projectMetadata.name.id}
                  name={projectMetadata.name.id}
                  label={projectMetadata.name.label}
                  value={_.get(values, projectMetadata.name.id)}
                  error={!isValid}
                  helperText={error}
                  data-testid="copy-project-endpoint"
                  required
                  variant="outlined"
                  onChange={handleInputChange}
                />
                <CopyDataApp data={data?.dataApps ?? []} onChange={handleDataAppChange} />
                {false && (
                  <CopyJobRuns
                    data={compact(flatten([data?.projectJobs, data?.predictionJobs]))}
                    onChange={handleChange}
                  />
                )}
                <CopyPredictionService
                  psAssociatedWithdataApp={_.get(values, "psAssociatedWithdataApp")}
                  data={_.get(data, projectMetadata.predictionServices.id) ?? []}
                  onChange={handleChange}
                />
                <CopyEnvironment
                  data={envDetails.data ?? []}
                  value={_.get(values, projectMetadata.environment.id)}
                  onChange={handleChange}
                />
                <CopyTenant
                  value={_.get(values, projectMetadata.tenant.id)}
                  onChange={handleChange}
                />
                {hasAutoSelectedPS && (
                  <i className={italics}>
                    Note: Prediction Service associated with selected dataapp will be automatically
                    copied
                  </i>
                )}
              </>
            )}
          </div>
        )}
      </NewModal>
    </NewThemeWrapper>
  );
};

export default CopyProjectModal;
