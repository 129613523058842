/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SignedUrlRequestDto
 */
export interface SignedUrlRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SignedUrlRequestDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignedUrlRequestDto
     */
    'filePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignedUrlRequestDto
     */
    'dirPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignedUrlRequestDto
     */
    'signedUrlObjectType'?: SignedUrlRequestDtoSignedUrlObjectTypeEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SignedUrlRequestDto
     */
    'metadata'?: { [key: string]: string; };
}

export const SignedUrlRequestDtoSignedUrlObjectTypeEnum = {
    Dataapp: 'DATAAPP',
    AppTemplateStreamlit: 'APP_TEMPLATE_STREAMLIT',
    AppTemplateReactjs: 'APP_TEMPLATE_REACTJS',
    DataappStreamlit: 'DATAAPP_STREAMLIT',
    DataappLaunchLogs: 'DATAAPP_LAUNCH_LOGS',
    DataConversationEntity: 'DATA_CONVERSATION_ENTITY',
    AiThreadAdhocInput: 'AI_THREAD_ADHOC_INPUT',
    AiThreadRagInput: 'AI_THREAD_RAG_INPUT',
    DataappArtifacts: 'DATAAPP_ARTIFACTS',
    FuncTemplate: 'FUNC_TEMPLATE',
    DemoProject: 'DEMO_PROJECT',
    General: 'GENERAL',
    DataappAdditionalCharts: 'DATAAPP_ADDITIONAL_CHARTS'
} as const;

export type SignedUrlRequestDtoSignedUrlObjectTypeEnum = typeof SignedUrlRequestDtoSignedUrlObjectTypeEnum[keyof typeof SignedUrlRequestDtoSignedUrlObjectTypeEnum];


