import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect, useRef, useState } from "react";
import {
  LinearProgress,
  Theme,
  makeStyles,
  withStyles,
  IconButton,
  CircularProgress
} from "@material-ui/core";

import OverflowTooltip from "components/OverflowTooltip";
import useDeleteArtifactFile from "hooks/api/artifacts/useDeleteArtifactFile";
import { IFile } from "./UploadFileList";
import { executeSignedUrlWithRethrow } from "src/api/projects";
import { getArtifactSignedUrl } from "src/api";
import { useCancelApis } from "src/utils/useCancelApis";
import { formatBytes } from "src/utils/format-bytes";

interface IProps {
  file: IFile;
  name: string;
  getFileType: (file: IFile) => string;
  onError: (error: Error) => void;
  onFinishUpload: () => void;
  onDelete: () => void;
}

interface IProgress {
  loaded: number;
  total: number;
}

interface IStyleProps {
  uploadComplete: boolean;
  uploadFailed: boolean;
}

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 12,
    borderRadius: 5,
    border: "0.5px solid #C0C0C0"
  },
  colorPrimary: {
    backgroundColor: "white"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}))(LinearProgress);

export const useStyles = makeStyles<Theme, IStyleProps>({
  fileContainer: {
    padding: "4px",
    marginBottom: "8px",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    border: ({ uploadComplete, uploadFailed }) =>
      `1px solid ${uploadComplete ? "#25e400" : uploadFailed ? "#da1d27" : "#4646b5"}`,
    backgroundColor: ({ uploadComplete, uploadFailed }) =>
      uploadComplete ? "#f6fff7" : uploadFailed ? "#ffeeee" : "#f2f2ff",

    "& .MuiLinearProgress-root": {
      width: "100%"
    }
  },
  title: {
    fontSize: 14,
    whiteSpace: "nowrap",
    fontWeight: 600
  },
  subtitle: {
    gap: "20px",
    display: "flex",
    fontSize: "12px",
    fontWeight: 400
  },
  left: {
    padding: "10px",
    borderRight: "1px solid #d1cdcd",
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    minWidth: "70%",
    width: "70%"
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    fontSize: 12,
    width: "100%"
  },
  success: {
    color: "#5fcb84",
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  failure: {
    color: "#da1d27",
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  close: {
    display: "flex",
    gap: "10px",
    alignItems: "center",

    "& svg": {
      width: 14,
      height: 14
    }
  }
});

const UploadFile: React.FC<IProps> = (props) => {
  const { file, name, getFileType, onError, onFinishUpload, onDelete } = props;

  const { newCancelApiToken, cancelApi } = useCancelApis();
  const cancelApiTokenRef = useRef<$TSFixMe>(null);
  const [progress, setProgress] = useState<number | null>(0);
  const [canceled, setCanceled] = useState(false);
  const classes = useStyles({
    uploadComplete: progress === 100,
    uploadFailed: progress === null || canceled === true
  });
  const deleteArtifact = useDeleteArtifactFile();

  useEffect(() => {
    let mounted = true;

    if (!cancelApiTokenRef?.current) {
      cancelApiTokenRef.current = newCancelApiToken();
    }

    const handleUploadProgress = (e: IProgress) => {
      let progressValue = Math.floor((e.loaded * 100) / e.total);
      if (mounted) {
        setProgress(() => progressValue);
      }
    };

    const upload = async () => {
      try {
        const cleanedArtifactName = name.trim();
        const signedUrlObj = await getArtifactSignedUrl(`${cleanedArtifactName}/${file.name}`);

        await executeSignedUrlWithRethrow({
          url: signedUrlObj?.signedUrl,
          data: file.file,
          options: {
            headers: { ...signedUrlObj?.headers },
            signedUrl: true,
            cancelToken: cancelApiTokenRef.current,
            onUploadProgress: handleUploadProgress
          },
          shouldDispatchEvent: false
        });

        onFinishUpload();
      } catch (error: any) {
        onError(error);
      }
    };

    upload();

    return () => {
      mounted = false;
    };
  }, []);

  const handleCancel = () => {
    cancelApi();
    deleteArtifact.mutate({
      name,
      filePath: file.name
    });
    setCanceled(true);
    onDelete();
  };

  return (
    <div key={file.name} className={classes.fileContainer}>
      <div className={classes.left}>
        <div className={classes.title}>
          <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={file?.name} />
        </div>
        <div className={classes.subtitle}>
          <span>{`Size: ${file.size ? formatBytes(file.size) : "0 KB"}`}</span>
          <span>{`Type: ${getFileType(file)}`}</span>
        </div>
      </div>
      <div className={classes.progress}>
        {progress === 100 ? (
          <div className={classes.success}>
            <CheckCircleIcon />
            <span>Uploaded Successfully!</span>
          </div>
        ) : progress === null ? (
          <div className={classes.failure}>
            <CancelIcon />
            <span>Upload Failed!</span>
          </div>
        ) : canceled ? (
          <div className={classes.failure}>
            <CancelIcon />
            <span>Upload Canceled!</span>
          </div>
        ) : (
          <>
            <span>Uploading {progress}%</span>
            <div className={classes.close}>
              <BorderLinearProgress variant="determinate" value={progress} />
              <IconButton size="small" onClick={handleCancel}>
                {deleteArtifact.isLoading ? <CircularProgress size={6} /> : <CancelIcon />}
              </IconButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
