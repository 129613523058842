import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { Grid, makeStyles, IconButton } from "@material-ui/core";
import { delay } from "lodash";

import AskAIInputDatasets from "./AskAIInputDatasets/AskAIInputDatasets";
import AskAIResponses from "./AskAIResponses/AskAIResponses";
import AskAIResponsesNewFlow from "./AskAIResponses/AskAIResponsesNewFlow";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { AskAILoaderItem } from "./AskAILoaderItem";
import { AskAIResponse } from "src/types";
import { useCodeRecipeContext } from "../../CodeRecipeContext/useCodeRecipeContext";

const useStyles = makeStyles({
  askAiConvoGrid: {
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
    flexWrap: "nowrap",
    overflow: "auto"
  },
  askAiResponses: {
    flexWrap: "nowrap",
    maxWidth: "95%"
  },

  inputDatasetsContainer: {
    flexWrap: "nowrap",
    gap: "16px",
    width: "95%",
    paddingBottom: "8px"
  },

  floatBtns: {
    position: "absolute",
    bottom: "32px",
    right: "32px",
    zIndex: 99,
    width: "auto",
    gap: "8px",
    justifyContent: "flex-end"
  },
  floatBtn: {
    color: "#303077",
    border: "2px solid #303077"
  },
  askAiResponsesWrap: {
    flexWrap: "nowrap",
    position: "relative",
    justifyContent: "flex-start",
    overflow: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  }
});

export const AskAIConversationGrid = ({
  isFetchingSuggestions,
  userInput
}: {
  isFetchingSuggestions: boolean;
  userInput: string;
  onUserInputChange: (userInput: string) => void;
}) => {
  const [hoverId, setHoverId] = useState(null);
  const [hoverInputs] = useState<string[]>([]);
  const [hoverCoordinates] = useState<{ x: number; y: number } | null>(null);
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);
  const [isScrollToBottomVisible, setIsScrollToBottomVisible] = useState(false);
  const classes = useStyles({ hoverCoordinates, hasHover: !!hoverId });

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messageStartRef = useRef<HTMLDivElement | null>(null);
  const askAIContainerRef = React.useRef<HTMLDivElement | null>(null);

  const {
    responses,
    isAutoGenerateInProgress,
    isAutoGeneratedSuccess,
    inputDatasets,
    entityFeaturesMap,
    autoGenerateQueryInputNames,
    autoGenerateQueryUserInput,
    newAskAIFlow,
    recipeId,
    setIsRetryInProgress,
    fetchSuggestionsApiInfo
  } = useCodeRecipeContext();

  const { fetchSuggestionsQueryInputNames, fetchSuggestionsUserInput } = fetchSuggestionsApiInfo;

  useEffect(() => {
    delay(() => scrollToBottom(), 1000);
  }, [isAutoGeneratedSuccess, isAutoGenerateInProgress, isFetchingSuggestions]);

  const handleScroll = (event: any) => {
    const TOLERANCE = screen.height * 0.5;
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + TOLERANCE;
    if (scrollTop > TOLERANCE) {
      setIsScrollToTopVisible(true);
    } else {
      setIsScrollToTopVisible(false);
    }
    setIsScrollToTopVisible(scrollTop > TOLERANCE);
    setIsScrollToBottomVisible(!isAtBottom);
  };

  const scrollToTop = () => {
    messageStartRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      onScroll={handleScroll}
      className={clsx(["askAiConvoGrid", classes.askAiConvoGrid])}>
      <Grid
        container
        direction="column"
        className={classes.askAiResponsesWrap}
        ref={askAIContainerRef}>
        <div id="messageStartRef" ref={messageStartRef}></div>
        <Grid container direction="column" className={classes.inputDatasetsContainer}>
          <AskAIInputDatasets
            inputDatasets={inputDatasets}
            entityFeaturesMap={entityFeaturesMap}
            isAutoGenerateInProgress={isAutoGenerateInProgress}
            hoverInputs={hoverInputs}
          />
        </Grid>
        <Grid container direction="column" className={classes.askAiResponses}>
          {newAskAIFlow ? (
            <AskAIResponsesNewFlow
              responses={responses as AIChatResponseDto[]}
              hoverId={hoverId}
              setHoverId={setHoverId}
              targetId={recipeId}
              isGenerating={isAutoGenerateInProgress || isFetchingSuggestions}
              setIsRetryInProgress={setIsRetryInProgress}
            />
          ) : (
            <AskAIResponses
              responses={responses as AskAIResponse[]}
              hoverId={hoverId}
              setHoverId={setHoverId}
              isGenerating={isAutoGenerateInProgress || isFetchingSuggestions}
            />
          )}
          {(isAutoGenerateInProgress || isFetchingSuggestions) && (
            <Grid container direction="column">
              <AskAILoaderItem
                isFetchingSuggestions={isFetchingSuggestions}
                inputNames={
                  isFetchingSuggestions
                    ? fetchSuggestionsQueryInputNames
                    : autoGenerateQueryInputNames
                }
                userInput={
                  isFetchingSuggestions
                    ? fetchSuggestionsUserInput || userInput
                    : autoGenerateQueryUserInput || userInput
                }
                testId="generate-output-loader"
                style={{ paddingBottom: "calc(10vh)" }}
                tags={isFetchingSuggestions ? ["Prompt Suggestions"] : []}
              />
            </Grid>
          )}
        </Grid>
        <div id="messagesEndRef" ref={messagesEndRef}></div>
      </Grid>
      <Grid container direction="row" wrap="nowrap" className={classes.floatBtns}>
        {isScrollToTopVisible && (
          <IconButton size="small" onClick={scrollToTop} className={classes.floatBtn}>
            <ArrowUpward fontSize="medium" />
          </IconButton>
        )}
        {isScrollToBottomVisible && (
          <IconButton size="small" onClick={scrollToBottom} className={classes.floatBtn}>
            <ArrowDownward fontSize="medium" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
